import initialState from 'store/initialState';
import { CUSTOMER_NUMS_IN_SITE } from 'actions/actionTypes';

const customerNumsForSite = (state = initialState.brokerTies, action) => {
  switch (action.type) {
    case CUSTOMER_NUMS_IN_SITE.PENDING:
      return { ...state, fetching: true };
    case CUSTOMER_NUMS_IN_SITE.SUCCESS:
      const { data, siteId } = action.payload;
      return { ...state, data: data, siteId: siteId, fetching: false };
    case CUSTOMER_NUMS_IN_SITE.FAILURE:
      return { ...state, fetching: false };
    default:
      return state;
  }
};

export default customerNumsForSite;
