import React, { useEffect, useState } from 'react';
import { Input, Radio, Select, Checkbox, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { selectQuestions, selectAllFeedback, selectFeedbackUpdatingStatus } from 'selectors/feedbackSelector';
import _ from 'lodash';
import { OPP_FEEDBACK_QUESTION_TYPE } from 'utils/Constants';
import {
  isRequiredQuestion,
  getQuestionById,
  getAnswerById,
  removeValidationError,
  getValidationErrorMessageById,
  getModifiedQuestions
} from 'managers/pages/OpportunityFeedBackDrawerLogic';
import { retrieveFeedback, updateFeedbackLocally } from '../actions';

const { TextArea } = Input;
const { Option } = Select;

const FeedBackForm = ({ conversionId, enableLoadingScreen = false, modifications = null, onChangeErrors }) => {
  // Loading questions and answers
  const originalQuestions = useSelector(selectQuestions);
  const allFeedbacks = useSelector(selectAllFeedback);
  const feedbackUpdatingStatus = useSelector(selectFeedbackUpdatingStatus);
  const dispatch = useDispatch();
  let _reqQuestions = [];
  // local states
  const [requiredQuestions, setRequiredQuestions] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [validations, setValidations] = useState({});

  // question ids
  const SOLD_STATUS_QA = 20;
  const LIST_DETAIL_COMMENTS_QA = 24;
  const WHY_WAS_THE_PRODUCT_NOT_SOLD_QA = 21;

  /**
   * add or remove to requiredQuestions
   * @param {questionId to update} questionId
   * @param {add or remove} isAdd
   * @returns
   */
  const updateRequiredQuestion = (questionId, isAdd) => {
    if (questionId === 0) return;

    const questionIndex = requiredQuestions.findIndex(item => item.questionId === questionId);

    if (isAdd) {
      const _requiredQuestions = [..._reqQuestions];
      if (questionIndex >= 0) {
        _requiredQuestions[questionIndex].required = true;
        _reqQuestions = [..._requiredQuestions];
      } else {
        _requiredQuestions.push({ questionId, required: true, showError: false });
        _reqQuestions = [..._requiredQuestions];
      }
    } else {
      const _requiredQuestions = _reqQuestions.filter(item => item.questionId !== questionId);
      _reqQuestions = [..._requiredQuestions];
    }

    setRequiredQuestions([..._reqQuestions]);
  };

  /**
   * update required questions on question answer selection
   * @param {*} data
   */
  const updateRequiredQuestions = data => {
    const { question, answer } = data;

    // now  need to update required status of dependent questions
    let answerIndex = -1;
    let dependentQuestionIds = null;
    let otherAnswerDependencies = null;

    if (
      question &&
      question.answers &&
      question.answers.length > 0 &&
      question.dependencies &&
      question.dependencies.length > 0
    ) {
      // first get dependant question Ids
      answerIndex = question.answers.findIndex(item => item === answer);
      otherAnswerDependencies = question.dependencies.filter((item, index) => index !== answerIndex);
    }

    if (answerIndex >= 0) {
      dependentQuestionIds = question.dependencies[answerIndex];
    }

    // add
    if (dependentQuestionIds) {
      dependentQuestionIds.forEach(item => {
        if (item && parseInt(item) > 0) {
          updateRequiredQuestion(parseInt(item), true);
        }
      });
    }

    // set other answer dependencies to false
    if (otherAnswerDependencies && otherAnswerDependencies.length > 0) {
      otherAnswerDependencies.forEach(item => {
        if (item && item.length > 0) {
          item.forEach(id => updateRequiredQuestion(parseInt(id), false));
        }
      });
    }
  };

  const validateSoldStatusAnswer = ({ answer }) => {
    const isAnswerNo = answer === 'No';

    const questionIds = [WHY_WAS_THE_PRODUCT_NOT_SOLD_QA, LIST_DETAIL_COMMENTS_QA];

    let temQuestions = [...questions];
    let tempValidations = { ...validations };
    const tempValidationErrors = [...validationErrors];

    questionIds.forEach(questionId => {
      temQuestions = temQuestions.map(qSet => {
        const tempQSet = qSet.questions.map(item => {
          if (item.questionId === questionId) {
            if (isAnswerNo) {
              const { answer: currentAnswer } = getAnswerById(questionId, allFeedbacks);
              const validation = { questionId, showError: true, errorMsg: ['This field is required'] };
              tempValidations = {
                ...tempValidations,
                [questionId]: validation
              };
              if (!currentAnswer) {
                // no answer
                tempValidationErrors.push(validation);
              } else {
                /*
                 * if answer is 'NO' but depnedency questions already have answers then validation errors should remove
                 * */
                const index = tempValidationErrors.findIndex(validation => validation.questionId === questionId);
                tempValidationErrors.splice(index, 1);
              }
            }
            if (!isAnswerNo) {
              /*
               * answer is not equal to 'NO', should remove the question ids 21 24 form ValidationErrors
               */
              const index = tempValidationErrors.findIndex(validation => validation.questionId === questionId);
              tempValidationErrors.splice(index, 1);
              delete tempValidations[questionId];
            }
            /*
             * if question is 'Why was the product NOT sold?'
             * then based on the 'Sold Status' answer
             * need to disable or enable
             * */
            if (questionId === WHY_WAS_THE_PRODUCT_NOT_SOLD_QA) {
              return { ...item, disabled: !isAnswerNo, answer: '', required: isAnswerNo };
            }

            return { ...item, disabled: false, answer: '', required: isAnswerNo };
          }

          return item;
        });
        return { ...qSet, questions: tempQSet };
      });
      /*
       * when the answer is not equal to 'NO'
       * should clear 'Why was the product NOT sold?'  answer
       * */
      if (!isAnswerNo) {
        if (questionId === WHY_WAS_THE_PRODUCT_NOT_SOLD_QA) {
          dispatch(updateFeedbackLocally({ questionId, answer: '' }));
        }
      }
    });
    setQuestions(temQuestions);
    setValidationErrors(tempValidationErrors);
    setValidations(tempValidations);
  };

  /**
   * updates feedback
   * @param {*} data
   */
  const updateEachFeedback = data => {
    const { question, answer } = data;
    let tempValidationErrors = [...validationErrors];

    if (answer) {
      tempValidationErrors = removeValidationError(question, answer, tempValidationErrors);
    } else {
      const validation = validations[question.questionId];
      if (validation) {
        tempValidationErrors.push(validation);
      }
    }
    setValidationErrors([...tempValidationErrors]);

    updateRequiredQuestions(data);

    if (question.question === 'Sold Status') {
      validateSoldStatusAnswer(data);
    }
    dispatch(updateFeedbackLocally({ questionId: question.questionId, answer }));
  };

  const getInputValues = question => {
    if (question.disabled) {
      return question.answer || '';
    }
    if (question.questionId) {
      const feedback = _.find(allFeedbacks, { questionId: question.questionId });
      return feedback ? feedback.answer : '';
    }
    return '';
  };

  /**
   * handles checkbox input values
   * since antd checkbox group returns an array of checked answer values,
   * we need to pick the last selected answer only
   */
  const handleCheckboxInput = (question, answers) => {
    if (answers && answers.length === 0) {
      updateEachFeedback({ question, answer: '' });
    } else {
      const currentlySelectedAnswer = getAnswerById(question.questionId, allFeedbacks);
      const lastSelectedAnswer = answers.find(item => item !== currentlySelectedAnswer.answer);

      updateEachFeedback({ question, answer: lastSelectedAnswer });
    }
  };

  /**
   * handles checkbox group input values
   * @param {question} question
   * @param {Array of all currently selected answers} answers
   */
  const handleMultiSelectInput = (question, answers) => {
    updateEachFeedback({ question, answer: [...answers] });
  };

  /**
   * fetch feedback by conversionId
   * @param {conversionId} conversionId
   */
  const fetchFeedback = id => {
    if (modifications) {
      dispatch(retrieveFeedback({ conversionId: id, modifications }));
    } else {
      dispatch(retrieveFeedback({ conversionId: id }));
    }
  };

  useEffect(() => {
    setRequiredQuestions([]);
    setValidationErrors([]);
    setValidations([]);
    if (conversionId) {
      fetchFeedback(conversionId);
    }
  }, []);

  useEffect(() => {
    if (conversionId) {
      fetchFeedback(conversionId);
    }
  }, [conversionId]);

  // update required questions on questions and feedback fetch
  useEffect(() => {
    if (allFeedbacks && allFeedbacks.length > 0) {
      setRequiredQuestions([]);
      setValidationErrors([]);
      setValidations([]);
      _reqQuestions = [...requiredQuestions];
      allFeedbacks.forEach(item => {
        if (item && item.answer.length > 0) {
          const question = getQuestionById(item.questionId, questions);
          if (question) {
            updateRequiredQuestions({ question, answer: item.answer });
          }
        }
        if (item.questionId === SOLD_STATUS_QA && item.answer) {
          validateSoldStatusAnswer({ answer: item.answer });
        }
      });
    }
  }, [originalQuestions, feedbackUpdatingStatus]);

  // modify questions
  useEffect(() => {
    const tempQuestion = originalQuestions.map(qSet => {
      const tempQuestions = qSet.questions.map(question => {
        if (question.questionId === WHY_WAS_THE_PRODUCT_NOT_SOLD_QA) {
          return {
            ...question,
            disabled: true
          };
        }
        return question;
      });
      return {
        ...qSet,
        questions: tempQuestions
      };
    });
    if (modifications) {
      setQuestions(getModifiedQuestions(tempQuestion, modifications));
    } else {
      setQuestions(tempQuestion);
    }
  }, [originalQuestions]);

  useEffect(() => {
    if (onChangeErrors) {
      onChangeErrors(validationErrors);
    }
  }, [validationErrors]);

  return (
    <div className="questions">
      <Spin spinning={enableLoadingScreen && feedbackUpdatingStatus}>
        {questions.map((qSet, qSetIndex) => {
          if (!qSet) {
            return <span key={qSet.questionType}></span>;
          }

          return (
            <div key={qSet.questionType} className="question-row">
              <div className="title">
                {` ${qSetIndex + 1}. ${qSet?.questionType?.replace(/_/g, ' ').toLowerCase()}`}
              </div>
              <div className="question">
                <ul>
                  {qSet.questions &&
                    qSet.questions.map(question => {
                      return (
                        <li key={`quesion-${question.questionId}`}>
                          <div className="subtitle" id={`feedback-input-${question?.questionId}`}>
                            {`${question?.questionNum}.${question?.question}`}
                            {isRequiredQuestion(question?.questionId, requiredQuestions) && (
                              <span className="required-mark">*</span>
                            )}
                          </div>
                          <div className="input">
                            {question?.answerType === OPP_FEEDBACK_QUESTION_TYPE.TEXT && (
                              <TextArea
                                key={question?.questionNum}
                                disabled={question.disabled || false}
                                value={getInputValues(question)}
                                id={`input-${question.questionId}`}
                                onChange={e => updateEachFeedback({ question, answer: e.target.value })}
                                allowClear
                              />
                            )}

                            {question?.answerType === OPP_FEEDBACK_QUESTION_TYPE.RADIO && (
                              <Radio.Group
                                key={question?.questionNum}
                                disabled={question.disabled || false}
                                id={`input-${question?.questionId}`}
                                value={getInputValues(question)}
                                onChange={e => updateEachFeedback({ question, answer: e.target.value })}
                              >
                                {question.answers &&
                                  question.answers.map(answer => {
                                    return (
                                      <Radio key={answer} value={answer}>
                                        {answer}
                                      </Radio>
                                    );
                                  })}
                              </Radio.Group>
                            )}

                            {question.answers && question.answerType === OPP_FEEDBACK_QUESTION_TYPE.SELECT && (
                              <Select
                                style={{ width: '100%', marginTop: '5px', color: '#6a737b' }}
                                disabled={question.disabled || false}
                                id={`input-${question?.questionId}`}
                                defaultValue={getInputValues(question)}
                                value={getInputValues(question)}
                                onChange={v => updateEachFeedback({ question, answer: v })}
                              >
                                <Option value="">Choose Option...</Option>
                                {question.answers &&
                                  question.answers.map((val, index) => (
                                    <Option key={val} value={val}>
                                      {val}
                                    </Option>
                                  ))}
                              </Select>
                            )}

                            {question?.answerType === OPP_FEEDBACK_QUESTION_TYPE.CHECKBOX && (
                              <Checkbox.Group
                                key={question?.questionNum}
                                disabled={question.disabled || false}
                                id={`input-${question?.questionId}`}
                                value={[getInputValues(question)]}
                                options={question.answers}
                                onChange={e => handleCheckboxInput(question, e)}
                              />
                            )}

                            {question?.answerType === OPP_FEEDBACK_QUESTION_TYPE.MULTISELECT && (
                              <Checkbox.Group
                                key={question?.questionNum}
                                disabled={question.disabled || false}
                                id={`input-${question?.questionId}`}
                                value={
                                  _.isArray(getInputValues(question))
                                    ? getInputValues(question)
                                    : getInputValues(question).split(',')
                                }
                                options={question.answers}
                                onChange={e => handleMultiSelectInput(question, e)}
                              />
                            )}

                            <div className="error-message">
                              {getValidationErrorMessageById(question?.questionId, validationErrors)}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          );
        })}
      </Spin>
    </div>
  );
};

export default FeedBackForm;
