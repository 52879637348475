export const getFormattedChartData = (data, sort = null, formatData = null) => {
  let formattedData = [];
  const total = data.reduce((r, d) => r + (d.keyName ? parseInt(d.value) : 0), 0);
  data.forEach(item => {
    let type = item.keyName;
    if (!type) return;

    if (typeof type === 'string') {
      // convert key value to label
      if (type.includes('_')) {
        type = type.split('_').join(' ');
      }
    }
    // eslint-disable-next-line radix
    const value = parseInt(item.value) || 0;
    const percentage = ((value / total) * 100).toFixed(1);
    formattedData.push({ type, value, keyName: item.keyName, percentage });
  });

  if (sort === 'desc') {
    formattedData.sort((a, b) => (a.value > b.value ? -1 : 1));
  }
  if (sort === 'asc') {
    formattedData.sort((a, b) => (a.value > b.value ? 1 : -1));
  }

  if (formatData) {
    formattedData = formatData(formattedData);
  }

  return formattedData;
};
