import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { OPP_LIST, USER_TYPE_BROKER_ROLE } from 'utils/Constants';
import Home from './pages/Home';
import PlaceOrder from 'pages/PlaceOrder';
import AppLoader from 'components/AppLoader';
import * as userActions from 'actions';

// TODO: Add the correct app logo
import styles from './styles/BrokerApp.scss';
import _ from 'lodash';
import AppLogo from './styles/images/app-logos/broker-app.svg';
import DefaultLayout from './layout/DefaultLayout';
import Dashboard from './pages/dashboard/Dashboard';

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = '/ui/brokeroppt' + AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

function MainApp({ opptList, actions, listFetching, userId, user }) {
  setAppLogo();
  setAppHeaderToAppMode();

  useEffect(() => {
    actions.loadUserDetails(userId);
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  useEffect(() => {
    if (_.isEmpty(opptList)) {
      actions.loadQuestions();
    }
  }, [actions.loadList, actions.retriveOpprtunityFiltrs]);

  useEffect(() => {
    if (user?.includes(USER_TYPE_BROKER_ROLE)) {
      actions.retriveOpprtunityFiltrs();
      actions.loadTranches();
    }
  }, [user]);

  if (!listFetching) {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <Route exact path="/suite/brokeroppt/placeorder" component={PlaceOrder} />
            <DefaultLayout>
              <Route exact path="/suite/brokeroppt" component={Home} />
              <Route exact path="/suite/brokeroppt/dashboard" component={Dashboard} />
            </DefaultLayout>
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  } else {
    return <AppLoader show />;
  }
}

function mapStateToProps(state) {
  return {
    opptList: [],
    listFetching: false,
    user: state?.user?.data?.roles
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainApp);
