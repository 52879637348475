import { all } from 'redux-saga/effects';
import userSaga from './userSaga';
import feedbackSaga from './feedbackSaga';
import notificationSaga from './notificationSaga';
import OppSaga from './oppSaga';
import feedbackStatusSaga from './feedbackStatusSaga';
import orderValidationSaga from './orderValidationsSaga';
import placeOrderSaga from './placeOrderSaga';
import orderStatusSaga from './orderStatusSaga';
import brokerList from './brokerListSaga';
import dashboardTrancheListSaga from './dashboardScoreCardSaga';
import dashboardCharts from './dashboardChartsSaga';
import dashboardOrderSummerySaga from './dashboardOrderTableSaga';
import HierarchySaga from './hierachySaga';

export default function* rootSaga() {
  yield all([
    ...userSaga,
    ...brokerList,
    ...OppSaga,
    ...feedbackSaga,
    ...notificationSaga,
    ...feedbackStatusSaga,
    ...orderValidationSaga,
    ...placeOrderSaga,
    ...orderStatusSaga,
    ...dashboardTrancheListSaga,
    ...dashboardCharts,
    ...dashboardOrderSummerySaga,
    ...HierarchySaga
  ]);
}
