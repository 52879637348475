import _ from 'lodash';
import { CONVERSION_ITEM_VALIDATION_FIELD, ERROR_MESSAGES, REGEXP_NUMBERS_ONLY } from 'utils/Constants';
import { calculatePrice } from '../components/OpportunityListViewLogic';

/**
 * returns an array of objects to populate customer select options
 * @param {} opportunityList
 * @returns
 */
const getCustomerSelectOptions = opportunityList => {
  const customerSet = new Set();
  if (opportunityList && opportunityList.length > 0) {
    opportunityList.forEach(item => {
      customerSet.add({ customerNum: item.customerNum, customerName: item.customerName });
    });
  }

  return [...customerSet];
};

/**
 * returns an array of objects to populate siteId select options
 * @param {} opportunityList
 * @returns
 */
const getSiteIdSelectOptions = opptFilters => {
  const sitesMap = new Map();

  if (opptFilters.markets && opptFilters.markets.length > 0) {
    opptFilters.markets.forEach(market => {
      if (market.regions && market.regions.length > 0) {
        market.regions.forEach(region => {
          if (region && region.sites && region.sites.length > 0) {
            region.sites.forEach(site => {
              sitesMap.set(site.siteId, site.siteName);
            });
          }
        });
      }
    });
  }
  let _sites = [];
  sitesMap.forEach((val, key) => {
    _sites.push({ siteId: key, siteName: val });
  });
  return _.orderBy(_sites, ['siteId'], ['asc']);
};

/**
 * add new item into the conversionItems array
 */
const addNewConversionItem = conversionItems => {
  let key = 1;
  if (conversionItems && conversionItems.length > 0 && conversionItems[conversionItems.length - 1].key) {
    key = conversionItems[conversionItems.length - 1].key + 1;
  }

  const item = {
    conversionId: '',
    fromItemNum: '',
    fromItemDesc: '',
    fromBrandId: '',
    fromPack: null,
    fromSize: '',
    fromSupplier: '',
    fromCasesSold: '',
    fromPoundsSold: '',
    toItemNum: '',
    split: '',
    toItemDesc: '',
    toBrandId: '',
    toPack: null,
    toSize: '',
    toSupplier: '',
    toBroker: null,
    recommendedSellPrice: null,
    uom: '',
    key: key,
    isNewItem: true,
    operation: key,
    supcChanged: false,
    caseChanged: false,
    splitChanged: false,
    priceUpdateRequired: true
  };

  return conversionItems ? [...conversionItems, item] : [item];
};

/**
 * validates a given product info list with conversions
 */
const validateProductInfoList = (
  _productInfoList,
  _itemValidationErrors,
  _conversionItems,
  _supcValidatedConversions,
  enableSplit
) => {
  const validProdInfoItems = [];
  const prodInfoValidatedConversions = [];
  const _validationErrors = [..._itemValidationErrors];
  const _convItems = [..._conversionItems];

  _supcValidatedConversions.forEach(item => {
    const prodInfoItem = _productInfoList[item.toItemNum];
    if (prodInfoItem) {
      const validationErrorItem = getValidationErrorItemForConversion(item.key, _validationErrors);
      const fieldValidationErrors = validationErrorItem.errors;

      const convItemIndex = _convItems.findIndex(convItem => convItem.key === item.key);
      const { prodInfoError, conversionItem } = validateProductInfo(
        prodInfoItem,
        _convItems[convItemIndex],
        enableSplit
      );
      const modifyObject = {
        ...conversionItem,
        itemDescription: prodInfoItem?.name,
        categoryName: prodInfoItem?.categoryName
      };
      _convItems[convItemIndex] = modifyObject;

      fieldValidationErrors[CONVERSION_ITEM_VALIDATION_FIELD.case.key] = prodInfoError;
      if (!prodInfoError) {
        validProdInfoItems.push(prodInfoItem);
        prodInfoValidatedConversions.push(item);
      }
    }
  });

  return {
    validProdInfoItems: validProdInfoItems,
    validationErrors: _validationErrors,
    prodInfoValidatedConversions: prodInfoValidatedConversions,
    conversionItemsList: _convItems
  };
};

/**
 * validates product info for a single item
 * @param {*} productInfoItem
 * @param {*} convItem
 * @returns
 */
const validateProductInfo = (productInfoItem, convItem, enableSplit) => {
  const _convItem = { ...convItem };

  if (!productInfoItem.active) {
    // if product is inactive, split and case should be disabled
    const prodInfoError = { showError: true, errorMessage: ERROR_MESSAGES.PRODUCT_INACTIVE };
    setInputFieldEnabled(_convItem, 'case', false, enableSplit);
    setInputFieldEnabled(_convItem, 'split', false, enableSplit);
    return { prodInfoError, conversionItem: _convItem };
  }

  if (!productInfoItem.isOrderable) {
    // if product is not Orderable, split and case should be disabled
    const prodInfoError = { showError: true, errorMessage: ERROR_MESSAGES.PRODUCT_IS_NOT_ORDERABLE };
    setInputFieldEnabled(_convItem, 'case', false, enableSplit);
    setInputFieldEnabled(_convItem, 'split', false, enableSplit);
    return { prodInfoError, conversionItem: _convItem };
  }

  // if isSplit is false, split field should be disabled
  if (!productInfoItem.isSplit) {
    setInputFieldEnabled(_convItem, 'case', true, enableSplit);
    setInputFieldEnabled(_convItem, 'split', false, enableSplit);
    return { prodInfoError: null, conversionItem: _convItem };
  }

  // if everything is good, case and split should be enabled
  setInputFieldEnabled(_convItem, 'case', true, enableSplit);
  setInputFieldEnabled(_convItem, 'split', true, enableSplit);
  return { prodInfoError: null, conversionItem: _convItem };
};

/**
 * return error items for given conversion item key
 * @param {*} conversionItemKey
 * @param {*} validationErrors
 * @returns
 */
const getValidationErrorItemForConversion = (conversionItemKey, validationErrors) => {
  let errorItem = validationErrors.find(_item => _item.key === conversionItemKey);
  if (!errorItem) {
    errorItem = { key: conversionItemKey, errors: {} };
    validationErrors.push(errorItem);
  }

  return errorItem;
};

/**
 * change input field's enabled status
 * @param {*} conversionItem
 * @param {*} field
 * @param {*} isEnable
 * @returns
 */
const setInputFieldEnabled = (conversionItem, field, isEnable, enableSplit) => {
  switch (field) {
    case 'case':
      conversionItem.isEnableCase = isEnable;
      break;
    case 'split':
      conversionItem.isEnableSplit = !enableSplit ? false : isEnable;
  }
  return conversionItem;
};

/**
 * validates a given set of conversion items with ASOH
 * @param {*} asohList
 * @param {*} convItem
 * @returns
 */
const validateAsohList = (_asohList, convItems, _itemValidationErrors, enableSplit) => {
  const validProductInstock = [];
  const _validationErrors = [..._itemValidationErrors];

  if (convItems && convItems.length > 0 && _asohList && _asohList.length > 0) {
    convItems.forEach(convItem => {
      const validationErrorItem = getValidationErrorItemForConversion(convItem.key, _validationErrors);
      const fieldValidationErrors = validationErrorItem.errors;

      let asohCaseValidationError, asohSplitValidationError;

      // validate for cases
      if (convItem.caseChanged) {
        asohCaseValidationError = validateAsoh(_asohList, convItem, 'case');
        fieldValidationErrors[CONVERSION_ITEM_VALIDATION_FIELD.case.key] = asohCaseValidationError;
      }

      // validate for splits
      if (convItem.splitChanged && enableSplit) {
        asohSplitValidationError = validateAsoh(_asohList, convItem, 'split');
        fieldValidationErrors[CONVERSION_ITEM_VALIDATION_FIELD.split.key] = asohSplitValidationError;
      }

      if (!asohCaseValidationError && !asohSplitValidationError) {
        validProductInstock.push(convItem);
      }
    });
  }

  return { asohValidConversionItems: validProductInstock, asohValidationErrors: _validationErrors };
};

/**
 * performs ASOH validation for a single item
 * @param {*} asohList
 * @param {*} convItem
 * @param {*} type
 * @returns
 */
const validateAsoh = (_asohList, convItem, type) => {
  switch (type) {
    case 'case':
      return validateAsohCase(_asohList, convItem);
    case 'split':
      return validateAsohSplit(_asohList, convItem);
  }
  return null;
};

/**
 * validate case field with the asoh
 * @param {*} asohList
 * @param {*} convItem
 * @returns
 */
const validateAsohCase = (_asohList, convItem) => {
  let asohCaseItems = _asohList.filter(
    item =>
      item.supc === String(convItem.toItemNum).padStart(7, '0') &&
      item.splitIndicator !== 'S' &&
      item.splitIndicator !== 'C'
  );

  if (asohCaseItems.length > 0) {
    const asohItem = asohCaseItems[0];
    if (asohItem.availableOnHand === 0 && asohItem.nextReceiveDate === '') {
      return { showError: true, errorMessage: ERROR_MESSAGES.PRODUCT_IS_OUT_OF_STOCKS };
    }
    if (parseInt(convItem.fromPack) > parseInt(asohItem.availableOnHand)) {
      return {
        showError: true,
        errorMessage: ERROR_MESSAGES.ONLY_N_ITEMS_ARE_AVAILABLE.replace('{num}', asohItem.availableOnHand)
      };
    }
  }

  return null;
};

/**
 * validate split field with the asoh
 * @param {*} asohList
 * @param {*} convItem
 * @param {*} type
 * @returns
 */
const validateAsohSplit = (_asohList, convItem) => {
  let asohSplitItems = _asohList.filter(
    item => item.supc === String(convItem.toItemNum).padStart(7, '0') && item.splitIndicator === 'S'
  );

  if (asohSplitItems && asohSplitItems.length > 0) {
    const asohItem = asohSplitItems[0];
    if (asohItem.availableOnHand === 0 && asohItem.nextReceiveDate === '') {
      return { showError: true, errorMessage: ERROR_MESSAGES.PRODUCT_IS_OUT_OF_STOCKS };
    }
    if (parseInt(convItem.split) > parseInt(asohItem.availableOnHand)) {
      return {
        showError: true,
        errorMessage: ERROR_MESSAGES.ONLY_N_ITEMS_ARE_AVAILABLE.replace('{num}', asohItem.availableOnHand)
      };
    }
  }

  return null;
};

/**
 * returns requested value from conversion item
 * @param {*} conversionIemKey
 * @param {*} inputKey
 * @returns
 */
const getInputValue = (conversionItems, conversionIemKey, inputKey) => {
  const conversionItem = conversionItems.find(item => item.key === conversionIemKey);
  let returnVal = '';
  if (conversionItem) {
    returnVal = conversionItem[inputKey] || '';
  }
  return returnVal;
};

/**
 * returns an object for place order request body
 * @param {*} args
 */
const getPlaceOrderRequestBody = args => {
  const { _conversionItems, _note, _prcpPricingArray, tranches, customerNum, brokerContact, site } = args;
  const trancheId = tranches && tranches.length > 0 ? tranches[0]?.tranche : null;
  const placeOrderBody = {};
  placeOrderBody['customerNo'] = customerNum;
  placeOrderBody['createdBy'] = brokerContact;
  placeOrderBody['orderConfirmationNumber'] = '';
  placeOrderBody['brokerCode'] = '';
  placeOrderBody['tranche'] = trancheId;
  placeOrderBody['status'] = 'CREATED';
  placeOrderBody['updatedBy'] = '';
  placeOrderBody['siteId'] = site;

  // set note
  placeOrderBody['note'] = _note;

  // set items
  const _items = [];
  _conversionItems.forEach(_convItem => {
    // get pricing items
    const casePricingItem = _prcpPricingArray.find(
      item => item.supc === String(_convItem.toItemNum).padStart(7, '0') && !item.splitFlag
    );
    const splitPricingItem = _prcpPricingArray.find(
      item => item.supc === String(_convItem.toItemNum).padStart(7, '0') && item.splitFlag
    );

    const _item = {
      caseQty: _convItem.fromPack && _convItem.fromPack.length !== '' ? parseInt(_convItem.fromPack) : 0,
      splitQty: _convItem.split && _convItem.split.length !== '' ? parseInt(_convItem.split) : 0,
      createdBy: brokerContact,
      itemId: String(_convItem.toItemNum),
      pricePerCase: casePricingItem ? calculatePrice(casePricingItem) : 0,
      pricePerSplit: splitPricingItem ? splitPricingItem.netPrice : 0,
      updatedBy: '',
      itemDescription: _convItem.itemDescription,
      categoryName: _convItem.categoryName
    };

    _items.push(_item);
  });
  placeOrderBody['items'] = _items;

  return placeOrderBody;
};

/**
 * set additional information to newly added conversion item
 * uses broker tie item to get necessary details
 * @param {*} conversionItem
 * @param {*} brokerTieItem
 */
const setDetailsToConversionItem = (conversionItem, brokerTieItem) => {
  // curently it use broker tie.
  if (conversionItem && brokerTieItem) {
    conversionItem.toItemDesc = brokerTieItem.itemDescr;
    conversionItem.toBrandId = brokerTieItem.brand;
  }
};

/**
 * validate SUPCs for all given conversions
 * @param {*} convItems
 */
const validateSupcs = (convItems, brokerTies, validationErrors, enableSplit, allowedSupcLengths) => {
  const validConversionItems = [];
  const _validationErrors = [...validationErrors];

  if (convItems && convItems.length > 0) {
    convItems.forEach(convItem => {
      // Only changed items will be validated
      if (convItem.supcChanged) {
        const validationErrorItem = getValidationErrorItemForConversion(convItem.key, _validationErrors);
        const fieldValidationErrors = validationErrorItem.errors;

        const supcValidationError = validateSupc(convItem, brokerTies, allowedSupcLengths);
        fieldValidationErrors[CONVERSION_ITEM_VALIDATION_FIELD.supc.key] = supcValidationError;

        // remove other fields validation errors
        const errorFieldKeys = Object.keys(fieldValidationErrors);
        errorFieldKeys.forEach(key => {
          if (key !== CONVERSION_ITEM_VALIDATION_FIELD.supc.key) {
            fieldValidationErrors[key] = null;
          }
        });

        if (!supcValidationError) {
          validConversionItems.push(convItem);
        }

        // disable case and split inputs
        setInputFieldEnabled(convItem, 'case', false, enableSplit);
        setInputFieldEnabled(convItem, 'split', false, enableSplit);
      }
    });
  }

  return { supcValidationErrors: _validationErrors, supcValidConversionItems: validConversionItems };
};

/**
 * return error object from itemValidationErrors
 * @param {key of the conversion item} itemKey
 * @param {key of the table input} inputKey
 * @returns
 */
const getError = (itemKey, inputKey, valdiationErrors) => {
  const item = valdiationErrors.find(_item => {
    return _item.key === itemKey;
  });
  if (!item || !item.errors) return null;
  return item.errors[inputKey];
};

/**
 * validates supc number for single conversion item
 * @param {*} inputValue
 * @returns
 */
const validateSupc = (conversionItem, brokerTies, allowedSupcLengths) => {
  const supc = conversionItem.toItemNum;
  const trimmedInputVal = supc.trim();

  // check input value is empty
  if (!trimmedInputVal || trimmedInputVal.length === 0) {
    return { showError: true, errorMessage: ERROR_MESSAGES.REQUIRED_FIELD };
  }

  // validate supc for only numbers
  const isSupcNumbersOnly = REGEXP_NUMBERS_ONLY.test(trimmedInputVal);
  if (!isSupcNumbersOnly) {
    return { showError: true, errorMessage: ERROR_MESSAGES.INVALID_INPUT };
  }

  // validate SUPC for 7 characters
  const isValidLength = allowedSupcLengths.includes(trimmedInputVal.length);
  if (!isValidLength) {
    return {
      showError: true,
      errorMessage: ERROR_MESSAGES.NUM_CHARACTERS_ARE_REQUIRED
    };
  }

  // validate supc number using broker ties
  // check if conversion item's toItemNum exists in broker ties' as itemId
  const isItemAvailableInBrokerTies =
    brokerTies && brokerTies.length > 0
      ? brokerTies.some(brokerTie => parseInt(brokerTie.itemId) === parseInt(conversionItem.toItemNum))
      : false;
  if (!isItemAvailableInBrokerTies) {
    return { showError: true, errorMessage: ERROR_MESSAGES.ITEM_TIE_NOT_AVAILABLE };
  }

  return null;
};

/**
 * check if order validation errors exists using given validation errors array
 * @param {*} validationErrors
 */
const isOrderErrorExists = validationErrors => {
  let isErrorExists = false;

  for (const validationError of validationErrors) {
    if (validationError && validationError.errors) {
      const errorKeys = Object.keys(validationError.errors);
      for (const errorKey of errorKeys) {
        if (validationError.errors[errorKey]) {
          isErrorExists = true;
          break;
        }
      }
    }
  }

  return isErrorExists;
};

/**
 * check conversion items has changed
 * @param {*} convItems
 */
const hasConversionItemsChanged = convItems => {
  let isChanged = false;
  if (convItems && convItems.length > 0) {
    isChanged = convItems.some(item => item.priceUpdateRequired);
  }

  return isChanged;
};

/**
 * returns total price for a conversion
 * if item has case and a split value, then both values will be summed up.
 * @param {*} conversionItem
 * @param {*} prcpPricingArray
 * @returns
 */
const getTotalPrice = (conversionItem, prcpPricingArray) => {
  if (!conversionItem || conversionItem.priceUpdateRequired || !prcpPricingArray || prcpPricingArray.length === 0) {
    return null;
  }
  const split = conversionItem.split && conversionItem.split !== '' ? true : false;

  const pricingItems = prcpPricingArray.filter(
    item =>
      item.supc === String(conversionItem.toItemNum).padStart(7, '0') &&
      item.quantity === conversionItem.fromPack &&
      item.splitFlag === split
  );
  let totalPrice = 0;
  pricingItems.forEach(item => (totalPrice += item.quantity * calculatePrice(item)));
  return totalPrice;
};

/**
 * returns unit price for a conversion
 * @param {*} conversionItem
 * @param {*} prcpPricingArray
 * @returns
 */
const getUnitPrice = (conversionItem, prcpPricingArray) => {
  if (!conversionItem || conversionItem.priceUpdateRequired || !prcpPricingArray || prcpPricingArray.length === 0) {
    return null;
  }

  const pricingItem = prcpPricingArray.find(
    item => parseInt(item.supc) === parseInt(conversionItem.toItemNum) && conversionItem.fromPack === item.quantity
  );

  if (pricingItem) {
    return pricingItem.unitPrice ? calculatePrice(pricingItem) : null;
  }

  return null;
};

/**
 * returns packSize/unitsPerCase for a given supc
 * @param {*} _asohList
 * @returns
 */
const getPackSize = (supc, _asohList) => {
  const asohItem = _asohList.find(
    item => parseInt(supc) === parseInt(item.supc) && item.splitIndicator !== 'S' && item.splitIndicator !== 'C'
  );
  if (asohItem) {
    return asohItem.unitsPerCase;
  }

  return '--';
};

export {
  getCustomerSelectOptions,
  getSiteIdSelectOptions,
  addNewConversionItem,
  validateProductInfoList,
  getValidationErrorItemForConversion,
  setInputFieldEnabled,
  validateProductInfo,
  validateAsohList,
  getInputValue,
  getPlaceOrderRequestBody,
  setDetailsToConversionItem,
  validateSupcs,
  getTotalPrice,
  validateAsoh,
  getError,
  getUnitPrice,
  getPackSize,
  hasConversionItemsChanged,
  isOrderErrorExists
};
