import initialState from 'store/initialState';

import { DASHBOARD_SCORE_CARD_SALES } from 'actions/actionTypes';

export const dashboardScoreCardSales = (state = initialState.dashboardScoreCardSales, _action) => {
  switch (_action.type) {
    case DASHBOARD_SCORE_CARD_SALES.REQUEST:
      return { ...state, fetching: true, data: [], error: null };
    case DASHBOARD_SCORE_CARD_SALES.SUCCESS:
      return { ...state, fetching: false, data: _action.payload, error: null };
    case DASHBOARD_SCORE_CARD_SALES.FAILURE:
      return { ...state, fetching: false, data: [], error: _action.error };
    case DASHBOARD_SCORE_CARD_SALES.CLEAR:
      return { ...state, fetching: false, data: [], error: null };
    default:
      return state;
  }
};

export default dashboardScoreCardSales;
