import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardCharts from './DashboardCharts';
import DashboardTable from './DashboardTable';

import Filters from '../../components/Filters';
import DashboardScoreCard from './DashboardScoreCard';

function Dashboard() {
  const dispatch = useDispatch();

  // Selectors

  // State

  const [formData, setFormData] = useState({ broker: '', tranche: '', filters: {} });
  const [filtersObject, setFiltersObject] = useState({});

  //  useEffect(() => {
  // //   dispatch(loadBrokerList());
  //  }, []);

  return (
    <div className="home-nav-tab">
      <Filters
        filtersObject={filtersObject}
        onSelectedTranche={tranche => setFormData(prev => ({ ...prev, tranche }))}
        onSelectedBroker={broker => setFormData({ broker, tranche: '', filters: {} })}
        onSearch={setFormData}
      />
      {/* <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => ( */}
      <DashboardScoreCard data={formData} />
      <DashboardCharts data={formData} />
      <DashboardTable data={formData} />
    </div>
  );
}
export default Dashboard;
