import { takeEvery, takeLatest } from 'redux-saga/effects';
import { BROKER_TIES, ENTERPISE_PRCP_PRICES, PRODUCT_INFO, ASOH } from 'actions/actionTypes';
import { loadBrokerTiesAsync, loadPrcpPricing, checkAsoh, getProductInfo } from './OrderValidationsSaga';

const orderValidationSaga = [
  takeEvery(BROKER_TIES.REQUEST, loadBrokerTiesAsync),
  takeLatest(PRODUCT_INFO.REQUEST, getProductInfo),
  takeLatest(ASOH.REQUEST, checkAsoh),
  takeLatest(ENTERPISE_PRCP_PRICES.REQUEST, loadPrcpPricing)
];

export default orderValidationSaga;
