import _ from 'lodash';
import { CHART_TYPES } from '../utils/Constants';

export const trancheList = state => _.get(state.dashboardTrancheList, 'data.data', []);
export const trancheListLoading = state => _.get(state.dashboardTrancheList, 'fetching', false);

export const soldStatusSummery = state => _.get(state.dashboardCharts, `${CHART_TYPES.SOLD_STATUS_SUMMARY}`, {});
export const notSoldReasonSummery = state =>
  _.get(state.dashboardCharts, `${CHART_TYPES.NOT_SOLD_REASONS_SUMMARY}`, {});
export const closedOppByEngagementSummery = state =>
  _.get(state.dashboardCharts, `${CHART_TYPES.CLOSED_OPP_BY_ENGAGEMENTS_SUMMARY}`, {});
export const customerCommitmentSummery = state =>
  _.get(state.dashboardCharts, `${CHART_TYPES.CUSTOMER_COMMITMENT_SUMMARY}`, {});
export const orderPlacementSummery = state =>
  _.get(state.dashboardCharts, `${CHART_TYPES.ORDER_PLACEMENT_SUMMARY}`, {});
export const scoreCardConversion = state => state.dashboardScoreCardConversion;
export const scoreCardSales = state => state.dashboardScoreCardSales;

export const orderSummery = state => state.dashboardOrderTableReducer;
export const orderTableSummeryReportDownloadState = state => state.orderTableSummeryReportDownload;
