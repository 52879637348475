import React from 'react';
import { Spin, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const ScoreCard = ({ data }) => {
  return (
    <div className="chart score-card">
      <Spin spinning={data.loading}>
        <>
          <div className="chart-title">
            {data.header.title}
            {data.header.tootleTip && (
              <Tooltip title={<div dangerouslySetInnerHTML={{ __html: data.header.tootleTip }} />}>
                <InfoCircleFilled className="icon info-icon" />
              </Tooltip>
            )}
          </div>

          <div className="chart-content">
            {data.content.map((section, index) => (
              <div className="score-block" key={index}>
                <div className="score-value">{section.value}</div>
                {section.title && <div className="score-title">{section.title}</div>}
              </div>
            ))}
          </div>
        </>
      </Spin>
    </div>
  );
};
export default ScoreCard;
