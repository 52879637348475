import initialState from 'store/initialState';
import { BROKER_TIES } from 'actions/actionTypes';

const brokerTies = (state = initialState.brokerTies, action) => {
  switch (action.type) {
    case BROKER_TIES.PENDING:
      return { ...state, fetching: true };
    case BROKER_TIES.SUCCESS:
      const { rows } = action.payload;
      return { ...state, data: rows || [], fetching: false };
    case BROKER_TIES.FAILURE:
      return { ...state, fetching: false };
    default:
      return state;
  }
};

export default brokerTies;
