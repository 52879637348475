import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import _ from 'lodash';

import {
  LIST,
  QUESTIONS,
  LIST_OPP_FILTERS,
  CONVERSION_DETAILS,
  FEEDBACK_STATUS,
  TRANCHES,
  CUSTOMER_NUMS_IN_SITE
} from 'actions/actionTypes';
import { action } from 'reduxHelpers';
import { OPP_LIST } from 'utils/Constants';
import { checkBrokerCode, checkTrancheCode } from 'utils/Util';

function* loadListAsync(payload) {
  try {
    const { filters, pageSize, requestedPage, sortParams, brokerCode, allBrokers } = payload;
    // eslint-disable-next-line no-underscore-dangle
    const _sortParams = sortParams
      ? encodeURIComponent(JSON.stringify(sortParams))
      : encodeURIComponent(JSON.stringify(OPP_LIST.DEFAULT_SORT_PARAMS));

    const filterParams = filters ? encodeURIComponent(JSON.stringify(filters)) : encodeURIComponent(JSON.stringify({}));

    const response = yield getRequest(
      `/oppt/listfiltered?currentPage=${requestedPage || 0}&pageSize=${pageSize ||
        OPP_LIST.DEFAULT_PAGE_SIZE}&sortParams=${_sortParams}&filters=${filterParams}${checkBrokerCode(
        brokerCode
      )}&all-brokers=${allBrokers}`
    );

    let data = [];

    if (response.data.data) {
      const customerIdList = response.data.data.map(opt => {
        return opt.customerNum;
      });
      yield put({ type: FEEDBACK_STATUS.REQUEST, customerIdList, brokerCode });
      data = _.get(response.data, 'data', []);
    }

    yield put({ type: LIST.SUCCESS, payload: { data, totalOpportunities: response.data.totalRowCount } });
  } catch (error) {
    yield put({ type: LIST.FAILURE, payload: error.message });
    if (error.response.status === 404) {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'No opportunities for the search criteria.',
          className: 'error',
          message: 'NO OPPORTUNITIES FOUND'
        })
      );
    } else {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: error.message,
          className: 'error',
          message: 'LIST LOADING ERROR'
        })
      );
    }
  }
}

function* loadQuestionsAsync() {
  try {
    const response = yield getRequest(`/oppt/questions`);
    yield put({ type: QUESTIONS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: QUESTIONS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'QUESTIONS LOADING ERROR'
      })
    );
  }
}

function* loadOpportunityFilersAsync(payload) {
  try {
    const { brokerCode, tranche, allBrokers } = payload;
    const response = yield getRequest(
      `/oppt/filters?${checkBrokerCode(brokerCode)}${checkTrancheCode(tranche)}&all-brokers=${allBrokers}`
    );
    yield put({ type: LIST_OPP_FILTERS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LIST_OPP_FILTERS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'FILTERS LOADING ERROR'
      })
    );
  }
}

function* loadConversionDetailsAsync(customerNumber) {
  try {
    const { brokerCode, bc, ig, ag } = customerNumber;
    let customerNo = customerNumber.custNo;
    const response = yield getRequest(
      `/oppt/conversions/customer/${customerNo}?${checkBrokerCode(brokerCode)}&bc=${bc}&ig=${ig}&ag=${ag}`
    );
    yield put({ type: CONVERSION_DETAILS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CONVERSION_DETAILS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'CONVERSION DETAILS LOADING ERROR'
      })
    );
  }
}

function* loadTranchesAsync(payload) {
  try {
    const { brokerCode, allBrokers } = payload;
    const response = yield getRequest(`/oppt/tranches?${checkBrokerCode(brokerCode)}&all-brokers=${allBrokers}`);
    yield put({ type: TRANCHES.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: TRANCHES.SUCCESS, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'TRANCHE LOADING ERROR'
      })
    );
  }
}

function* loadCustomerIdsForSiteAsync(payload) {
  const { siteId } = payload;

  try {
    const response = yield getRequest(`/oppt/sites/${siteId}/customers`);
    yield put({ type: CUSTOMER_NUMS_IN_SITE.SUCCESS, payload: { data: response.data, siteId } });
  } catch (error) {
    console.log(error);
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'CUSTOMER NUMBERS LOADING ERROR'
      })
    );
  }
}

export {
  loadListAsync,
  loadQuestionsAsync,
  loadOpportunityFilersAsync,
  loadConversionDetailsAsync,
  loadTranchesAsync,
  loadCustomerIdsForSiteAsync
};
