const appendSiteList = (markets, selectedMarkets, selectedRegions) => {
  let validMarkets = markets;
  let updatedSites = [];
  if (selectedRegions.length > 0) {
    validMarkets.map(market => {
      let sitesForSelectedRegion = market.regions.filter(region => selectedRegions.includes(region.regionName));
      sitesForSelectedRegion.map(region => region.sites.map(site => updatedSites.push(site)));
    });
  } else if (selectedMarkets.length > 0) {
    validMarkets = markets.filter(market => selectedMarkets.includes(market.marketName));
    validMarkets.map(market => market.regions.map(region => region.sites.map(site => updatedSites.push(site))));
  } else {
    validMarkets.map(market =>
      market.regions.map(region => {
        region.sites.map(site => updatedSites.push(site));
      })
    );
  }
  return updatedSites;
};

const appendRegionList = (markets, selectedMarkets) => {
  let validMarkets = markets;
  let updatedRegions = [];
  if (selectedMarkets.length > 0) {
    validMarkets = markets.filter(market => selectedMarkets.includes(market.marketName));
  }
  validMarkets.map(market => {
    market.regions.map((region, index) => {
      updatedRegions.push(region);
    });
  });
  return updatedRegions;
};

export { appendSiteList, appendRegionList };
