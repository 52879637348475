import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { AG, BC, IG } from 'actions/actionTypes';
import { action } from 'reduxHelpers';

function* loadBcAsync(payload) {
  const { brokerCode, tranche, allBrokers } = payload;
  try {
    const response = yield getRequest(
      `/oppt/bc?broker-code=${brokerCode}&tranche=${tranche}&all-brokers=${allBrokers}`
    );
    yield put({ type: BC.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BC.SUCCESS, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'BUSINESS CENTER LOADING ERROR'
      })
    );
  }
}
function* loadIGAsync(payload) {
  try {
    const { bc, brokerCode, tranche, allBrokers } = payload;
    const response = yield getRequest(
      `/oppt/ig?broker-code=${brokerCode}&bc=${encodeURIComponent(bc)}&tranche=${tranche}&all-brokers=${allBrokers}`
    );
    yield put({ type: IG.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: IG.SUCCESS, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ITEM GROUP LOADING ERROR'
      })
    );
  }
}
function* loadAGAsync(payload) {
  try {
    const { bc, ig, brokerCode, tranche, allBrokers } = payload;
    const response = yield getRequest(
      `/oppt/ag?bc=${encodeURIComponent(bc)}&ig=${encodeURIComponent(
        ig
      )}&broker-code=${brokerCode}&tranche=${tranche}&all-brokers=${allBrokers}`
    );
    yield put({ type: AG.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: AG.SUCCESS, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ATTRIBUTE GROUP LOADING ERROR'
      })
    );
  }
}

export { loadBcAsync, loadAGAsync, loadIGAsync };
