import { takeLatest } from 'redux-saga/effects';
import { BC, IG, AG } from 'actions/actionTypes';
import { loadBcAsync, loadIGAsync, loadAGAsync } from './HierarchySaga';

const HierarchySaga = [
  takeLatest(BC.REQUEST, loadBcAsync),
  takeLatest(IG.REQUEST, loadIGAsync),
  takeLatest(AG.REQUEST, loadAGAsync)
];

export default HierarchySaga;
