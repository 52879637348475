import _ from 'lodash';

const selectOpportunityList = state => _.get(state.opptList, 'data', []);
const selectMarketFilterPickList = state => _.get(state.opptList, 'marketFilterPickList', []);
const selectTotalOpportunities = state => _.get(state.opptList, 'totalOpportunities', 0);

const selectSingleSelectedOpportunity = state => _.get(state.selectedOpportunity, 'data', []);
const selectSingleOpportunitySelected = state => _.get(state.selectedOpportunity, 'isTabActive', false);
const isOpptListLoading = state => _.get(state.opptList, 'fetching', false);

export {
  selectOpportunityList,
  selectSingleSelectedOpportunity,
  selectSingleOpportunitySelected,
  selectMarketFilterPickList,
  isOpptListLoading,
  selectTotalOpportunities
};
