import { ORDER_STATUS } from 'actions/actionTypes';
import initialState from 'store/initialState';

const orderStatus = (state = initialState.feedbackStatus, action) => {
  switch (action.type) {
    case ORDER_STATUS.SUCCESS:
      let { payload } = action;

      if (state.data && state.data.length > 0 && payload && payload.length > 0) {
        let newData = state.data.filter(
          stateDataItem => !payload.some(payloadItem => stateDataItem.customerId === payloadItem.customerId)
        );
        return { ...state, data: [...newData, ...payload], isUpdating: false, isError: false };
      }
      return { ...state, data: payload, isUpdating: false, isError: false };
    case ORDER_STATUS.PENDING:
      return { ...state, isUpdating: true, isError: false };
    case ORDER_STATUS.FAILURE:
      return { ...state, isUpdating: false, isError: true };
    default:
      return state;
  }
};

export default orderStatus;
