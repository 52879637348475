import { CHART_TYPES } from '../../utils/Constants';

const dashboardChartsState = {
  [CHART_TYPES.SOLD_STATUS_SUMMARY]: {
    data: [],
    fetching: false,
    error: null
  },
  [CHART_TYPES.NOT_SOLD_REASONS_SUMMARY]: {
    data: [],
    fetching: false,
    error: null
  },
  [CHART_TYPES.CLOSED_OPP_BY_ENGAGEMENTS_SUMMARY]: {
    data: [],
    fetching: false,
    error: null
  },
  [CHART_TYPES.CUSTOMER_COMMITMENT_SUMMARY]: {
    data: [],
    fetching: false,
    error: null
  },
  [CHART_TYPES.ORDER_PLACEMENT_SUMMARY]: {
    data: [],
    fetching: false,
    error: null
  }
};

export default dashboardChartsState;
