const { SUBMIT_ORDERS } = require('actions/actionTypes');
const { default: initialState } = require('store/initialState');

const placeOrder = (state = initialState.placedOrders, action) => {
  switch (action.type) {
    case SUBMIT_ORDERS.SUCCESS:
      return { ...state, updating: false };
    case SUBMIT_ORDERS.PENDING:
      return { ...state, updating: true };
    case SUBMIT_ORDERS.FAILURE:
      return { ...state, updating: false };
    default:
      return state;
  }
};

export default placeOrder;
