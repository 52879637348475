import initialState from 'store/initialState';
import { FEEDBACK_STATUS } from 'actions/actionTypes';
import { createReducer } from 'reduxHelpers';

const feedbackStatus = (state = initialState.feedbackStatus, action) => {
  switch (action.type) {
    case FEEDBACK_STATUS.SUCCESS:
      let { payload } = action;

      if (state.data && state.data.length > 0) {
        let newData = state.data.filter(
          stateDataItem => !payload.some(payloadItem => stateDataItem.customerId === payloadItem.customerId)
        );
        return { ...state, data: [...newData, ...payload] };
      }
      return { ...state, data: payload };
    default:
      return state;
  }
};

createReducer(FEEDBACK_STATUS, initialState.feedbackStatus);

export default feedbackStatus;
