import initialState from 'store/initialState';
import { DASHBOARD_CHARTS } from 'actions/actionTypes';

const dashboardCharts = (state = initialState.dashboardChartsState, action) => {
  switch (action.type) {
    case DASHBOARD_CHARTS.REQUEST: {
      const data = {
        [action.chartType]: {
          data: [],
          fetching: true,
          error: null
        }
      };

      return { ...state, ...data };
    }

    case DASHBOARD_CHARTS.SUCCESS: {
      const data = {
        [action.payload.chartType]: {
          data: action.payload.data.data,
          fetching: false,
          error: null
        }
      };

      return { ...state, ...data };
    }

    case DASHBOARD_CHARTS.CLEAR: {
      const data = {
        [action.chartType]: {
          data: [],
          fetching: false,
          error: null
        }
      };

      return { ...state, ...data };
    }

    case DASHBOARD_CHARTS.FAILURE: {
      const data = {
        [action.payload.chartType]: {
          data: [],
          fetching: false,
          error: action.payload.data
        }
      };

      return { ...state, ...data };
    }

    default:
      return state;
  }
};

export default dashboardCharts;
