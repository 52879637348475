import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { opptFilterList } from 'selectors/oppFilterList';
import { selectOpportunityList, isOpptListLoading, selectTotalOpportunities } from 'selectors/brokerOpportunity';
import { selectTranches } from 'selectors/tranches';
import selectFeedbackStatus from 'selectors/feedbackStatusSelector';
import {
  retriveConversionDetails,
  generateFeedbackReport,
  loadList,
  loadOrderStatus,
  loadListFilters,
  clearList
} from 'actions';
import { selectFeedbackReportStatus, selectFeedbackReportUrl } from 'selectors/feedbackReportSelectors';
import { DownloadOutlined, CheckCircleOutlined, InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getParamsFromUrl } from 'utils/Util';
import { useHistory } from 'react-router-dom';
import { selectOrderStatus, selectOrderStatusError } from 'selectors/orderStatusSelector';
import {
  OPP_LIST,
  USER_TYPE_ASSOCIATE_PERMISSION,
  USER_TYPE_ASSOCIATE_ROLE,
  USER_TYPE_BROKER_ROLE
} from 'utils/Constants';
import userDetails from 'selectors/userDetailsSelector';
import OpportunityListView from '../components/OpportunityListView';
import OpportunityDrawer from './OpportunityDrawer';
import Filters from '../components/Filters';
import { allBrokersSelector } from '../selectors/brokerListSelector';

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Selectors
  const loadUserDetails = useSelector(userDetails);
  const opptFilters = useSelector(opptFilterList);
  const opptList = useSelector(selectOpportunityList);
  const isLoadOpptList = useSelector(isOpptListLoading);
  const feedbackStatusList = useSelector(selectFeedbackStatus);
  const tranches = useSelector(selectTranches);
  const feedbackReportGenerationStatus = useSelector(selectFeedbackReportStatus);
  const feedbackReportUrl = useSelector(selectFeedbackReportUrl);
  const orderStatusList = useSelector(selectOrderStatus);
  const orderStatusError = useSelector(selectOrderStatusError);
  const totalOpportunities = useSelector(selectTotalOpportunities);
  const allBrokers = useSelector(allBrokersSelector);

  // State

  const [selectedBroker, setSelectedBroker] = useState('');
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [conversionDrawer, setConversionDrawer] = useState({ visible: false, rowData: null });
  const [opportunities, setOpportunities] = useState([]);
  const [pageSize, setpageSize] = useState(OPP_LIST.DEFAULT_PAGE_SIZE);
  const [lotDescription, setLotDescription] = useState(null);
  const [noOfTableRecords, setNoOfTableRecords] = useState(0);
  const [showReportDownloadModal, setShowReportDownloadModal] = useState(false);
  const [selectedPageNo, setSelectedPageNo] = useState(1);
  const [sortingParams, setSortingParams] = useState({});
  const [reportUrlTimeout, setReportUrlTimeout] = useState();
  const [reportUrlExpired, setReportUrlExpired] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [filtersObject, setFiltersObject] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [selectedBc, setSelectedBc] = useState([]);
  const [selectedAg, setSelectedAg] = useState([]);
  const [selectedIg, setSelectedIg] = useState([]);
  const singleValueFilters = [
    'lotDescription',
    'customerName',
    'city',
    'brokerName',
    'dsmName',
    'scName',
    'id',
    'noOfOpp',
    'customerNum',
    'customerCity'
  ];

  useEffect(() => {
    if (
      loadUserDetails?.roles?.includes(USER_TYPE_ASSOCIATE_ROLE) &&
      loadUserDetails?.permissions?.includes(USER_TYPE_ASSOCIATE_PERMISSION)
    ) {
      setReadOnlyMode(true);
    } else if (loadUserDetails?.roles?.includes(USER_TYPE_BROKER_ROLE)) {
      setReadOnlyMode(false);
    }
  }, [loadUserDetails]);

  useEffect(() => {
    if (opportunities) {
      setOpportunities(opptList);
    }
    setNoOfTableRecords(opptList.length);
    retrieveOrderStatus(opptList, tranches, selectedBroker);
  }, [opptList]);

  /**
   * handles expiry time of the generated URL
   */
  useEffect(() => {
    if (feedbackReportUrl && feedbackReportUrl.length > 0) {
      const urlParams = getParamsFromUrl(feedbackReportUrl);
      if (urlParams && urlParams['X-Amz-Expires'] && urlParams['X-Amz-Expires'].length > 0) {
        const timeoutMillis = Number(urlParams['X-Amz-Expires'][0]) * 1000;

        const timeout = setTimeout(() => {
          setReportUrlExpired(true);
        }, timeoutMillis);

        setReportUrlTimeout(timeout);
      }
    }
  }, [feedbackReportUrl]);

  /**
   * load order statuses on tranche load
   */
  useEffect(() => {
    if (tranches && tranches.length > 0) {
      retrieveOrderStatus(opptList, tranches, selectedBroker);
    }
  }, [tranches, selectedBroker]);

  /**
   * retrieve place order statuses with customer Ids
   */
  const retrieveOrderStatus = (opportunityList, tranchesList, brokerCode) => {
    if (tranchesList && tranchesList.length > 0 && opportunityList && opportunityList.length > 0) {
      const customerIdAndSiteIdMap = {};

      opportunityList.forEach(item => {
        customerIdAndSiteIdMap[String(item.customerNum)] = item.siteId;
      });

      const { tranche } = tranches[0];
      dispatch(loadOrderStatus({ tranche, customerIdAndSiteIdMap, brokerCode }));
    }
  };

  const appendTablesFiltersToFilterParams = filters => {
    const filterParams = getMainFilters();

    Object.keys(filters).forEach(key => {
      if (filters[key] && filters[key].length > 0) {
        const _filters = filters[key] ? filters[key].map(item => item.trim()) : [];

        if (singleValueFilters.includes(key)) {
          if (key === 'customerCity') {
            filterParams.city = _filters.length > 0 ? _filters[0] : null;
          } else {
            filterParams[key] = _filters.length > 0 ? _filters[0] : null;
          }
        } else {
          filterParams[key] = _filters;
        }
      }
    });

    return filterParams;
  };

  /**
   * handle table page changes
   */
  const handleTablePageChange = (pageNumber, updatedPageSize) => {
    setSelectedPageNo(pageNumber);
    setpageSize(updatedPageSize);
  };

  /**
   * handle table sorting changes
   */
  const handleTableSortingChanges = (field, order) => {
    const _state = {};
    _state[field] = order;
    setSortingParams(_state);
  };

  /**
   * handle report download
   */
  const reportGenerationHandler = () => {
    const params = {
      pageSize,
      currentPage: selectedPageNo - 1, // selectedPageNo starts from 1
      filters: {
        market: selectedMarkets,
        region: selectedRegions,
        siteId: selectedSites,
        lotDescription,
        categoryName: selectedCategories,
        supplier: selectedSupplier,
        bc: selectedBc,
        ig: selectedIg,
        ag: selectedAg
      },
      sortParams: getSortingParamsForReportGeneration(),
      brokerCode: selectedBroker
    };
    setShowReportDownloadModal(true);
    dispatch(generateFeedbackReport(params));
  };

  /**
   * handle report download modal close
   */
  const handlerReportDownloadModalClose = () => {
    setShowReportDownloadModal(false);
    clearTimeout(reportUrlTimeout);
    setReportUrlTimeout(null);
    setReportUrlExpired(false);
  };

  /**
   * handles report download modal's button click
   */
  const reportDownloadBtnClickHandler = () => {
    window.open(feedbackReportUrl, '_blank');
  };

  /**
   *  returns an object to use in the report generation
   *  format -> {asc: [], desc: []}
   */
  const getSortingParamsForReportGeneration = () => {
    const params = { asc: [], desc: [] };
    const mapForSearchParams = {
      customerNum: 'customerNum',
      customerName: 'customerName',
      customerCity: 'customerCity',
      brokerName: 'brokerName',
      dsmName: 'dsmName',
      scName: 'scName',
      feedbackStatus: 'feedbackStatus'
    };
    Object.keys(sortingParams).forEach(key => {
      if (key === 'noOfOpp') {
        return;
      }
      if (sortingParams[key] === 'ascend') {
        params.asc.push(key);
      } else if (sortingParams[key] === 'descend') {
        params.desc.push(key);
      }
    });
    const asc = params.asc.map(colkey => mapForSearchParams[colkey]);
    const desc = params.desc.map(colkey => mapForSearchParams[colkey]);

    return { asc, desc };
  };

  /**
   * return report generation modal title
   */
  const getReportGenerationModalTitle = () => {
    if (!reportUrlExpired) {
      switch (feedbackReportGenerationStatus) {
        case 'PENDING':
          return (
            <>
              <InfoCircleOutlined />
              <span className="ant-modal-confirm-title">Your report is being generated.</span>
            </>
          );
        case 'SUCCESS':
          return (
            <>
              <CheckCircleOutlined />
              <span className="ant-modal-confirm-title">Your report is ready to download!</span>
            </>
          );
        case 'FAILURE':
          return (
            <>
              <CloseCircleOutlined />
              <span className="ant-modal-confirm-title">Something went wrong!</span>
            </>
          );
        default:
          return (
            <>
              <InfoCircleOutlined />
              <span className="ant-modal-confirm-title">Report Model Not Using</span>
            </>
          );
      }
    } else {
      return (
        <>
          <CloseCircleOutlined />
          <span className="ant-modal-confirm-title">URL expired!</span>
        </>
      );
    }
  };

  /**
   * return report generation modal text
   */
  const getReportGenerationModalText = () => {
    if (!reportUrlExpired) {
      switch (feedbackReportGenerationStatus) {
        case 'PENDING':
          return <p>This will take few seconds...</p>;
        case 'SUCCESS':
          return (
            <>
              {/* <p className="report-url">{feedbackReportUrl.substr(0, 180) + '...'}</p> */}
              {/* <p>Link will be available for next 24 hours.</p> */}
            </>
          );
        case 'FAILURE':
          return <p>We are having trouble generating your report. Please try again.</p>;
        default:
          return <p>This will take few seconds...</p>;
      }
    } else {
      return <p>Please generate a new report.</p>;
    }
  };

  /**
   * return report generation modal button
   */
  const getReportGenerationModalBtn = () => {
    if (!reportUrlExpired) {
      switch (feedbackReportGenerationStatus) {
        case 'PENDING':
          return <Spin size="large" />;
        case 'SUCCESS':
          return (
            <Button
              className="download-btn"
              type="primary"
              icon={<DownloadOutlined />}
              size="default"
              onClick={reportDownloadBtnClickHandler}
            >
              {feedbackReportGenerationStatus === 'SUCCESS' && 'Download'}
            </Button>
          );
        case 'FAILURE':
          return (
            <Button type="primary" size="large" danger onClick={handlerReportDownloadModalClose}>
              Close
            </Button>
          );
        default:
          return <Spin size="large" />;
      }
    } else {
      return (
        <Button type="primary" size="large" danger onClick={handlerReportDownloadModalClose}>
          Close
        </Button>
      );
    }
  };

  const getMainFilters = () => {
    return {
      market: selectedMarkets.map(item => item.trim()),
      region: selectedRegions.map(item => item.trim()),
      siteId: selectedSites.map(item => item.trim()),
      lotDescription: lotDescription || null,
      categoryName: selectedCategories.map(item => item.trim()),
      supplier: selectedSupplier.map(item => item.trim()),
      bc: selectedBc.map(item => item.trim()),
      ig: selectedIg.map(item => item.trim()),
      ag: selectedAg.map(item => item.trim())
    };
  };

  const handleTableChanges = (pagination, filters, sorter, extra) => {
    setFiltersObject(filters);
    setpageSize(pagination.pageSize);
    setSelectedPageNo(pagination.current);

    let filterParams = null;
    let sortParams = null;
    const { currentDataSource } = extra;
    setNoOfTableRecords(currentDataSource?.size);
    // append table fiters
    if (filters) {
      filterParams = appendTablesFiltersToFilterParams(filters);
    }

    if (sorter && sorter.columnKey && sorter.order) {
      sortParams = getSortingParamsObj(sorter.columnKey, sorter.order);
    }
    dispatch(
      loadList({
        pageSize: pagination.pageSize,
        requestedPage: pagination.current - 1,
        filters: filterParams,
        sortParams,
        brokerCode: selectedBroker,
        allBrokers
      })
    );
  };

  const routeToPath = (path, params) => {
    history.push({
      pathname: path,
      state: {
        ...params
      }
    });
  };

  // Handle drawer open close functions.
  const handleConversionalDrawer = values => {
    if (values) {
      dispatch(
        retriveConversionDetails({
          custNo: values.customerNum,
          brokerCode: selectedBroker,
          bc: selectedBc,
          ig: selectedIg,
          ag: selectedAg
        })
      );
      setConversionDrawer({ visible: !conversionDrawer.visible, rowData: values });
    } else {
      setConversionDrawer({ visible: false, rowData: null });
    }
  };
  const getSortingParamsObj = (field, order) => {
    switch (order) {
      case 'ascend':
        return { asc: [field], desc: [] };
      case 'descend':
        return { asc: [], desc: [field] };
      default:
        return OPP_LIST.DEFAULT_SORT_PARAMS;
    }
  };

  const handleSearchButtonClick = ({ filters }) => {
    setSelectedPageNo(0);
    dispatch(loadListFilters(filters, selectedBroker, allBrokers));
  };

  useEffect(() => {
    if (selectedBroker) {
      dispatch(loadList({ brokerCode: selectedBroker, allBrokers }));
    }
  }, [selectedBroker]);

  useEffect(() => {
    //  if  user is associate, we should clear the list, when destroy the page
    return () => {
      if (readOnlyMode) {
        dispatch(clearList());
      }
    };
  }, [readOnlyMode]);

  return (
    <>
      <Filters
        filtersObject={filtersObject}
        onSelectedBroker={setSelectedBroker}
        onSelectedMarkets={setSelectedMarkets}
        onSelectedRegions={setSelectedRegions}
        onSelectedSites={setSelectedSites}
        onSelectedCategories={setSelectedCategories}
        onLotDescription={setLotDescription}
        onSelectedSupplier={setSelectedSupplier}
        onSelectedBc={setSelectedBc}
        onSelectedAg={setSelectedAg}
        onSelectedIg={setSelectedIg}
        onSearch={handleSearchButtonClick}
      />
      {/* <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => ( */}

      <div className="list-table">
        <OpportunityListView
          dataSource={opportunities}
          loading={isLoadOpptList}
          handleConversionDrawerVisibilityChange={conversionDrawer}
          pageSize={pageSize}
          currentPage={selectedPageNo}
          totalOpportunities={totalOpportunities}
          filters={opptFilters}
          handlePageChange={handleTablePageChange}
          onSorting={handleTableSortingChanges}
          feedbackStatusList={feedbackStatusList}
          orderStatusList={orderStatusList}
          orderStatusError={orderStatusError}
          tableChangeHandler={handleTableChanges}
          handleDrawer={handleConversionalDrawer}
          handleReportGeneration={reportGenerationHandler}
          handleRoute={routeToPath}
          readOnlyMode={readOnlyMode}
          selectedBroker={selectedBroker}
        />

        {/* <Pagination
              size="small"
              className="grid-pagination"
              onChange={handlePagination}
              total={recordCount}
              showTotal={total => `Total ${total} items`}
              current={page}
              defaultPageSize={50}
              hideOnSinglePage
            /> */}
      </div>

      <OpportunityDrawer
        readOnlyMode={readOnlyMode}
        conversionDrawer={conversionDrawer}
        handleDrawer={handleConversionalDrawer}
      />

      <Modal visible={showReportDownloadModal} onCancel={handlerReportDownloadModalClose} footer={null}>
        <div className="report-generation-modal-body">
          <div className="ant-modal-confirm-body">
            <div className="download-modal-header">{getReportGenerationModalTitle()}</div>
            <div className="ant-modal-confirm-content">
              <div>{getReportGenerationModalText()}</div>
              <div>{getReportGenerationModalBtn()}</div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Home;
