import React, { useEffect, useState } from 'react';
import { Drawer, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectQuestions,
  selectAllFeedback,
  selectFeedbackUpdatingStatus,
  selectFeedbackUpdatedby
} from 'selectors/feedbackSelector';
import AppLoader from 'components/AppLoader';
import _ from 'lodash';
import { action } from 'reduxHelpers';
import { getFormCompletionStatus } from 'managers/pages/OpportunityFeedBackDrawerLogic';
import FeedBackForm from './FeedBackForm';
import { updateFeedback } from '../actions';

const OpportunityFeedBackDrawer = props => {
  //Loading questions and answers
  const questions = useSelector(selectQuestions);
  const allFeedbacks = useSelector(selectAllFeedback);
  const feedbackUpdatedBy = useSelector(selectFeedbackUpdatedby);
  const isFeedbackUpdating = useSelector(selectFeedbackUpdatingStatus);
  const dispatch = useDispatch();

  // state variables
  const [validationErrors, setValidationErrors] = useState([]);

  /**
   * scroll to top on drawer load
   */
  useEffect(() => {
    if (props.showFeedbackDrawer) {
      setTimeout(() => {
        const feedbackDrawerWindow = document.querySelector('.opportunities-feedback-drawer');
        if (feedbackDrawerWindow) {
          const drawerBody = feedbackDrawerWindow.querySelector('.ant-drawer-body');

          if (drawerBody) {
            drawerBody.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }
      }, 300);
    }
  }, [props.showFeedbackDrawer]);

  /**
   * perform validations and send update feedback request
   */
  const updateFeedbackRequest = () => {
    if (validationErrors.length === 0) {
      const completionStatus = getFormCompletionStatus(questions, allFeedbacks);
      dispatch(
        updateFeedback({
          payload: {
            conversionId: props.selectedConversionId,
            feedbacks: allFeedbacks,
            feedbackStatus: completionStatus
          },
          customerId: props.customerData.customerNum,
          onSubmissionSuccess: props.onClose
        })
      );
    } else {
      dispatch(
        action('SHOW_NOTIFICATION', {
          description: 'Please check the provided feedbacks again.',
          className: 'error',
          message: 'FEEDBACK VALIDATION ERROR'
        })
      );

      if (validationErrors.length > 0) {
        const question = _.minBy(validationErrors, item => item.questionId);
        scrollToQuestion(question.questionId);
      }
    }
  };

  /**
   * scrolls the drawer window into a given question
   * @param {questionId} questionId
   */
  const scrollToQuestion = questionId => {
    const inputElementDiv = document.querySelector(`#feedback-input-${questionId}`);

    if (inputElementDiv) {
      setTimeout(() => {
        inputElementDiv.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  };

  return (
    <Drawer
      className="opportunities-feedback-drawer"
      title={
        <>
          <div className="titles">
            FEEDBACK FORM
            <div className="subtitle">
              {props?.customerData?.customerName} / {props?.customerData?.customerNum}
            </div>
            {feedbackUpdatedBy && feedbackUpdatedBy.length > 0 && (
              <div className="text updated-by">Updated by: {feedbackUpdatedBy}</div>
            )}
          </div>
        </>
      }
      placement="right"
      visible={props?.showFeedbackDrawer}
      width="45rem"
      footer={
        <>
          <Button disabled={props?.readOnlyMode} type="primary" className="drawer-btn" onClick={updateFeedbackRequest}>
            SEND FEEDBACK
          </Button>
          <Button className="drawer-btn" onClick={() => props.onClose()}>
            CANCEL
          </Button>
        </>
      }
      onClose={() => props.onClose()}
    >
      {props?.showFeedbackDrawer && (
        <FeedBackForm conversionId={props?.selectedConversionId} onChangeErrors={setValidationErrors} />
      )}

      {isFeedbackUpdating && <AppLoader show />}
    </Drawer>
  );
};

export default OpportunityFeedBackDrawer;
