import initialState from 'store/initialState';
import { ENTERPISE_PRCP_PRICES } from 'actions/actionTypes';

const prcpPricing = (state = initialState.prcpPricing, action) => {
  switch (action.type) {
    case ENTERPISE_PRCP_PRICES.PENDING:
      return { ...state, fetching: true };
    case ENTERPISE_PRCP_PRICES.SUCCESS:
      const { products } = action.payload;
      return { ...state, data: products || [], fetching: false };
    case ENTERPISE_PRCP_PRICES.FAILURE:
      return { ...state, fetching: false, data: [] };
    default:
      return state;
  }
};

export default prcpPricing;
