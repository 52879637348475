import React, { useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { uniq } from 'underscore';
import { useLocation } from 'react-router-dom';
import { appendRegionList, appendSiteList } from '../managers/pages/HomeLogic';
import userDetails from '../selectors/userDetailsSelector';
import { brokerList, brokerListLoading, brokerError, allBrokersSelector } from '../selectors/brokerListSelector';
import { opptFilterList, opptFilterListLoading } from '../selectors/oppFilterList';
import { selectTranches, trancheLoading } from '../selectors/tranches';
import { USER_TYPE_ASSOCIATE_PERMISSION, USER_TYPE_ASSOCIATE_ROLE, USER_TYPE_BROKER_ROLE } from '../utils/Constants';
import {
  loadBrokerList,
  loadList,
  loadTranches,
  retriveOpprtunityFiltrs,
  loadBc,
  loadIg,
  loadAg,
  setAllBrokers
} from '../actions';
import {
  selectcorAG,
  selectcorAGLoading,
  selectcorBC,
  selectcorBCLoading,
  selectcorIG,
  selectcorIGLoading,
  selectorAllBrokers
} from '../selectors/bcIgAg';

const Filters = ({
  filtersObject,
  onSelectedBroker = () => {},
  onSelectedMarkets = () => {},
  onSelectedRegions = () => {},
  onSelectedSites = () => {},
  onSelectedCategories = () => {},
  onLotDescription = () => {},
  onSelectedSupplier = () => {},
  onSelectedTranche = () => {},
  onSelectedBc = () => {},
  onSelectedAg = () => {},
  onSelectedIg = () => {},
  onSearch = () => {}
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Selectors
  const loadUserDetails = useSelector(userDetails);
  const brokersList = useSelector(brokerList);
  const brokersListFetching = useSelector(brokerListLoading);
  const opptFilters = useSelector(opptFilterList);
  const bc = useSelector(selectcorBC);
  const bcLoading = useSelector(selectcorBCLoading);
  const ig = useSelector(selectcorIG);
  const igLoading = useSelector(selectcorIGLoading);
  const ag = useSelector(selectcorAG);
  const agLoading = useSelector(selectcorAGLoading);
  const tranches = useSelector(selectTranches);
  const opptFilterListLoadingSelector = useSelector(opptFilterListLoading);
  const isFetchingTranches = useSelector(trancheLoading);
  const isBrokerListError = useSelector(brokerError);
  const allBrokers = useSelector(allBrokersSelector);
  // State
  const [selectedBroker, setSelectedBroker] = useState(undefined);
  const [markets, setMarkets] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [lotDescription, setLotDescription] = useState(null);
  const [readOnlyMode, setReadOnlyMode] = useState(undefined);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [selectedBC, setSelectedBC] = useState([]);
  const [selectedIG, setSelectedIG] = useState([]);
  const [selectedAG, setSelectedAG] = useState([]);
  const [tranchesList, setTranchesList] = useState([]);
  const [selectedTranche, setSelectedTranche] = useState(undefined);
  const [bcLocal, setBcLocal] = useState([]);
  const [igLocal, setIgLocal] = useState([]);
  const [agLocal, setAgLocal] = useState([]);
  const singleValueFilters = [
    'lotDescription',
    'customerName',
    'city',
    'brokerName',
    'dsmName',
    'scName',
    'id',
    'noOfOpp',
    'customerNum',
    'customerCity'
  ];

  const isDashboard = location.pathname === '/suite/brokeroppt/dashboard';

  const filterArrayAlphabetically = (array = [], columnName) => {
    let a = array;
    a = a.filter(column => column[columnName] !== null);
    if (columnName?.length === 0) {
      return a.sort((c, b) => c.localeCompare(b));
    }
    return a.sort((c, b) => c[columnName].localeCompare(b[columnName]));
  };
  const addAllOption = (array = [], columnName) => {
    let a = array;
    if (!isDashboard && a?.length > 0 && columnName === 'brokerName') {
      a = a.filter(item => item?.brokerCode !== 'ALL');
    }
    if (isDashboard && a?.length > 0 && columnName === 'brokerName') {
      if (!a.map(item => item?.brokerCode)?.includes('ALL')) {
        a.unshift({ brokerCode: 'ALL', brokerName: 'All' });
      }
    }

    return a;
  };
  const handleLotDescription = event => {
    setLotDescription(event.target.value);
  };

  const getMainFilters = () => {
    return {
      market: selectedMarkets.map(item => item.trim()),
      region: selectedRegions.map(item => item.trim()),
      siteId: selectedSites.map(item => item.trim()),
      lotDescription: lotDescription || null,
      categoryName: selectedCategories.map(item => item.trim()),
      supplier: selectedSupplier.map(item => item.trim()),
      bc: selectedBC.map(item => item.trim()),
      ig: selectedIG.map(item => item.trim()),
      ag: selectedAG.map(item => item.trim())
    };
  };
  const appendTablesFiltersToFilterParams = filters => {
    const filterParams = getMainFilters();

    Object.keys(filters).forEach(key => {
      if (filters[key] && filters[key].length > 0) {
        const _filters = filters[key] ? filters[key].map(item => item.trim()) : [];

        if (singleValueFilters.includes(key)) {
          if (key === 'customerCity') {
            filterParams.city = _filters.length > 0 ? _filters[0] : null;
          } else {
            filterParams[key] = _filters.length > 0 ? _filters[0] : null;
          }
        } else {
          filterParams[key] = _filters;
        }
      }
    });

    return filterParams;
  };
  const handleSearchButtonClick = () => {
    const filters = appendTablesFiltersToFilterParams(filtersObject);
    onSelectedBc(selectedBC);
    onSelectedAg(selectedAG);
    onSelectedIg(selectedIG);
    onSearch({
      broker: selectedBroker,
      tranche: selectedTranche,
      filters
    });
  };

  const restFilter = () => {
    setSelectedMarkets([]);
    setSelectedRegions([]);
    setSelectedSites([]);
    setSelectedCategories([]);
    setLotDescription(null);
    setSelectedSupplier([]);

    setMarkets([]);
    setSuppliers([]);
    setCategories([]);
  };
  const handleBrokerValueChange = value => {
    if (Array.isArray(value) && value.includes('ALL') && value.length === 1 && value.indexOf('ALL') === 0) {
      dispatch(setAllBrokers({ payload: true }));
      setSelectedBroker(['ALL']);
    } else if (Array.isArray(value) && value.includes('ALL') && value.indexOf('ALL') !== 0) {
      dispatch(setAllBrokers({ payload: true }));
      setSelectedBroker(['ALL']);
    } else if (Array.isArray(value) && value.includes('ALL') && value.indexOf('ALL') === 0 && value.length !== 1) {
      dispatch(setAllBrokers({ payload: false }));
      setSelectedBroker([...value.filter(item => item !== 'ALL')]);
    } else {
      dispatch(setAllBrokers({ payload: false }));
      setSelectedBroker(value);
    }
    restFilter();

    setTranchesList([]);
    setSelectedTranche(undefined);
  };

  const handleSupplierValueChange = value => {
    setSelectedSupplier(value);
  };

  const handleBcChange = value => {
    setSelectedBC(value);
    setSelectedIG([]);
    setSelectedAG([]);
  };

  useEffect(() => {
    if (selectedBC.length === 0) {
      setAgLocal([]);
      setIgLocal([]);
    } else {
      setAgLocal(ag);
      setIgLocal(ig);
    }
  }, [selectedBC, ig, ag]);

  useEffect(() => {
    if (selectedIG.length === 0) {
      setAgLocal([]);
    } else {
      setAgLocal(ag);
    }
  }, [selectedIG, ag]);

  const handleIgChange = value => {
    setSelectedIG(value);
    setSelectedAG([]);
  };
  const handleAgChange = value => {
    setSelectedAG(value);
  };

  const sortArray = (array = [], key = 'bc' || 'ig' || 'ag') => {
    let a = array;
    if (a?.length > 0) {
      // sort((a, b) => a.bcName.localeCompare(b.bcName))
      if (key === 'bc') {
        a = a.filter(({ bcName }) => bcName !== null);
        return a.sort((c, b) => c.bcName.localeCompare(b.bcName));
      }
      if (key === 'ig') {
        a = a.filter(({ igName }) => igName !== null);
        return a.sort((c, b) => c.igName.localeCompare(b.igName));
      }
      if (key === 'ag') {
        a = a.filter(({ agName }) => agName !== null);
        return a.sort((c, b) => c.agName.localeCompare(b.agName));
      }
    }
    return array;
  };
  useEffect(() => {
    if (selectedBroker) {
      dispatch(loadTranches({ brokerCode: selectedBroker, allBrokers }));
      if (!isDashboard) {
        // if it's not dashboard
        dispatch(retriveOpprtunityFiltrs({ brokerCode: selectedBroker, allBrokers }));
      }
    }
  }, [selectedBroker]);

  useEffect(() => {
    if (
      !isDashboard &&
      loadUserDetails?.roles?.includes(USER_TYPE_ASSOCIATE_ROLE) &&
      loadUserDetails?.permissions?.includes(USER_TYPE_ASSOCIATE_PERMISSION) &&
      selectedBroker &&
      tranches[0]?.tranche
    ) {
      dispatch(loadBc({ brokerCode: selectedBroker, tranche: tranches[0]?.tranche, allBrokers }));
    }
    if (
      isDashboard &&
      loadUserDetails?.roles?.includes(USER_TYPE_ASSOCIATE_ROLE) &&
      loadUserDetails?.permissions?.includes(USER_TYPE_ASSOCIATE_PERMISSION) &&
      selectedBroker &&
      selectedTranche
    ) {
      dispatch(loadBc({ brokerCode: selectedBroker, tranche: selectedTranche, allBrokers }));
    }
    // in normal view
    if (!isDashboard && loadUserDetails?.roles?.includes(USER_TYPE_BROKER_ROLE) && tranches[0]?.tranche) {
      dispatch(loadBc({ brokerCode: undefined, tranche: tranches[0]?.tranche, allBrokers }));
    }

    if (isDashboard && loadUserDetails?.roles?.includes(USER_TYPE_BROKER_ROLE) && selectedTranche) {
      dispatch(loadBc({ brokerCode: undefined, tranche: selectedTranche, allBrokers }));
    }
  }, [selectedBroker, loadUserDetails, selectedTranche, tranches]);

  useEffect(() => {
    if (!isDashboard && selectedBC.length > 0 && tranches[0]?.tranche) {
      dispatch(loadIg({ bc: selectedBC, brokerCode: selectedBroker, tranche: tranches[0]?.tranche, allBrokers }));
    }
    if (isDashboard && selectedBC.length > 0 && selectedTranche) {
      dispatch(loadIg({ bc: selectedBC, brokerCode: selectedBroker, tranche: selectedTranche, allBrokers }));
    }
  }, [selectedBC, selectedBroker, selectedTranche, tranches]);
  //
  useEffect(() => {
    if (!isDashboard && selectedBC.length > 0 && selectedIG.length > 0 && tranches[0]?.tranche) {
      dispatch(
        loadAg({
          bc: selectedBC,
          ig: selectedIG,
          brokerCode: selectedBroker,
          tranche: tranches[0]?.tranche,
          allBrokers
        })
      );
    }
    if (isDashboard && selectedBC.length > 0 && selectedIG.length > 0 && selectedTranche) {
      dispatch(
        loadAg({ bc: selectedBC, ig: selectedIG, brokerCode: selectedBroker, tranche: selectedTranche, allBrokers })
      );
    }
  }, [selectedBC, selectedIG, selectedBroker, selectedTranche, tranches]);
  useEffect(() => {
    if (
      loadUserDetails?.roles?.includes(USER_TYPE_ASSOCIATE_ROLE) &&
      loadUserDetails?.permissions?.includes(USER_TYPE_ASSOCIATE_PERMISSION)
    ) {
      setReadOnlyMode(true);
      // fetch brokers
      if (brokersList.length === 0 || isBrokerListError) {
        dispatch(loadBrokerList());
      }
    } else if (loadUserDetails?.roles?.includes(USER_TYPE_BROKER_ROLE)) {
      dispatch(loadList());
      setReadOnlyMode(false);
      setSelectedBroker('');
      dispatch(loadTranches({ brokerCode: selectedBroker, allBrokers }));
    }
  }, [loadUserDetails]);

  // change dependent selected options
  useEffect(() => {
    // change selected region values
    if (selectedMarkets) {
      const validMarkets = markets.filter(market => selectedMarkets.includes(market.marketName));
      if (validMarkets) {
        const validRegions = validMarkets.flatMap(vm => {
          return vm.regions.flatMap(rgn => {
            return rgn.regionName;
          });
        });
        const filteredRegions = selectedRegions.filter(region => validRegions.includes(region));
        setSelectedRegions(filteredRegions);

        const validSites = validMarkets.flatMap(vm => {
          return vm.regions.flatMap(rgn => {
            return rgn.sites.flatMap(site => {
              return site.siteId;
            });
          });
        });
        const filteredSites = selectedSites.filter(site => validSites.includes(site));
        setSelectedSites(filteredSites);
      }
    }
  }, [selectedMarkets]);

  useEffect(() => {
    if (opptFilters.markets) {
      setMarkets(opptFilters.markets);
    }
    if (opptFilters.categories) {
      setCategories(opptFilters.categories);
    }
  }, [opptFilters]);

  useEffect(() => {
    // change selected region values
    if (selectedRegions) {
      const validSites = markets.flatMap(vm => {
        return vm.regions.flatMap(rgn => {
          if (selectedRegions.includes(rgn.regionName)) {
            return rgn.sites.flatMap(site => {
              return site.siteId;
            });
          }
        });
      });
      const filteredSites = selectedSites.filter(site => validSites.includes(site));
      setSelectedSites(filteredSites);
    }
  }, [selectedRegions]);

  useEffect(() => {
    if (tranches.length > 0) {
      let arr = tranches.map(item => {
        const time = moment(item.date, 'YYYY-MM-DD');
        return { ...item, label: `${item.tranche} (${time.format('DD MMM YYYY')})` }; // create label & format date
      });
      arr = uniq(arr, item => item.label); //  remove duplicate values
      arr.sort((a, b) => (a.label > b.label ? -1 : 1)); // descending order
      setTranchesList(arr);

      // when goto dashboard if user is broker, select latest tranche automatically
      if (readOnlyMode != undefined && !readOnlyMode && isDashboard) {
        setSelectedTranche(arr[0]?.tranche);
      }
    }
  }, [tranches]);

  useEffect(() => {
    if (opptFilters?.suppliers) {
      setSuppliers(opptFilters.suppliers);
    }
  }, [opptFilters]);

  useEffect(() => {
    onSelectedBroker(selectedBroker);
  }, [selectedBroker]);

  useEffect(() => {
    onSelectedMarkets(selectedMarkets);
  }, [selectedMarkets]);

  useEffect(() => {
    onSelectedRegions(selectedRegions);
  }, [selectedRegions]);

  useEffect(() => {
    onSelectedSites(selectedSites);
  }, [selectedSites]);

  useEffect(() => {
    onSelectedCategories(selectedCategories);
  }, [selectedCategories]);

  useEffect(() => {
    onLotDescription(lotDescription);
  }, [lotDescription]);

  useEffect(() => {
    onSelectedSupplier(selectedSupplier);
  }, [selectedSupplier]);

  useEffect(() => {
    if (!selectedBroker && !loadUserDetails?.roles?.includes(USER_TYPE_BROKER_ROLE)) {
      restFilter();
      setBcLocal([]);
      setAgLocal([]);
      setIgLocal([]);
    } else if (isDashboard && !selectedTranche) {
      restFilter();
      setBcLocal([]);
      setAgLocal([]);
      setIgLocal([]);
    } else {
      setBcLocal(bc);
    }
  }, [bc, isDashboard, selectedBroker, ig, ag, selectedTranche]);

  useEffect(() => {
    onSelectedTranche(selectedTranche);
    if (isDashboard && selectedTranche) {
      restFilter();
      dispatch(retriveOpprtunityFiltrs({ brokerCode: selectedBroker, tranche: selectedTranche, allBrokers }));
    }
  }, [selectedTranche]);

  return (
    <>
      <div className="filterbar">
        {readOnlyMode && (
          <div className="filtergroup broker ">
            <div className="filtergroup-title">
              <div className="icon"> </div>
              BROKER
            </div>
            <div className="filtergroup-row">
              <div className="filteritem">
                <Select
                  loading={brokersListFetching}
                  mode={isDashboard ? 'multiple' : 'single'}
                  className="filter-select"
                  placeholder="Broker Name"
                  onChange={value => {
                    setSelectedBC([]);
                    setSelectedAG([]);
                    setSelectedIG([]);
                    handleBrokerValueChange(value);
                  }}
                  value={selectedBroker}
                  dropdownMatchSelectWidth={false}
                >
                  {addAllOption(filterArrayAlphabetically(brokersList, 'brokerName'), 'brokerName')?.map(broker => (
                    <Select.Option key={broker?.brokerCode} value={broker?.brokerCode}>
                      {broker?.brokerName}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        )}
        {isDashboard && (
          <div className="filtergroup tranche ">
            <div className="filtergroup-title">
              <div className="icon"> </div>
              TRANCHE
            </div>
            <div className="filtergroup-row">
              <div className="filteritem">
                <Select
                  data-testid="select-tranche"
                  className="tranche-selector"
                  placeholder="Tranche"
                  loading={isFetchingTranches}
                  value={selectedTranche}
                  disabled={readOnlyMode ? !selectedBroker || tranchesList.length === 0 : false}
                  onChange={value => {
                    setSelectedBC([]);
                    setSelectedAG([]);
                    setSelectedIG([]);
                    setSelectedTranche(value);
                  }}
                  dropdownMatchSelectWidth={false}
                >
                  {tranchesList.map((state, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Select.Option key={index} value={state.tranche}>
                      {state.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        )}

        <div className="filtergroup market">
          <div className="filtergroup-title">
            <div className="icon"> </div>
            LOCATION
          </div>
          <div className="filtergroup-row">
            <div className="filteritem">
              <Select
                mode="multiple"
                data-testid="select-market"
                className="filter-select market"
                placeholder="Market"
                onChange={setSelectedMarkets}
                value={selectedMarkets}
                loading={opptFilterListLoadingSelector}
                dropdownMatchSelectWidth={false}
              >
                {filterArrayAlphabetically(markets, 'marketName')?.map(market => (
                  <Select.Option key={market.marketName} data-testid="select-market-option" value={market.marketName}>
                    {market.marketName}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="filteritem">
              <Select
                mode="multiple"
                data-testid="select-region"
                className="filter-select region"
                placeholder="Region"
                onChange={setSelectedRegions}
                value={selectedRegions}
                loading={opptFilterListLoadingSelector}
                dropdownMatchSelectWidth={false}
              >
                {filterArrayAlphabetically(appendRegionList(markets, selectedMarkets), 'regionName')?.map(region => {
                  return (
                    <Select.Option key={region.regionName} data-testid="select-region-option" value={region.regionName}>
                      {region.regionName}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>

            <div className="filteritem">
              <Select
                mode="multiple"
                data-testid="select-site"
                className="filter-select site"
                placeholder="Site"
                onChange={setSelectedSites}
                value={selectedSites}
                loading={opptFilterListLoadingSelector}
                dropdownMatchSelectWidth={false}
              >
                {filterArrayAlphabetically(appendSiteList(markets, selectedMarkets, selectedRegions), 'siteName')?.map(
                  (site, index) => {
                    return (
                      <Select.Option key={site.siteId} value={site.siteId} title={site.siteName}>
                        {`${site.siteName} ${site.siteId}`}
                      </Select.Option>
                    );
                  }
                )}
              </Select>
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'none'
          }}
          className="filtergroup item "
        >
          <div className="filtergroup-title">
            <div className="icon"> </div>
            ITEM
          </div>
          <div className="filtergroup-row">
            <div className="filteritem">
              <Select
                mode="multiple"
                className="filter-select"
                placeholder="Category Name"
                onChange={setSelectedCategories}
                value={selectedCategories}
                loading={opptFilterListLoadingSelector}
                dropdownMatchSelectWidth={false}
              >
                {categories.map(category => (
                  <Select.Option key={category} value={category}>
                    {category}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="filtergroup businesscat">
          <div className="filtergroup-title">
            <div className="icon"> </div>
            BUSINESS CATEGORY
          </div>
          <div className="filtergroup-row">
            <div className="filteritem">
              <Select
                loading={bcLoading}
                mode="multiple"
                className="filter-select"
                placeholder="Busness Center"
                value={selectedBC}
                onChange={handleBcChange}
                dropdownMatchSelectWidth={false}
              >
                {sortArray(bcLocal, 'bc')?.map(({ bcName }) => (
                  <Select.Option key={bcName} value={bcName}>
                    {bcName}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="filteritem">
              <Select
                loading={igLoading}
                mode="multiple"
                className="filter-select"
                placeholder="Item Group"
                value={selectedIG}
                onChange={handleIgChange}
                dropdownMatchSelectWidth={false}
              >
                {sortArray(igLocal, 'ig')?.map(({ igName }) => (
                  <Select.Option key={igName} value={igName}>
                    {igName}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="filteritem">
              <Select
                loading={agLoading}
                mode="multiple"
                className="filter-select"
                placeholder="Attribute Group"
                value={selectedAG}
                onChange={handleAgChange}
                dropdownMatchSelectWidth={false}
              >
                {sortArray(agLocal, 'ag')?.map(({ agName }) => (
                  <Select.Option key={agName} value={agName}>
                    {agName}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="filtergroup supplier ">
          <div className="filtergroup-title">
            <div className="icon"> </div>
            SUPPLIER
          </div>
          <div className="filtergroup-row">
            <div className="filteritem">
              <Select
                loading={opptFilterListLoadingSelector}
                mode="multiple"
                className="filter-select"
                placeholder="Supplier Name"
                value={selectedSupplier}
                onChange={handleSupplierValueChange}
                dropdownMatchSelectWidth={false}
              >
                {filterArrayAlphabetically(suppliers, '')?.map(supplier => (
                  <Select.Option key={supplier} data-testid="select-supplier-option" value={supplier}>
                    {supplier}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className="filtergroup ">
          <div className="filtergroup-title"> </div>
          <div className="filtergroup-row">
            <div className="filteritem">
              <Button type="primary" className="searchbtn" onClick={handleSearchButtonClick}>
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Filters;
