import initialState from 'store/initialState';
import {
  UPDATE_LOCAL_FEEDBACK,
  UPDATE_FEEDBACK,
  QUESTIONS,
  FEEDBACK,
  OPPORTUNITY_SELECTED_CLEAR
} from 'actions/actionTypes';
import { createReducer } from 'reduxHelpers';
import { get } from 'lodash';
const getIndexOfQuestionId = (feedbackArray, questionId) => {
  return feedbackArray.findIndex(eachFeedback => eachFeedback.questionId === questionId);
};

const updateOrAddToFeedbackList = (allFeedbacks = [], { questionId, answer }) => {
  const feedbacks = [...allFeedbacks];
  const feedbackFoundIndex = getIndexOfQuestionId(allFeedbacks, questionId);
  if (feedbackFoundIndex > -1) {
    feedbacks[feedbackFoundIndex] = { questionId, answer };
  } else {
    feedbacks.push({ questionId, answer });
  }
  return feedbacks;
};

const filterQuestionsForFeedbacks = data => {
  let allQuestions = [];
  data.forEach(({ questions }) => {
    questions.forEach(({ questionId }) => {
      allQuestions.push({
        questionId,
        answer: ''
      });
    });
  });
  return allQuestions;
};

const clearAnswersForAllQuestions = (answers, modifications = null) => {
  return answers.map(({ questionId }) => {
    // override answers id modification available
    if (modifications) {
      const modifiedQuestion = modifications.find(item => item.questionId === questionId);
      const answer = get(modifiedQuestion, 'answer', '');
      return { questionId, answer };
    }
    return { questionId, answer: '' };
  });
};

const applyExistingFeedbackValues = (allQuestions = [], existingFeedbacks, modifications = null) => {
  let updatedQuestionsWithExistingAnswers = [...allQuestions];

  updatedQuestionsWithExistingAnswers.forEach(({ questionId }, index) => {
    const existingIndex = getIndexOfQuestionId(existingFeedbacks, questionId);
    if (existingIndex > -1) {
      updatedQuestionsWithExistingAnswers[index].answer = existingFeedbacks[existingIndex].answer;
    } else {
      updatedQuestionsWithExistingAnswers[index].answer = '';
    }

    // override answers id modification available
    if (modifications) {
      const modifiedQuestion = modifications.find(item => item.questionId === questionId);
      const answer = get(modifiedQuestion, 'answer', '');
      if (answer) {
        updatedQuestionsWithExistingAnswers[index].answer = answer;
      }
    }
  });
  return updatedQuestionsWithExistingAnswers;
};

const feedback = (state = initialState.feedback, action) => {
  const modifications = get(action, 'payload.modifications', null);
  switch (action.type) {
    case QUESTIONS.SUCCESS:
      let { payload } = action;
      return { ...state, data: filterQuestionsForFeedbacks(payload) };
    case UPDATE_LOCAL_FEEDBACK.REQUEST:
      const { feedback } = action;
      return { ...state, data: updateOrAddToFeedbackList(state.data, feedback) };
    case FEEDBACK.REQUEST:
      return { ...state, isUpdating: true, data: clearAnswersForAllQuestions(state.data, modifications) };
    case FEEDBACK.FAILURE:
      return {
        ...state,
        data: clearAnswersForAllQuestions(state.data, modifications),
        updatedBy: '',
        isUpdating: false,
        existingFeedback: []
      };
    case FEEDBACK.SUCCESS:
      const { payload: { feedbacks, updatedBy } = { feedbacks: [], updatedBy: '' } } = action;
      return {
        ...state,
        data: applyExistingFeedbackValues(state.data, feedbacks, modifications),
        updatedBy,
        isUpdating: false,
        existingFeedback: feedbacks
      };
    case UPDATE_FEEDBACK.REQUEST:
      return { ...state, isUpdating: true };
    case UPDATE_FEEDBACK.SUCCESS:
      return { ...state, isUpdating: false };
    case UPDATE_FEEDBACK.FAILURE:
      return { ...state, isUpdating: false };
    case OPPORTUNITY_SELECTED_CLEAR.REQUEST:
      return { ...state, existingFeedback: [], data: clearAnswersForAllQuestions(state.data, modifications) };
    default:
      return state;
  }
};

createReducer(UPDATE_LOCAL_FEEDBACK, initialState.feedback);

export default feedback;
