import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Space, Table, DatePicker, Pagination, Tooltip, Modal, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import _, { get } from 'lodash';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import moment from 'moment-timezone';
import {
  clearOrderSummeryTableReport,
  loadDashboardOrderSummeryTable,
  startOrderSummeryTableReportDownload
} from '../../actions';
import { orderSummery, orderTableSummeryReportDownloadState } from '../../selectors/dashboardSelector';
import { CURRENCY_SYMBOL } from '../../utils/Constants';
import { getParamsFromUrl, simpleNumberFormatter } from '../../utils/Util';
import { allBrokersSelector } from '../../selectors/brokerListSelector';

function DashboardTable({ data }) {
  const dispatch = useDispatch();
  const pgSize = 10; //  default page size
  const defaultSortParams = { asc: [], desc: ['createdDate'] };
  // Selectors
  const orderSummeryData = useSelector(orderSummery);
  const orderTableSummeryReportDownload = useSelector(orderTableSummeryReportDownloadState);
  const allBrokers = useSelector(allBrokersSelector);
  // State
  const [paginationData, setPaginationData] = useState({
    current: 1,
    total: 0,
    pageSize: pgSize
  });
  const [tableData, setTableData] = useState([]);
  const [estimateTotal, setEstimateTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterParams, setFilterParams] = useState({});
  const [sortParams, setSortParams] = useState(defaultSortParams);
  const [numberOfOrders, setNumberOfOrders] = useState(0);
  const searchInput = useRef(null);
  const [showReportDownloadModal, setShowReportDownloadModal] = useState(false);
  const [reportUrlExpired, setReportUrlExpired] = useState(false);
  const [reportUrlTimeout, setReportUrlTimeout] = useState();
  const fetchData = (page = 1, pageSize = pgSize, sort = defaultSortParams, filter = {}) => {
    const { broker, tranche, filters } = data;
    if (!tranche) {
      return;
    }
    // create payload
    const body = {
      broker,
      tranche,
      currentPage: page - 1,
      pageSize,
      sortParams: JSON.stringify(sort),
      body: {
        market: get(filters, 'market', []),
        region: get(filters, 'region', []),
        siteId: get(filters, 'siteId', []),
        conversionCatName: get(filters, 'categoryName', []),
        supplier: get(filters, 'supplier', []),
        bc: get(filters, 'bc', []),
        ig: get(filters, 'ig', []),
        ag: get(filters, 'ag', []),
        ...filter
      },
      allBrokers
    };
    dispatch(loadDashboardOrderSummeryTable(body));
  };

  const paginationChange = (page, size) => {
    setPaginationData(prev => ({ ...prev, current: page, pageSize: size }));
    fetchData(page, size, sortParams, filterParams);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getSortOrderByDataIndex = dataIndex => {
    if (sortParams.asc.includes(dataIndex)) {
      return 'ascend';
    }
    if (sortParams.desc.includes(dataIndex)) {
      return 'descend';
    }
    return null;
  };

  const handlerReportDownloadModalClose = () => {
    setShowReportDownloadModal(false);
    clearTimeout(reportUrlTimeout);
    setReportUrlTimeout(null);
    setReportUrlExpired(false);
  };

  const getColumnSearchProps = (dataIndex, columnName = '') => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      let inputFiled = (
        <Input
          ref={searchInput}
          placeholder={`Search ${columnName}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
      );
      // number inputs
      // decimal number inputs
      if (['total'].includes(dataIndex)) {
        inputFiled = (
          <Input
            ref={searchInput}
            placeholder={`Search ${columnName}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value.replace(/[^0-9.]/gm, '')] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block'
            }}
          />
        );
      }
      // integer number inputs
      if (['itemId', 'siteId', 'cases'].includes(dataIndex)) {
        inputFiled = (
          <Input
            ref={searchInput}
            placeholder={`Search ${columnName}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value.replace(/[^0-9]/gm, '')] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block'
            }}
          />
        );
      }
      // date input
      if (dataIndex === 'createdDate') {
        inputFiled = (
          <DatePicker
            ref={searchInput}
            placeholder={`Search ${columnName}`}
            value={selectedKeys[0] ? moment(selectedKeys[0]) : ''}
            onChange={(date, dateString) => setSelectedKeys(dateString ? [dateString] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block'
            }}
          />
        );
      }

      return (
        <div
          style={{
            padding: 8
          }}
        >
          {inputFiled}

          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              size="small"
              style={{
                width: 90
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      );
    },

    // onFilter: (value, record) => {
    //   // column search function
    //   if (record[dataIndex]) {
    //     try {
    //       return record[dataIndex]
    //         .toString()
    //         .toLowerCase()
    //         .includes(value.toLowerCase());
    //     } catch (e) {
    //       return false;
    //     }
    //   }
    //   return false;
    // },
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text || ' - '
      ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    filteredValue: filterParams[dataIndex] ? [filterParams[dataIndex]] : null,
    sortOrder: getSortOrderByDataIndex(dataIndex),
    sorter: true
  });

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      let filter = { asc: [], desc: [] };
      if (sorter.columnKey !== 'createdDate') {
        if (sorter.order) {
          filter[sorter.order === 'ascend' ? 'asc' : 'desc'].push(sorter.columnKey);
        } else {
          filter = defaultSortParams;
        }
      } else if (sorter.order) {
        filter[sorter.order === 'ascend' ? 'asc' : 'desc'].push(sorter.columnKey);
      }

      setSortParams(filter);
      fetchData(paginationData.current, pagination.pageSize, filter, filterParams);
    }
    if (extra.action === 'filter') {
      const filter = Object.fromEntries(
        Object.entries(filters)
          .filter(([k, v]) => v)
          .map(([k, v]) => {
            // pass these values as string array
            if (['siteId'].includes(k)) {
              const value = v.toString();
              return [k, value ? [value] : []];
            }
            if (['bc'].includes(k)) {
              const value = v.toString();
              return [k, value ? [value] : []];
            }
            return [k, v.toString()];
          })
      );
      setPaginationData(prev => ({ ...prev, current: 1, pageSize: pgSize }));

      setFilterParams(filter);
      fetchData(1, pgSize, defaultSortParams, filter);
    }
  };

  const restTableData = () => {
    setEstimateTotal(0);
    setTableData([]);
    setPaginationData(prev => ({
      ...prev,
      total: 0
    }));
    setSearchText('');
    setSortParams(defaultSortParams);
    setFilterParams({});
  };

  const handleOrderSummeryReportDownload = () => {
    dispatch(clearOrderSummeryTableReport());
    const { broker, tranche, filters } = data;
    setShowReportDownloadModal(true);
    dispatch(
      startOrderSummeryTableReportDownload({
        broker,
        tranche,
        sortParams,
        filterParams: {
          market: get(filters, 'market', []),
          region: get(filters, 'region', []),
          siteId: get(filters, 'siteId', []),
          conversionCatName: get(filters, 'categoryName', []),
          supplier: get(filters, 'supplier', []),
          bc: get(filters, 'bc', []),
          ig: get(filters, 'ig', []),
          ag: get(filters, 'ag', []),
          ...filterParams
        },
        allBrokers
      })
    );
  };

  useEffect(() => {
    restTableData();
    const { tranche } = data;
    if (tranche) {
      setPaginationData(prev => ({ ...prev, current: 1, total: 0 })); // rest data
      fetchData();
    }
  }, [data]);

  useEffect(() => {
    const { tranche } = data;
    if (!tranche) {
      restTableData();
      return;
    }
    let arr = _.get(orderSummeryData, 'data.data', []);
    const totalRows = _.get(orderSummeryData, 'data.total', arr.length);

    arr = arr.map((row, index) => {
      return {
        ...row,
        total: `${CURRENCY_SYMBOL}${row?.total ? row?.total.toFixed(2) : 0}`,
        key: index
      }; //  set key, currency symbol manually
    });

    setPaginationData(prev => ({
      ...prev,
      total: totalRows
    }));
    setNumberOfOrders(_.get(orderSummeryData, 'data.totalOrders', 0));
    setEstimateTotal((_.get(orderSummeryData, 'data.estimatedTotal', 0) || 0).toFixed(2));

    setTableData(arr);
  }, [orderSummeryData]);

  useEffect(() => {
    const url = orderTableSummeryReportDownload?.data?.url;
    if (url?.length > 0) {
      const urlParams = getParamsFromUrl(url);
      if (urlParams && urlParams['X-Amz-Expires'] && urlParams['X-Amz-Expires'].length > 0) {
        const timeoutMillis = Number(urlParams['X-Amz-Expires'][0]) * 1000;
        const timeout = setTimeout(() => {
          setReportUrlExpired(true);
        }, timeoutMillis);

        setReportUrlTimeout(timeout);
      }
    }
  }, [orderTableSummeryReportDownload?.data?.url]);

  // set table columns
  const columns = [
    {
      title: 'Targeted',
      dataIndex: 'isTargeted',
      key: 'isTargeted',
      sorter: false,
      render: value => {
        return (
          <Tooltip title={value ? 'Target Item' : 'Non Target Item'}>
            <div className="status-circle-wrapper">
              <div className="status-circle"> </div>
            </div>
          </Tooltip>
        );
      }
    },
    {
      title: 'PO Number',
      dataIndex: 'poNo',
      key: 'poNo',
      ...getColumnSearchProps('poNo', 'PO Number')
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
      width: '8rem',
      ...getColumnSearchProps('customerName', 'Customer Name')
    },
    {
      title: 'Items ID',
      dataIndex: 'itemId',
      key: 'itemId',
      ...getColumnSearchProps('itemId', 'Items ID')
    },
    {
      title: 'Items Description',
      dataIndex: 'itemDesc',
      key: 'itemDesc',
      ...getColumnSearchProps('itemDesc', 'Items Description')
    },
    {
      title: 'Site #',
      dataIndex: 'siteId',
      key: 'siteId',
      ...getColumnSearchProps('siteId', 'Site')
    },
    {
      title: 'Site Name',
      dataIndex: 'siteName',
      key: 'siteName',
      ...getColumnSearchProps('siteName', 'Site Name')
    },
    {
      title: 'Sold Cases',
      dataIndex: 'cases',
      key: 'cases',
      width: '9rem',
      ...getColumnSearchProps('cases', 'Sold Cases')
    },
    {
      title: 'Business Center',
      dataIndex: 'bc',
      key: 'bcSearch',
      width: '9rem',
      ...getColumnSearchProps('bcSearch', 'Business Center')
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      key: 'categoryName',
      hidden: true,
      ...getColumnSearchProps('categoryName', 'Category')
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      ...getColumnSearchProps('createdBy', 'Created By')
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      defaultSortOrder: 'descend',
      width: '10rem',
      ...getColumnSearchProps('createdDate', 'Created Date')
    },
    {
      title: 'Total Sales',
      dataIndex: 'total',
      key: 'total',
      align: 'left',
      ...getColumnSearchProps('total', 'Total Sales')
    }
  ].filter(item => !item.hidden);
  const reportDownloadBtnClickHandler = () => {
    window.open(orderTableSummeryReportDownload?.data?.url, '_blank');
  };
  const timeOut = (
    <>
      <CloseCircleOutlined />
      <span className="ant-modal-confirm-title">URL expired!</span>
    </>
  );
  return (
    <>
      <div className="list-table order-summery-table">
        <div className="section-title">
          ORDER SUMMARY
          <div className="spacer dotted-divider"> </div>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          loading={orderSummeryData.fetching}
          onChange={handleTableChange}
          pagination={{
            pageSize: paginationData.pageSize
          }}
          rowClassName={record => `updateby-filled ${record.isTargeted ? 'full' : 'pending'}`}
        />
        <div className="order-summary-footer">
          <Pagination
            size="small"
            className="grid-pagination"
            defaultCurrent={1}
            onChange={paginationChange}
            {...paginationData}
          />
          <div className="spacer"></div>
          {orderSummeryData?.data?.data?.length > 0 && (
            <Button
              loading={orderTableSummeryReportDownload?.fetching}
              onClick={handleOrderSummeryReportDownload}
              type="primary"
              className="btn green-action-btn download-report-btn"
            >
              Generate & Download Report
            </Button>
          )}
          <div className="totals">
            <div className="data-block order-count">
              <div className="label">Number of Orders</div>
              <div className="value">{numberOfOrders}</div>
            </div>
            <div className="dotted-divider"> </div>
            <div className="data-block total">
              <div className="label">Estimated Total</div>
              <div className="value">
                {CURRENCY_SYMBOL}
                {simpleNumberFormatter(estimateTotal, 2)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal visible={showReportDownloadModal} onCancel={handlerReportDownloadModalClose} footer={null}>
        <div className="report-generation-modal-body">
          <div className="ant-modal-confirm-body">
            <div className="download-modal-header">
              {reportUrlExpired && timeOut}
              {!reportUrlExpired && orderTableSummeryReportDownload?.fetching && (
                <>
                  <InfoCircleOutlined />
                  <span className="ant-modal-confirm-title">Your report is being generated.</span>
                </>
              )}

              {!reportUrlExpired && orderTableSummeryReportDownload?.data?.url && (
                <>
                  <CheckCircleOutlined />
                  <span className="ant-modal-confirm-title">Your report is ready to download!</span>
                </>
              )}
              {!reportUrlExpired && orderTableSummeryReportDownload?.error && (
                <>
                  <CloseCircleOutlined />
                  <span className="ant-modal-confirm-title">Something went wrong!</span>
                </>
              )}
            </div>
            <div className="ant-modal-confirm-content">
              {orderTableSummeryReportDownload?.fetching && <Spin size="large" />}
              {reportUrlExpired && <p>Please generate a new report.</p>}
              <div>
                {reportUrlExpired && (
                  <Button type="primary" size="large" danger onClick={handlerReportDownloadModalClose}>
                    Close
                  </Button>
                )}
                {!reportUrlExpired && orderTableSummeryReportDownload?.data?.url && (
                  <Button
                    className="download-btn"
                    type="primary"
                    icon={<DownloadOutlined />}
                    size="default"
                    onClick={reportDownloadBtnClickHandler}
                  >
                    {orderTableSummeryReportDownload?.data?.url && 'Download'}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default DashboardTable;
