import { put } from 'redux-saga/effects';
import { postRequest } from '_http';
import { DASHBOARD_SCORE_CARD_SALES, DASHBOARD_SCORE_CARD_CONVERSION } from 'actions/actionTypes';

import { action } from 'reduxHelpers';

function* loadScoreCardConversionAsync(payload) {
  try {
    const { broker, tranche, filters, allBrokers } = payload;
    const response = yield postRequest(
      `/oppt/opp-dashboard/scorecard/conversions?broker-code=${broker}&tranche=${tranche}&all-brokers=${allBrokers}`,
      filters
    );
    yield put({ type: DASHBOARD_SCORE_CARD_CONVERSION.SUCCESS, payload: response.data.data });
  } catch (error) {
    yield put({
      type: DASHBOARD_SCORE_CARD_CONVERSION.FAILURE,
      payload: error
    });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'SCORE CARD LOADING ERROR'
      })
    );
  }
}

function* loadScoreCardSalesAsync(payload) {
  try {
    const { broker, tranche, filters, allBrokers } = payload;
    const response = yield postRequest(
      `/oppt/opp-dashboard/scorecard/sales?broker-code=${broker}&tranche=${tranche}&all-brokers=${allBrokers}`,
      filters
    );
    yield put({ type: DASHBOARD_SCORE_CARD_SALES.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({
      type: DASHBOARD_SCORE_CARD_SALES.FAILURE,
      payload: error
    });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'SCORE CARD LOADING ERROR'
      })
    );
  }
}

export { loadScoreCardConversionAsync, loadScoreCardSalesAsync };
