import _ from 'lodash';
import initialState from 'store/initialState';
import { LIST, REFRESHSTATUS } from 'actions/actionTypes';

const updateOpptUpdatedBy = (state, action, pending) => {
  let newState = _.cloneDeep(state.data);
  let { opptId, updatedBy } = action.payload;
  let foundOppt = _.find(newState, oppt => `${oppt.conversionId}` === `${opptId}`);
  if (foundOppt) {
    foundOppt.updatedBy = updatedBy;
    foundOppt.updatePending = pending;
  }
  return newState;
};

const opptList = (state = initialState.opptList, action) => {
  switch (action.type) {
    case LIST.REQUEST:
      return { ...state, fetching: true };
    case LIST.SUCCESS:
      return {
        ...state,
        data: formatOpptList(action.payload.data),
        fetching: false,
        totalOpportunities: action.payload.totalOpportunities
      };
    case REFRESHSTATUS.PENDING:
      return { ...state, data: updateOpptUpdatedBy(state, action, true) };
    case REFRESHSTATUS.SUCCESS:
      return { ...state, data: updateOpptUpdatedBy(state, action, false) };
    case LIST.FAILURE:
      return { ...state, fetching: false, data: [] };
    case LIST.CLEAR:
      return { ...state, fetching: false, data: [], totalOpportunities: 0 };
    default:
      return { ...state };
  }
};

const formatOpptList = data => {
  return data.map(oppt => {
    return {
      key: oppt.customerNum,
      id: oppt.customerNum,
      customerName: oppt.customerName,
      customerCity: oppt.customerCity,
      brokerName: oppt.brokerName,
      scName: oppt.scName,
      noOfOpp: oppt.noOfOpp,
      market: oppt.market,
      region: oppt.region,
      siteId: oppt.siteId,
      siteName: oppt.siteName,
      catName: oppt.catName,
      lotDesc: oppt.lotDesc,
      customerNum: oppt.customerNum,
      customerAddress: [
        oppt.customerAddressLine1,
        oppt?.customerAddressLine2,
        oppt?.customerCity,
        oppt?.state,
        oppt?.zip
      ].join(' '),
      customerPhoneNum: oppt.customerPhoneNum,
      dsmName: oppt.dsmName,
      lotNum: oppt.lotNum,
      bc: oppt.bc,
      ig: oppt.ig,
      ag: oppt.ag,
      feedbackStatus: false
    };
  });
};

export default opptList;
