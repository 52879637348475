import _ from 'lodash';

const selectcorBC = state => _.get(state.bc, 'data.data', []);
const selectcorBCLoading = state => _.get(state.bc, 'fetching', false);
const selectcorAG = state => _.get(state.ag, 'data.data', []);
const selectcorAGLoading = state => _.get(state.ag, 'fetching', false);
const selectcorIG = state => _.get(state.ig, 'data.data', []);
const selectcorIGLoading = state => _.get(state.ig, 'fetching', false);

export { selectcorBC, selectcorBCLoading, selectcorIGLoading, selectcorAGLoading, selectcorIG, selectcorAG };
