import _ from 'lodash';
const brokerTiesSelector = state => {
  return _.get(state.brokerTies, 'data', []);
};

const brokerTiesLoadingSelector = state => {
  return _.get(state.brokerTies, 'fetching', false);
};

const productInfoSelector = state => {
  return _.get(state.productInfo, 'data', {});
};

const productInfoLoadingSelector = state => {
  return _.get(state.productInfo, 'fetching', false);
};

const asohSelector = state => {
  return _.get(state.asoh, 'data', []);
};

const asohLoadingSelector = state => {
  return _.get(state.asoh, 'fetching', false);
};

const prcpPricingSelector = state => {
  return _.get(state.prcpPricing, 'data', []);
};

const prcpPricingLoadingSelector = state => {
  return _.get(state.prcpPricing, 'fetching', false);
};

export {
  brokerTiesSelector,
  prcpPricingSelector,
  prcpPricingLoadingSelector,
  productInfoSelector,
  asohSelector,
  asohLoadingSelector,
  productInfoLoadingSelector,
  brokerTiesLoadingSelector
};
