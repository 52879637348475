import { postRequest } from '_http';
import { FEEDBACK_STATUS } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';

function* fetchFeedbackStatusAsync(payload) {
  const { customerIdList, brokerCode } = payload;
  try {
    const statusListResponse = yield postRequest(`/oppt/feedback-status`, {
      customerIdList: customerIdList,
      brokerCode
    });
    yield put({ type: FEEDBACK_STATUS.SUCCESS, payload: statusListResponse.data.feedbackStatus });
  } catch (error) {
    yield put({ type: FEEDBACK_STATUS.FAILURE, payload: error.message });
  }
}

export { fetchFeedbackStatusAsync };
