import { BROKER_TIES, ENTERPISE_PRCP_PRICES, PRODUCT_INFO, ASOH } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { postRequest, getRequest } from '_http';
import { action } from 'reduxHelpers';

function* loadBrokerTiesAsync(payload) {
  try {
    yield put({ type: BROKER_TIES.PENDING, payload: {} });

    const { opcoId, itemId } = payload;
    const response = yield getRequest(`/oppt/brokerTies?opcoId=${opcoId}&itemId=${itemId}`);
    yield put({ type: BROKER_TIES.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BROKER_TIES.FAILURE, payload: error.message });
  }
}

function* loadPrcpPricing(payload) {
  try {
    yield put({ type: ENTERPISE_PRCP_PRICES.PENDING, payload: {} });

    const { requestBody } = payload;
    const response = yield postRequest('/order-prices', requestBody);
    yield put({ type: ENTERPISE_PRCP_PRICES.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ENTERPISE_PRCP_PRICES.FAILURE, payload: error.message });

    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'PRICE RETRIEVING ERROR'
      })
    );
  }
}

function* getProductInfo(payload) {
  try {
    yield put({ type: PRODUCT_INFO.PENDING, payload: {} });
    const { siteNo, supcs, customerNum } = payload;
    const response = yield getRequest(`/products-info?siteNo=${siteNo}&supc=[${supcs}]&customerNo=${customerNum}`);
    yield put({ type: PRODUCT_INFO.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: PRODUCT_INFO.FAILURE, payload: error.message });

    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'PRODUCT INFO RETRIEVING ERROR'
      })
    );
  }
}

function* checkAsoh(payload) {
  try {
    yield put({ type: ASOH.PENDING, payload: {} });
    const { businessUnitNumber, products } = payload;
    const response = yield postRequest(`/asoh`, { businessUnitNumber, products });
    yield put({ type: ASOH.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ASOH.FAILURE, payload: error.message });

    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'PRODUCT QUANTITY RETRIEVING ERROR'
      })
    );
  }
}

export { loadBrokerTiesAsync, getProductInfo, checkAsoh, loadPrcpPricing };
