import React from 'react';
import { Table, Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { FEEDBACK_COMPLETION_STATUS, ORDER_COMPLETION_STATUS } from 'utils/Constants';
import {
  columnFilter,
  getFeedbackCompletionStatus,
  getOrderCompletionStatus
} from 'managers/components/OpportunityListViewLogic';
import _ from 'lodash';
class OpportunityListView extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    isVisible: false,
    selectedRow: -1,
    pageSize: this.props.pageSize,
    filteredInfo: {},
    pagination: {},
    sorter: {},
    extra: {},
    pageNumber: 1
  };

  // eslint-disable-next-line react/sort-comp
  getColumns = () => {
    const { filteredInfo, sorter } = this.state;
    const { readOnlyMode } = this.props;
    return [
      {
        title: 'Status',
        dataIndex: 'feedbackStatus',
        key: 'feedbackStatus',
        width: '7rem',
        align: 'center',
        filters: _.get(this.props.filters, 'feedbackStatus', []).map(item => {
          return {
            text: _.upperFirst(
              item
                .split('_')
                .join(' ')
                .toLowerCase()
            ),
            value: item
          };
        }),
        filteredValue: filteredInfo?.feedbackStatus || null,
        onFilter: (value, record) => {
          return columnFilter(value, record, this.props.feedbackStatusList);
        },
        render: value => {
          return (
            <div className="status-circle-wrapper">
              <div className="status-circle"> </div>
            </div>
          );
        }
      },
      {
        title: 'ID',
        dataIndex: 'customerNum',
        key: 'customerNum',
        width: '10rem',
        sorter: true,
        sortOrder: sorter?.columnKey === 'customerNum' ? sorter?.order : null,
        filteredValue: filteredInfo?.customerNum || null,
        sortDirections: ['ascend', 'descend']
      },
      {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName',
        sorter: true,
        sortOrder: sorter?.columnKey === 'customerName' ? sorter?.order : null,
        filteredValue: filteredInfo?.customerName || null,
        sortDirections: ['ascend', 'descend']
      },
      {
        title: 'City',
        dataIndex: 'customerCity',
        key: 'customerCity',
        sorter: true,
        sortOrder: sorter?.columnKey === 'customerCity' ? sorter?.order : null,
        filteredValue: filteredInfo?.customerCity || null,
        sortDirections: ['ascend', 'descend']
      },
      {
        title: 'Broker Name ',
        dataIndex: 'brokerName',
        key: 'brokerName',
        sorter: true,
        sortOrder: sorter?.columnKey === 'brokerName' ? sorter?.order : null,
        sortDirections: ['ascend', 'descend'],
        filteredValue: filteredInfo?.brokerName || null,
        filters: _.get(this.props.filters, 'brokerName', []).map(item => {
          return {
            text: item,
            value: item
          };
        }),
        filterSearch: true
      },
      {
        title: 'DSM Name',
        dataIndex: 'dsmName',
        key: 'dsmName',
        sorter: true,
        sortOrder: sorter?.columnKey === 'dsmName' ? sorter?.order : null,
        filteredValue: filteredInfo?.dsmName || null,
        sortDirections: ['ascend', 'descend']
      },
      {
        title: 'SC Name',
        dataIndex: 'scName',
        key: 'scName',
        sorter: true,
        sortOrder: sorter?.columnKey === 'scName' ? sorter?.order : null,
        filteredValue: filteredInfo?.scName || null,
        sortDirections: ['ascend', 'descend']
      },
      {
        title: 'Opportunities',
        dataIndex: 'noOfOpp',
        key: 'noOfOpp',
        sorter: true,
        sortOrder: sorter?.columnKey === 'noOfOpp' ? sorter?.order : null,
        filteredValue: filteredInfo?.noOfOpp || null,
        sortDirections: ['ascend', 'descend']
      },
      {
        title: 'Place Order',
        dataIndex: 'placeOrder',
        key: 'placeOrder',
        render: (value, record) => {
          const { siteName, siteId, brokerName, customerName, customerNum, scName } = record;
          const params = { siteName, siteId, brokerName, customerName, customerNum, scName };
          const orderStatus = getOrderCompletionStatus(record.customerNum, this.props.orderStatusList);

          let renderElement = null;
          if (orderStatus.completionStatus === ORDER_COMPLETION_STATUS.PROCESSING) {
            renderElement = <span className="place-order-processing">{'Processing...'}</span>;
          } else if (orderStatus.completionStatus === ORDER_COMPLETION_STATUS.COMPLETED) {
            renderElement = (
              <span className="place-order-completed">{orderStatus.orderConfirmationNum.toUpperCase()}</span>
            );
          } else if (orderStatus.completionStatus === ORDER_COMPLETION_STATUS.FAILED) {
            renderElement = <span className="place-order-failed">{'Failed!'}</span>;
          } else {
            renderElement = (
              <Button
                disabled={readOnlyMode}
                type="primary"
                onClick={e => {
                  e.stopPropagation();
                  this.handlePlaceOrderClick(params);
                }}
              >
                Place Order
              </Button>
            );
          }

          return renderElement;
        }
      }
    ];
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(preProps, prevState) {
    const { selectedBroker, tableChangeHandler, onSorting, handlePageChange } = this.props;
    const { filteredInfo, sorter, pagination, extra } = this.state;
    if (selectedBroker && selectedBroker !== preProps.selectedBroker) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          filteredInfo: {},
          pagination: {},
          sorter: {},
          extra: {},
          searchText: '',
          pageNumber: 1
        },
        () => {
          tableChangeHandler(pagination, {}, {}, extra);
          onSorting(sorter.field, sorter.order);
          handlePageChange(pagination.current + 1, pagination.pageSize);
        }
      );
    }
    if (Object.keys(filteredInfo).length > 0 && prevState.filteredInfo !== filteredInfo) {
      tableChangeHandler(pagination, filteredInfo, sorter, extra);
      onSorting(sorter.field, sorter.order);
      handlePageChange(pagination.current + 1, pagination.pageSize);
    }
  }

  handlePlaceOrderClick = params => {
    const path = '/suite/brokeroppt/placeorder';
    this.props.handleRoute(path, params);
  };

  getSearchWords = searchText => {
    if (searchText) {
      return [searchText.trim()];
    }
    return [];
  };

  getSearchInput = (dataIndex, title, setSelectedKeys, selectedKeys, confirm) => {
    if (title === 'Opportunities') {
      return (
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={e => this.filterNumberInput(e, setSelectedKeys)}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
      );
    } else {
      return (
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
      );
    }
  };

  filterNumberInput = (e, setSelectedKeys) => {
    let value = e.target.value ? [e.target.value.replace(/(\D)|(^0+)/gm, '')] : []; ///(\D)|(^0+)/gm
    if (value && value[0] && value[0].length > 9) {
      return false;
    }
    setSelectedKeys(value);
    return true;
  };
  getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {this.getSearchInput(dataIndex, title, setSelectedKeys, selectedKeys, confirm)}
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <i className="icon fi flaticon-magnifying-glass" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .trim()
        .includes(value.toLowerCase().trim()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={this.getSearchWords(this.state.searchText)}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  /**
   * returns class name to display completion status of conversions
   * @param {table row data} row
   * @returns class name string
   */
  getFeedbackStatusClassName = row => {
    let className = 'updateby-filled';
    const feedbackStatus = getFeedbackCompletionStatus(row.customerNum, this.props.feedbackStatusList);
    let statusClass = '';
    if (feedbackStatus === 'COMPLETED') {
      statusClass = 'full';
    } else if (feedbackStatus === 'PARTIALLY_COMPLETED') {
      statusClass = 'partial';
    } else {
      statusClass = 'pending';
    }
    className = className + ' ' + statusClass;
    return className;
  };

  render() {
    const { pageNumber } = this.state;
    const modifiedColumns = this.getColumns().map(column => {
      if (!['opportunities', 'feedbackStatus', 'placeOrder'].includes(column.key)) {
        return {
          ...column,
          ...this.getColumnSearchProps(column.key, column.title)
        };
      }
      return column;
    });

    return (
      <Table
        columns={modifiedColumns}
        dataSource={this.props.dataSource}
        className="items-list-tbl"
        pagination={{
          position: ['none', 'bottomLeft'],
          pageSize: this.state.pageSize,
          total: this.props.totalOpportunities,
          current: pageNumber,
          onChange: page => {
            this.setState({
              pageNumber: page
            });
          },
          defaultCurrent: 1,
          showTotal: total => (
            <Button
              type="primary"
              className="btn green-action-btn download-report-btn"
              onClick={this.props.handleReportGeneration}
            >
              Generate & Download Report
            </Button>
          )
        }}
        scroll={{ y: 'calc(100vh - 29rem)' }}
        loading={this.props.loading}
        rowClassName={(record, index) => {
          let className =
            index === this.state.selectedRow && this.props.handleConversionDrawerVisibilityChange.visible
              ? 'selected'
              : '';
          className += ' ' + this.getFeedbackStatusClassName(record);
          return className;
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              this.state.selectedRow = rowIndex;
              this.props.handleDrawer(record);
              this.props.handleConversionDrawerVisibilityChange.visible = !this.props
                .handleConversionDrawerVisibilityChange.visible;
            }
          };
        }}
        onChange={(pagination, filters, sorter, extra) => {
          this.setState({
            filteredInfo: filters,
            pagination,
            sorter,
            extra,
            pageSize: pagination.pageSize
          });
        }}
      />
    );
  }
}
export default OpportunityListView;
