import initialState from 'store/initialState';
import { LIST_OPP_FILTERS } from 'actions/actionTypes';
import { createReducer } from 'reduxHelpers';

const oppFilters = (state = initialState.oppFilterList, action) => {
  switch (action.type) {
    case LIST_OPP_FILTERS.REQUEST:
      return { ...state, fetching: true };
    case LIST_OPP_FILTERS.SUCCESS:
      const { payload } = action;
      payload['feedbackStatus'] = ['COMPLETED', 'PARTIALLY_COMPLETED', 'NOT_STARTED'];
      return { ...state, data: payload, fetching: false };
    default:
      return state;
  }
};

createReducer(LIST_OPP_FILTERS, initialState.oppFilterList);

export default oppFilters;
