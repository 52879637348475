import initialState from 'store/initialState';

import { CREATE_ORDER_SUMMERY_REPORT } from 'actions/actionTypes';

import { createReducer } from 'reduxHelpers';

const orderTableSummeryReportDownload = createReducer(
  CREATE_ORDER_SUMMERY_REPORT,
  initialState.orderTableSummeryReportDownload
);

export default orderTableSummeryReportDownload;
