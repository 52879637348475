import React, { useEffect, useState } from 'react';
import { Pie, Bar } from '@ant-design/plots';
import { Spin } from 'antd';
import { max } from 'underscore';
import { getFormattedChartData } from '../managers/components/singleChartLogic';

function SingleChart({ chartType, chartData, options, sort = null, formatData = null }) {
  // State
  const [config, setConfig] = useState(undefined);
  const [data, setData] = useState([]);

  // add pie chart configurations
  const pieChartConfig = {
    appendPadding: 20,
    data: [],
    angleField: 'value',
    colorField: 'type',
    color: ['#008ddc', '#f3d674', '#84d9e4', '#5fbc5d', '#00d9ff', '#00fca9'],
    radius: 1,
    innerRadius: 0.58,
    meta: {
      value: {
        formatter: v => `${v}`
      }
    },
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      itemSpacing: 4,
      flipPage: false
    },
    label: {
      type: 'spider',
      style: {
        textAlign: 'center',
        fontWeight: 'bold'
      },
      autoRotate: false,
      content: ({ percent }) => `${(percent * 100).toFixed(1)}%`
    },
    statistic: {
      title: false,
      content: {
        content: ''
      }
    },
    interactions: [
      // {
      //   type: 'element-selected'
      // },
      // {
      //   type: 'element-active'
      // },
      // {
      //   type: 'pie-statistic-active'
      // }
    ],
    ...options
  };

  // add bar chart configurations
  const barChartConfig = {
    data: [],
    xField: 'value',
    yField: 'type',
    seriesField: 'type',
    // Utilizing a hidden legend here to force chart to add a padding to the right side of the chart to show unclipped label
    legend: {
      offsetY: -1000,
      maxWidth: 30
    },
    label: {
      position: 'right',
      offset: 3,
      content: item => {
        return `${item.percentage}%`;
      },
      style: {
        fontWeight: 'bold'
      }
    },
    ...options
  };

  useEffect(() => {
    setConfig(chartType === 'BarChart' ? barChartConfig : pieChartConfig);
  }, []);

  useEffect(() => {
    setData(getFormattedChartData(chartData.data, sort, formatData));
  }, [chartData]);

  useEffect(() => {
    let colorObj = {};

    if (chartType === 'BarChart') {
      colorObj = {
        color: ({ type }) => {
          const maxValue = max(data, item => item.value);
          return type === maxValue.type ? '#da7875' : '#5fbc5d';
        }
      };
    }

    setConfig(prv => ({
      ...prv,
      data,
      ...colorObj
    }));
  }, [data]);
  if (chartData.fetching) {
    return (
      <div className="chart-content">
        <Spin />
      </div>
    );
  }
  if (config?.data.length === 0) {
    return (
      <div className="chart-content">
        <span>No Data</span>
      </div>
    );
  }
  if (config === undefined) {
    return <></>;
  }
  if (chartType === 'BarChart') {
    return <Bar {...config} />;
  }
  return <Pie {...config} />;
}
export default SingleChart;
