import initialState from 'store/initialState';

import { DASHBOARD_ORDER_SUMMERY_TABLE } from 'actions/actionTypes';

import { createReducer } from 'reduxHelpers';

const dashboardOrderTableReducer = createReducer(
  DASHBOARD_ORDER_SUMMERY_TABLE,
  initialState.dashboardOrderTableSummeryState
);

export default dashboardOrderTableReducer;
