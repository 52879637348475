import initialState from 'store/initialState';
import { ALL_BROKERS } from 'actions/actionTypes';

const allBrokers = (state = initialState.allBrokers, _action) => {
  switch (_action.type) {
    case ALL_BROKERS.REQUEST:
      return { ...state, data: _action.payload };
    default:
      return state;
  }
};

export default allBrokers;
