/**
 * Competion status values of feedback form
 */
const FEEDBACK_COMPLETION_STATUS = {
  COMPLETED: 'COMPLETED',
  PARTIALLY_COMPLETED: 'PARTIALLY_COMPLETED',
  NOT_STARTED: 'NOT_STARTED'
};

/**
 * opp list loading default params
 */
const OPP_LIST = {
  DEFAULT_PAGE_SIZE: 20,
  DEFAULT_SORT_PARAMS: { asc: ['customerNum'], desc: [] }
};

/**
 * Order Competion status values of feedback form
 */
const ORDER_COMPLETION_STATUS = {
  CREATED: 'CREATED',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  NOT_STARTED: 'NOT_STARTED'
};

/**
 * opportunity feedback form question types
 */
const OPP_FEEDBACK_QUESTION_TYPE = {
  TEXT: 'text',
  RADIO: 'radio',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  MULTISELECT: 'multiselect'
};

const PLACE_ORDER_FILTER_TYPE = {
  SITE: 'siteId',
  CUSTOMER_NUM: 'customerNum',
  BROKER_NAME: 'brokerName',
  SALES_CONSULTANT: 'scName',
  DATE_NEEDED: 'dateNeeded',
  DELIVERY_METHOD: 'deliveryMethod'
};

const ERROR_MESSAGES = {
  INVALID_INPUT: 'Invalid input.',
  ONLY_N_ITEMS_ARE_AVAILABLE: 'Only {num} items are available.',
  EXISTING_SUPC_NUMBER: 'Existing SUPC number.',
  ITEM_NOT_AVAILABLE: 'Item not available.',
  REQUIRED_FIELD: 'Required field.',
  ITEM_TIE_NOT_AVAILABLE: 'Broker tie not available.',
  NUM_CHARACTERS_ARE_REQUIRED: '6 or 7 characters are required.',
  PRODUCT_IS_NOT_ORDERABLE: 'This product is not orderable',
  PRODUCT_INACTIVE: 'This product is not active',
  ASOH_PRODUCT_NOT_FOUND: 'Product not found',
  PRODUCT_IS_OUT_OF_STOCKS: 'Product is out of stocks.'
};

const REGEXP_NUMBERS_ONLY = /^[0-9]+$/;

const CONVERSION_ITEM_VALIDATION_FIELD = {
  supc: { key: 'supc', fieldName: 'toItemNum' },
  case: { key: 'case', fieldName: 'fromPack' },
  split: { key: 'split', fieldName: 'split' }
};
const USER_TYPE_ASSOCIATE_ROLE = 2201;
const USER_TYPE_BROKER_ROLE = 2200;
const USER_TYPE_ASSOCIATE_PERMISSION = 'BROPAPP.INTERNAL.VIEW';
const USER_TYPE_ASSOCIATE_REPORT_PERMISSION = 'BROPAPP.REPORT.VIEW';
/**
 * SingleChart Types
 */
const CHART_TYPES = {
  SOLD_STATUS_SUMMARY: 'SOLD_STATUS_SUMMARY',
  NOT_SOLD_REASONS_SUMMARY: 'NOT_SOLD_REASONS_SUMMARY',
  CLOSED_OPP_BY_ENGAGEMENTS_SUMMARY: 'CLOSED_OPP_BY_ENGAGEMENTS_SUMMARY',
  CUSTOMER_COMMITMENT_SUMMARY: 'CUSTOMER_COMMITMENT_SUMMARY',
  ORDER_PLACEMENT_SUMMARY: 'ORDER_PLACEMENT_SUMMARY'
};

const CURRENCY_SYMBOL = '$';

export {
  FEEDBACK_COMPLETION_STATUS,
  OPP_FEEDBACK_QUESTION_TYPE,
  PLACE_ORDER_FILTER_TYPE,
  REGEXP_NUMBERS_ONLY,
  ERROR_MESSAGES,
  CONVERSION_ITEM_VALIDATION_FIELD,
  ORDER_COMPLETION_STATUS,
  OPP_LIST,
  USER_TYPE_ASSOCIATE_ROLE,
  USER_TYPE_BROKER_ROLE,
  USER_TYPE_ASSOCIATE_PERMISSION,
  CHART_TYPES,
  CURRENCY_SYMBOL,
  USER_TYPE_ASSOCIATE_REPORT_PERMISSION
};
