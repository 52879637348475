import { takeLatest } from 'redux-saga/effects';
import { DASHBOARD_SCORE_CARD_CONVERSION, DASHBOARD_SCORE_CARD_SALES } from 'actions/actionTypes';
import { loadScoreCardConversionAsync, loadScoreCardSalesAsync } from './DashboardScoreCardSaga';

const dashboardScoreCards = [
  takeLatest(DASHBOARD_SCORE_CARD_CONVERSION.REQUEST, loadScoreCardConversionAsync),
  takeLatest(DASHBOARD_SCORE_CARD_SALES.REQUEST, loadScoreCardSalesAsync)
];

export default dashboardScoreCards;
