import { action } from 'reduxHelpers';
import {
  USER,
  BROKER_LIST,
  LIST,
  OPPORTUNITY_SELECTED,
  OPPORTUNITY_SELECTED_CLEAR,
  UPDATE_LOCAL_FEEDBACK,
  UPDATE_FEEDBACK,
  FEEDBACK,
  REFRESHSTATUS,
  LIST_OPP_FILTERS,
  CONVERSION_DETAILS,
  QUESTIONS,
  TRANCHES,
  GENERATE_FEEDBACK_REPORT,
  BROKER_TIES,
  ENTERPISE_PRCP_PRICES,
  PRODUCT_INFO,
  ASOH,
  SUBMIT_ORDERS,
  ORDER_STATUS,
  CUSTOMER_NUMS_IN_SITE,
  DASHBOARD_CHARTS,
  DASHBOARD_ORDER_SUMMERY_TABLE,
  DASHBOARD_SCORE_CARD_CONVERSION,
  DASHBOARD_SCORE_CARD_SALES,
  BC,
  IG,
  AG,
  CREATE_ORDER_SUMMERY_REPORT,
  ALL_BROKERS
} from './actionTypes';

const loadUserDetails = userId => action(USER.REQUEST, { userId });
const loadBrokerList = () => action(BROKER_LIST.REQUEST, {});
const loadList = params => action(LIST.REQUEST, params);
const loadListFilters = (filters, brokerCode, allBrokers) => action(LIST.REQUEST, { filters, brokerCode, allBrokers });
const opportunitySelected = opportunity => action(OPPORTUNITY_SELECTED.REQUEST, { opportunity });
const clearOpportunitySelected = () => action(OPPORTUNITY_SELECTED_CLEAR.REQUEST, {});
const updateFeedbackLocally = feedback => action(UPDATE_LOCAL_FEEDBACK.REQUEST, { feedback });
const updateFeedback = data => action(UPDATE_FEEDBACK.REQUEST, { data });
const retrieveFeedback = conversionId => action(FEEDBACK.REQUEST, conversionId);
const refreshStatus = refreshIds => action(REFRESHSTATUS.REQUEST, { refreshIds });
const retriveOpprtunityFiltrs = params => action(LIST_OPP_FILTERS.REQUEST, params);
const retriveConversionDetails = customer => action(CONVERSION_DETAILS.REQUEST, customer);
const loadQuestions = () => action(QUESTIONS.REQUEST, {});
const loadTranches = params => action(TRANCHES.REQUEST, params);
const loadBc = params => action(BC.REQUEST, params);
const loadIg = params => action(IG.REQUEST, params);
const loadAg = params => action(AG.REQUEST, params);

const generateFeedbackReport = params => action(GENERATE_FEEDBACK_REPORT.REQUEST, params);
const loadBrokerTies = params => action(BROKER_TIES.REQUEST, params);
const loadProductInfo = params => action(PRODUCT_INFO.REQUEST, params);
const loadAsoh = params => action(ASOH.REQUEST, params);
const retrieveOrderPrices = requestBody => action(ENTERPISE_PRCP_PRICES.REQUEST, { requestBody });
const submitOrders = (requestBody, successCallBack) => action(SUBMIT_ORDERS.REQUEST, { requestBody, successCallBack });
const loadOrderStatus = requestBody => action(ORDER_STATUS.REQUEST, requestBody);
const loadCustomerNumsForSite = siteId => action(CUSTOMER_NUMS_IN_SITE.REQUEST, { siteId });
const loadDashboardCardConversion = params => action(DASHBOARD_SCORE_CARD_CONVERSION.REQUEST, params);
const loadDashboardCardSales = params => action(DASHBOARD_SCORE_CARD_SALES.REQUEST, params);
const loadDashboardCharts = params => action(DASHBOARD_CHARTS.REQUEST, params);
const clearDashboardCharts = params => action(DASHBOARD_CHARTS.CLEAR, params);
const clearDashboardCardConversion = params => action(DASHBOARD_SCORE_CARD_CONVERSION.CLEAR, params);
const clearDashboardCardSales = params => action(DASHBOARD_SCORE_CARD_SALES.CLEAR, params);
const loadDashboardOrderSummeryTable = params => action(DASHBOARD_ORDER_SUMMERY_TABLE.REQUEST, params);
const clearList = () => action(LIST.CLEAR);
const startOrderSummeryTableReportDownload = params => action(CREATE_ORDER_SUMMERY_REPORT.REQUEST, params);
const clearOrderSummeryTableReport = () => action(CREATE_ORDER_SUMMERY_REPORT.CLEAR);

const setAllBrokers = params => action(ALL_BROKERS.REQUEST, params);

export {
  loadUserDetails,
  loadBrokerList,
  loadList,
  loadListFilters,
  opportunitySelected,
  clearOpportunitySelected,
  updateFeedbackLocally,
  updateFeedback,
  retrieveFeedback,
  refreshStatus,
  retriveOpprtunityFiltrs,
  retriveConversionDetails,
  loadQuestions,
  loadTranches,
  generateFeedbackReport,
  loadBrokerTies,
  loadProductInfo,
  loadAsoh,
  retrieveOrderPrices,
  submitOrders,
  loadOrderStatus,
  loadCustomerNumsForSite,
  loadDashboardCardConversion,
  loadDashboardCardSales,
  loadDashboardCharts,
  loadDashboardOrderSummeryTable,
  clearDashboardCardConversion,
  clearDashboardCardSales,
  clearDashboardCharts,
  clearList,
  loadBc,
  loadIg,
  loadAg,
  startOrderSummeryTableReportDownload,
  clearOrderSummeryTableReport,
  setAllBrokers
};
