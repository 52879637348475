import { takeLatest } from 'redux-saga/effects';
import { UPDATE_FEEDBACK, FEEDBACK, REFRESHSTATUS, GENERATE_FEEDBACK_REPORT } from 'actions/actionTypes';
import {
  updateFeedbackAsync,
  fetchFeedbackAsync,
  fetchRefeshStatusAsync,
  generateFeedbackReport
} from './feedbackSaga';

const feedbackSaga = [
  takeLatest(UPDATE_FEEDBACK.REQUEST, updateFeedbackAsync),
  takeLatest(FEEDBACK.REQUEST, fetchFeedbackAsync),
  takeLatest(REFRESHSTATUS.REQUEST, fetchRefeshStatusAsync),
  takeLatest(GENERATE_FEEDBACK_REPORT.REQUEST, generateFeedbackReport)
];

export default feedbackSaga;
