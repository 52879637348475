import { SUBMIT_ORDERS } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { postRequest, getRequest } from '_http';
import { action } from 'reduxHelpers';

function* submitOrders(payload) {
  try {
    yield put({ type: SUBMIT_ORDERS.PENDING, payload: {} });
    const { requestBody, successCallBack } = payload;
    const response = yield postRequest(`/orders`, requestBody);
    yield put({ type: SUBMIT_ORDERS.SUCCESS, payload: response.data });

    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Orders submitted successfully',
        className: 'success',
        message: 'ORDERS SUBMITTED SUCCESSFULLY'
      })
    );

    if (successCallBack) {
      successCallBack();
    }
  } catch (error) {
    yield put({ type: SUBMIT_ORDERS.FAILURE, payload: error.message });

    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ORDER SUBMISSION FAILED'
      })
    );
  }
}

export default submitOrders;
