import { put } from 'redux-saga/effects';
import { postRequest } from '_http';
import { DASHBOARD_CHARTS } from 'actions/actionTypes';

import { action } from 'reduxHelpers';

function* loadChartsAsync(payload) {
  try {
    const { broker, tranche, chartType, filters, allBrokers } = payload;
    const response = yield postRequest(
      `/oppt/opp-dashboard/charts/${chartType}?broker-code=${broker}&tranche=${tranche}&all-brokers=${allBrokers}`,
      filters
    );
    yield put({
      type: DASHBOARD_CHARTS.SUCCESS,
      payload: {
        chartType,
        data: response.data
      }
    });
  } catch (error) {
    const { chartType } = payload;
    yield put({
      type: DASHBOARD_CHARTS.FAILURE,
      payload: {
        chartType,
        data: error.message
      }
    });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: `${chartType} LOADING ERROR`
      })
    );
  }
}

export default loadChartsAsync;
