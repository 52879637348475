import { combineReducers } from 'redux';

import user from './UserReducer';
import brokerList from './BrokerListReducer';
import opptList from './OpptListReducer';
import selectedOpportunity from './SelectedOpportunityReducer';
import questions from './QuestionsReducer';
import feedback from './FeedbackReducer';
import oppFilters from './OppFilterListReducer';
import conversionDetails from './ConversionDetailsReducer';
import feedbackStatus from './FeedbackStatusReducer';
import tranches from './TranchesReducer';
import feedbackReport from './FeedbackReportReducer';
import brokerTies from './BrokerTiesReducer';
import productInfo from './ProductInfoReducer';
import asoh from './AsohReducer';
import prcpPricing from './PrcpPrcingReducer';
import placeOrder from './PlaceOrderReducer';
import orderStatus from './OrderStatusReducer';
import customerNumsForSite from './CustomerNumsForSiteReducer';
import dashboardScoreCardConversion from './DashboardScoreCardConversion';
import dashboardScoreCardSales from './DashboardScoreCardSales';
import dashboardCharts from './DashboardChartsReducer';
import dashboardOrderTableReducer from './DashboardOrderTableReducer';
import bc from './BcReducer';
import ig from './IgReducer';
import ag from './AgReducer';
import orderTableSummeryReportDownload from './OrderSummeryReportDownloadReducer';
import allBrokers from './AllBrokersReducers';

export default combineReducers({
  user,
  brokerList,
  opptList,
  selectedOpportunity,
  questions,
  feedback,
  oppFilters,
  conversionDetails,
  feedbackStatus,
  tranches,
  feedbackReport,
  brokerTies,
  productInfo,
  asoh,
  prcpPricing,
  placeOrder,
  orderStatus,
  customerNumsForSite,
  dashboardScoreCardConversion,
  dashboardScoreCardSales,
  dashboardCharts,
  dashboardOrderTableReducer,
  bc,
  ig,
  ag,
  orderTableSummeryReportDownload,
  allBrokers
});
