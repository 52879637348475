import { takeLatest } from 'redux-saga/effects';
import {
  CONVERSION_DETAILS,
  LIST,
  LIST_OPP_FILTERS,
  QUESTIONS,
  TRANCHES,
  CUSTOMER_NUMS_IN_SITE
} from 'actions/actionTypes';
import {
  loadListAsync,
  loadQuestionsAsync,
  loadOpportunityFilersAsync,
  loadConversionDetailsAsync,
  loadTranchesAsync,
  loadCustomerIdsForSiteAsync
} from './OppSaga';

const OppSaga = [
  takeLatest(LIST.REQUEST, loadListAsync),
  takeLatest(QUESTIONS.REQUEST, loadQuestionsAsync),
  takeLatest(LIST_OPP_FILTERS.REQUEST, loadOpportunityFilersAsync),
  takeLatest(CONVERSION_DETAILS.REQUEST, loadConversionDetailsAsync),
  takeLatest(TRANCHES.REQUEST, loadTranchesAsync),
  takeLatest(CUSTOMER_NUMS_IN_SITE.REQUEST, loadCustomerIdsForSiteAsync)
];

export default OppSaga;
