import { takeLatest } from 'redux-saga/effects';
import { CREATE_ORDER_SUMMERY_REPORT, DASHBOARD_ORDER_SUMMERY_TABLE } from 'actions/actionTypes';
import loadTrancheListAsync, { loadOrderSummeryReportAsync } from './DashboardOrderTableSaga';

const dashboardOrderSummerySaga = [
  takeLatest(DASHBOARD_ORDER_SUMMERY_TABLE.REQUEST, loadTrancheListAsync),
  takeLatest(CREATE_ORDER_SUMMERY_REPORT.REQUEST, loadOrderSummeryReportAsync)
];

export default dashboardOrderSummerySaga;
