import { FEEDBACK_COMPLETION_STATUS, ORDER_COMPLETION_STATUS } from 'utils/Constants';

const columnFilter = (value, record, feedbackStatusList) => {
  const completionStatus = getFeedbackCompletionStatus(record.customerNum, feedbackStatusList);
  return value === completionStatus;
};

const columnSorter = (firstItem, secondItem, feedbackStatusList) => {
  /**
   * sorting is done by assigning values to completion status values
   * COMPLETED = 1, PARTIALLY_COMPLETED = 0, NOT_STARTED = -1
   */

  const sortVals = { COMPLETED: 1, PARTIALLY_COMPLETED: 0, NOT_STARTED: -1 };
  const completionStatusOfFirstItem = getFeedbackCompletionStatus(firstItem.customerNum, feedbackStatusList);
  const completionStatusOfSecondItem = getFeedbackCompletionStatus(secondItem.customerNum, feedbackStatusList);

  return sortVals[completionStatusOfSecondItem] - sortVals[completionStatusOfFirstItem];
};

/**
 * get overall completion status for a customer
 * @param {customerId} customerId
 * @param {feedbackStatusList} feedbackStatusList
 * @returns overall conversion completion status for a customer
 */
const getFeedbackCompletionStatus = (customerId, feedbackStatusList) => {
  // any null value in status field will be treated as NOT_STARTED
  //   console.log('props : ', props);
  let feedbackStatus = null;
  if (feedbackStatusList && feedbackStatusList.length > 0) {
    feedbackStatus = feedbackStatusList.find(item => item.customerId === customerId);
  }

  if (feedbackStatus && feedbackStatus.status && feedbackStatus.status.length > 0) {
    // here if status array length is 1 then we can just return the value
    if (feedbackStatus.status.length === 1) {
      return feedbackStatus.status[0] || FEEDBACK_COMPLETION_STATUS.NOT_STARTED;
    } else {
      // if there are multiple status values, then we need to reduce it to one status
      // if all status values are COMPLETED -> COMPLETED
      // if all are NOT_STARTED -> NOT_STARTED
      // otherwise -> PARTIALLY_COMPLETED
      const isAllCompleted = feedbackStatus.status.every(item => item === FEEDBACK_COMPLETION_STATUS.COMPLETED);
      if (isAllCompleted) {
        return FEEDBACK_COMPLETION_STATUS.COMPLETED;
      }
      const isAllNotStarted = feedbackStatus.status.every(item => item === FEEDBACK_COMPLETION_STATUS.NOT_STARTED);
      if (isAllNotStarted) {
        return FEEDBACK_COMPLETION_STATUS.NOT_STARTED;
      }
      return FEEDBACK_COMPLETION_STATUS.PARTIALLY_COMPLETED;
    }
  }
  return FEEDBACK_COMPLETION_STATUS.NOT_STARTED;
};

/**
 * get overall completion status of an order for a customer
 * @param {customerId} customerId
 * @param {feedbackStatusList} feedbackStatusList
 * @returns overall order completion status for a customer
 */
const getOrderCompletionStatus = (customerId, orderStatusList) => {
  let orderStatus = null;
  let orderConfirmationNum = null;
  if (orderStatusList && orderStatusList.length > 0) {
    orderStatus = orderStatusList.find(item => item.customerNo === customerId);
  }

  let orderStatusStr = null;
  if (orderStatus && orderStatus.status && orderStatus.status.length > 0) {
    orderStatusStr = orderStatus.status;
    orderConfirmationNum = orderStatus.orderConfirmationNum;
  }

  if (orderStatusStr) {
    // PROCESSING and CREATED states are considered as PROCESSING
    if (orderStatusStr === ORDER_COMPLETION_STATUS.CREATED) {
      orderStatusStr = ORDER_COMPLETION_STATUS.PROCESSING;
    }
    return { completionStatus: orderStatusStr, orderConfirmationNum };
  }

  return { completionStatus: ORDER_COMPLETION_STATUS.NOT_STARTED, orderConfirmationNum: null };
};

export { columnFilter, getFeedbackCompletionStatus, columnSorter, getOrderCompletionStatus };

export const calculatePrice = priceObj => {
  try {
    const { netPrice, perWeightFlag, averageNetWeight } = priceObj;
    if (perWeightFlag) {
      return +(averageNetWeight * netPrice).toFixed(2);
    }
    return netPrice;
  } catch (e) {
    console.log('price cal error', e);
    return priceObj.netPrice || '';
  }
};
