import initialState from 'store/initialState';

import { OPPORTUNITY_SELECTED, OPPORTUNITY_SELECTED_CLEAR } from 'actions/actionTypes';

import { createReducer } from 'reduxHelpers';

const selectedOpportunity = (state = initialState.selectedOpportunity, action) => {
  switch (action.type) {
    case OPPORTUNITY_SELECTED.REQUEST:
      const { opportunity } = action;
      return { ...state, data: opportunity, isTabActive: true };
    case OPPORTUNITY_SELECTED_CLEAR.REQUEST:
      return { ...state, ...initialState.selectedOpportunity };
    default:
      return state;
  }
};

createReducer(OPPORTUNITY_SELECTED, initialState.selectedOpportunity);

export default selectedOpportunity;
