import { put } from 'redux-saga/effects';
import { postRequest } from '_http';
import { ORDER_STATUS } from 'actions/actionTypes';
import { action } from 'reduxHelpers';

function* loadOrderStatus(payload) {
  try {
    yield put({ type: ORDER_STATUS.PENDING, payload: {} });

    const { tranche, customerIdAndSiteIdMap, brokerCode } = payload;

    const response = yield postRequest(`/oppt/customer/orders-status`, { tranche, customerIdAndSiteIdMap, brokerCode });
    yield put({ type: ORDER_STATUS.SUCCESS, payload: response.data.customerOrderStatusList });
  } catch (error) {
    yield put({ type: ORDER_STATUS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ORDER STATUS RETRIEVING ERROR'
      })
    );
  }
}

export { loadOrderStatus };
