import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { BROKER_LIST } from 'actions/actionTypes';

import { action } from 'reduxHelpers';

function* loadBrokerListAsync() {
  try {
    const response = yield getRequest(`/oppt/brokers`);
    yield put({ type: BROKER_LIST.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BROKER_LIST.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'BROKER LIST LOADING ERROR'
      })
    );
  }
}

export default loadBrokerListAsync;
