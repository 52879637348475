import initialState from 'store/initialState';
import _ from 'lodash';

import { QUESTIONS } from 'actions/actionTypes';

import { createReducer } from 'reduxHelpers';

const questions = (state = initialState.questions, action) => {
  switch (action.type) {
    case QUESTIONS.SUCCESS:
      const { payload } = action;
      return { ...state, data: sortQuestions(payload) };
    default:
      return state;
  }
};

const sortQuestions = questions => {
  let _questions = [];
  if (questions && questions.length > 0) {
    // sort questions
    _questions = _.sortBy(questions, item => item.questions[0].questionId, ['asc']);
    _questions.forEach(qSet => {
      if (qSet.questions && qSet.questions.length > 0) {
        qSet.questions = _.orderBy(qSet.questions, item => item.questionOrder, ['asc']);
      }
    });
  }
  return _questions;
};

createReducer(QUESTIONS, initialState.questions);

export default questions;
