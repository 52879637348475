import initialState from 'store/initialState';
import { PRODUCT_INFO } from 'actions/actionTypes';

const productInfo = (state = initialState.productInfo, action) => {
  switch (action.type) {
    case PRODUCT_INFO.PENDING:
      return { ...state, fetching: true };
    case PRODUCT_INFO.SUCCESS:
      const { active, isPhasedOut, isSplit, isSelectedNonstock, isNonstockOrderable } = action.payload;
      return { ...state, data: action.payload, fetching: false };
    case PRODUCT_INFO.FAILURE:
      return { ...state, fetching: false };
    default:
      return state;
  }
};

export default productInfo;
