import opptList from './InitialState/opptListInitialState';
import selectedOpportunity from './InitialState/selectedOpportunityState';
import questions from './InitialState/questionsInitialState';
import feedback from './InitialState/feedbackInitialState';
import oppFilterList from './InitialState/oppFilterIistInitialState';
import conversionDetails from './InitialState/conversionDetailsInitialState';
import feedbackStatus from './InitialState/feedbackStatusInitialStatus';
import tranches from './InitialState/tranchesInitialState';
import feedbackReport from './InitialState/feedbackReportInitialState';
import brokerTies from './InitialState/brokerTiesInitialState';
import prcpPricing from './InitialState/prcpPricingInitialState';
import productInfo from './InitialState/productInfoInitialState';
import asoh from './InitialState/asohInitialState';
import placedOrders from './InitialState/placeOrderInitialState';
import orderStatus from './InitialState/orderStatusInitialState';
import customerNumsInSite from './InitialState/customerNumsInSiteInitialState';
import user from './InitialState/userInitialState';
import brokerList from './InitialState/brokerListInitailState';
import dashboardScoreCardConversion from './InitialState/dashboardScoreCardConversion';
import dashboardScoreCardSales from './InitialState/dashboardScoreCardSales';
import dashboardChartsState from './InitialState/dashboardChartsInitialState';
import dashboardOrderTableSummeryState from './InitialState/dashboardOrderTableSummeryState';
import bc from './InitialState/bcInitialState';
import ig from './InitialState/igInitialState';
import ag from './InitialState/agInitialState';
import orderTableSummeryReportDownload from './InitialState/OrderTableSummeryReportDownloadState';
import allBrokers from './InitialState/allBrokers';

const initialState = {
  user,
  brokerList,
  opptList,
  selectedOpportunity,
  questions,
  feedback,
  oppFilterList,
  conversionDetails,
  feedbackStatus,
  tranches,
  feedbackReport,
  brokerTies,
  productInfo,
  asoh,
  prcpPricing,
  placedOrders,
  orderStatus,
  customerNumsInSite,
  dashboardScoreCardConversion,
  dashboardScoreCardSales,
  dashboardChartsState,
  dashboardOrderTableSummeryState,
  bc,
  ig,
  ag,
  orderTableSummeryReportDownload,
  allBrokers
};
export default initialState;
