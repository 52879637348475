import _ from 'lodash';

/**
 * retruns query parameters from a given URL
 * @param {url string} urlStr
 * @returns
 */
const getParamsFromUrl = urlStr => {
  const url = new URL(decodeURIComponent(urlStr));
  var qd = {};
  if (url.search) {
    url.search
      .substr(1)
      .split(`&`)
      .forEach(item => {
        let [k, v] = item.split`=`;
        v = v && decodeURIComponent(v);
        qd[k] = qd[k] || [];
        qd[k].push(v);
      });
  }

  return qd;
};

/**
 * feedbacks can contain arrays. Before sending them to API, we need to concat them into strings.
 *
 * @param {*} feedbacksArray
 * @returns
 */
const flattenFeedbacks = feedbacksArray => {
  if (feedbacksArray && feedbacksArray.length > 0) {
    feedbacksArray.forEach(feedback => {
      if (_.isArray(feedback.answer)) {
        feedback.answer = feedback.answer.join(',');
      }
    });
  }
  return feedbacksArray;
};

const checkBrokerCode = brokerCode => {
  if (brokerCode) {
    return `&broker-code=${brokerCode}`;
  }
  return '';
};
const checkTrancheCode = tranche => {
  if (tranche) {
    return `&tranche=${tranche}`;
  }
  return '';
};

const numberFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  // find block
  const selectedBlock = lookup.reverse().find(block => {
    return num >= block.value;
  });
  let value = selectedBlock ? `${(num / selectedBlock.value).toFixed(digits)}` : num;
  // remove unwanted zeros
  value = parseFloat(value).toString();

  return `${value}${selectedBlock?.symbol ? ` ${selectedBlock?.symbol}` : ''}`;
};

const simpleNumberFormatter = (num, fractionDigits = 0) => {
  let value = (+num).toFixed(fractionDigits);
  if (isNaN(value)) {
    return `0`;
  }
  let symbol = '';
  if (num > 99999) {
    value = (value / 1000).toFixed(fractionDigits);
    symbol = 'K';
  }

  return `${value}${symbol ? ` ${symbol}` : ''}`;
};

export {
  getParamsFromUrl,
  flattenFeedbacks,
  checkBrokerCode,
  checkTrancheCode,
  numberFormatter,
  simpleNumberFormatter
};
