import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { get, minBy } from 'lodash';
import { action } from 'reduxHelpers';
import { getFormCompletionStatus } from 'managers/pages/OpportunityFeedBackDrawerLogic';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAllFeedback,
  selectFeedbackUpdatedby,
  selectFeedbackUpdatingStatus,
  selectQuestions
} from '../selectors/feedbackSelector';
import FeedBackForm from './FeedBackForm';
import { conversionDetails } from '../selectors/conversionDetails';
import { updateFeedback } from '../actions';
import questionModifications from '../data/questionModifications.json';

const OpportunityFeedBackModel = ({ openFeedBackMode, customerData, onClose, done, conversionItems }) => {
  // Loading questions and answers
  const questions = useSelector(selectQuestions);
  const allFeedbacks = useSelector(selectAllFeedback);
  const feedbackUpdatedBy = useSelector(selectFeedbackUpdatedby);
  const conversions = useSelector(conversionDetails);
  const isFeedbackUpdating = useSelector(selectFeedbackUpdatingStatus);
  const dispatch = useDispatch();

  // state variables
  const [validationErrors, setValidationErrors] = useState([]);
  const initConversions = get(conversions, 'data', []);
  const selectedConversionId = get(conversions, 'data[0].conversionId', '');

  /**
   * scrolls the drawer window into a given question
   * @param {questionId} questionId
   */
  const scrollToQuestion = questionId => {
    const inputElementDiv = document.querySelector(`#feedback-input-${questionId}`);

    if (inputElementDiv) {
      setTimeout(() => {
        inputElementDiv.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  };

  /**
   * perform validations and send update feedback request
   */
  const updateFeedbackRequest = () => {
    // any validation should be performed here and errors can be pushed to _validationErrs array

    if (validationErrors.length === 0) {
      const conversionIds = [];
      const initConversionIds = initConversions.map(initConversion => initConversion.conversionId);
      conversionItems.forEach(conversion => {
        // check existing conversion ids with place order item's conversion ids
        if (initConversionIds.includes(conversion.conversionId)) {
          conversionIds.push(conversion.conversionId);
        }
      });
      const completionStatus = getFormCompletionStatus(questions, allFeedbacks);
      conversionIds.forEach(conversionId => {
        dispatch(
          updateFeedback({
            payload: {
              conversionId,
              feedbacks: allFeedbacks,
              feedbackStatus: completionStatus
            },
            customerId: customerData.customerNum,
            onSubmissionSuccess: () => {}
          })
        );
      });
      done();
    } else {
      dispatch(
        action('SHOW_NOTIFICATION', {
          description: 'Please check the provided feedbacks again.',
          className: 'error',
          message: 'FEEDBACK VALIDATION ERROR'
        })
      );

      if (validationErrors.length > 0) {
        const question = minBy(validationErrors, item => item.questionId);
        scrollToQuestion(question.questionId);
      }
    }
  };

  return (
    <Modal
      className="opportunities-feedback-drawer"
      title={
        <div
          style={{
            boxSizing: 'border-box',
            textTransform: 'uppercase',
            fontSize: '1.4rem',
            color: '#6a737b',
            fontWeight: '600',
            width: '100%'
          }}
        >
          FEEDBACK FORM
          <div style={{ fontSize: '1.1rem', fontWeight: '300' }}>
            {`${customerData?.customerName}/${customerData?.customerNum}`}
          </div>
          {feedbackUpdatedBy && feedbackUpdatedBy.length > 0 && (
            <div
              style={{
                fontWeight: 600,
                marginTop: '0.5rem',
                padding: '0.2rem 0.7rem',
                borderRadius: '0.5rem',
                background: '#49943a',
                color: 'white',
                width: 'fit-content',
                fontSize: '1rem'
              }}
            >
              Updated by:
              {feedbackUpdatedBy}
            </div>
          )}
        </div>
      }
      visible={openFeedBackMode}
      footer={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            type="primary"
            className="drawer-btn"
            onClick={updateFeedbackRequest}
            disabled={isFeedbackUpdating}
            loading={isFeedbackUpdating}
          >
            CONFIRM
          </Button>
          <Button className="drawer-btn" onClick={onClose}>
            CANCEL
          </Button>
        </div>
      }
      onCancel={onClose}
    >
      <FeedBackForm
        conversionId={selectedConversionId}
        onChangeErrors={setValidationErrors}
        enableLoadingScreen
        modifications={questionModifications}
      />
    </Modal>
  );
};

export default OpportunityFeedBackModel;
