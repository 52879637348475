import initialState from 'store/initialState';
import { GENERATE_FEEDBACK_REPORT } from 'actions/actionTypes';
import { createReducer } from 'reduxHelpers';

const feedbackReport = (state = initialState.feedbackReport, action) => {
  switch (action.type) {
    case GENERATE_FEEDBACK_REPORT.PENDING:
      return { ...state, status: 'PENDING' };
    case GENERATE_FEEDBACK_REPORT.SUCCESS:
      const { fileName, url, expiredTime } = action.payload;
      return { ...state, status: 'SUCCESS', url, fileName, expiredTime };
    case GENERATE_FEEDBACK_REPORT.FAILURE:
      return { data: '', status: 'FAILURE' };
    default:
      return { ...state };
  }
};

createReducer(GENERATE_FEEDBACK_REPORT, initialState.feedbackReport);

export default feedbackReport;
