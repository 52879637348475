import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const BROKER_LIST = createRequestTypes('BROKER_LIST');
const LIST = createRequestTypes('LIST');
const OPPORTUNITY_SELECTED = createRequestTypes('OPPORTUNITY_SELECTED');
const OPPORTUNITY_SELECTED_CLEAR = createRequestTypes('OPPORTUNITY_SELECTED_CLEAR');
const QUESTIONS = createRequestTypes('QUESTIONS');
const UPDATE_LOCAL_FEEDBACK = createRequestTypes('UPDATE_LOCAL_FEEDBACK');
const UPDATE_FEEDBACK = createRequestTypes('UPDATE_FEEDBACK');
const FEEDBACK = createRequestTypes('FEEDBACK');
const REFRESHSTATUS = createRequestTypes('REFRESHSTATUS');
const LIST_OPP_FILTERS = createRequestTypes('LIST_OPP_FILTERS');
const CONVERSION_DETAILS = createRequestTypes('CONVERSION_DETAILS');
const FEEDBACK_STATUS = createRequestTypes('FEEDBACK_STATUS');
const TRANCHES = createRequestTypes('TRANCHES');
const GENERATE_FEEDBACK_REPORT = createRequestTypes('GENERATE_FEEDBACK_REPORT');
const BROKER_TIES = createRequestTypes('BROKER_TIES');
const PRODUCT_INFO = createRequestTypes('PRODUCT_INFO');
const ASOH = createRequestTypes('ASOH');
const ENTERPISE_PRCP_PRICES = createRequestTypes('ENTERPISE_PRCP_PRICES');
const SUBMIT_ORDERS = createRequestTypes('SUBMIT_ORDERS');
const ORDER_STATUS = createRequestTypes('ORDER_STATUS');
const CUSTOMER_NUMS_IN_SITE = createRequestTypes('CUSTOMER_NUMS_IN_SITE');
const DASHBOARD_SCORE_CARD_CONVERSION = createRequestTypes('DASHBOARD_SCORE_CARD_CONVERSION');
const DASHBOARD_SCORE_CARD_SALES = createRequestTypes('DASHBOARD_SCORE_CARD_SALES');
const DASHBOARD_CHARTS = createRequestTypes('DASHBOARD_CHARTS');
const DASHBOARD_ORDER_SUMMERY_TABLE = createRequestTypes('DASHBOARD_ORDER_SUMMERY_TABLE');
const BC = createRequestTypes('BC');
const IG = createRequestTypes('IG');
const AG = createRequestTypes('AG');
const CREATE_ORDER_SUMMERY_REPORT = createRequestTypes('CREATE_ORDER_SUMMERY_REPORT');

const ALL_BROKERS = createRequestTypes('SET_ALL_BROKER');

export {
  USER,
  BROKER_LIST,
  LIST,
  OPPORTUNITY_SELECTED,
  OPPORTUNITY_SELECTED_CLEAR,
  QUESTIONS,
  UPDATE_LOCAL_FEEDBACK,
  UPDATE_FEEDBACK,
  FEEDBACK,
  REFRESHSTATUS,
  LIST_OPP_FILTERS,
  CONVERSION_DETAILS,
  FEEDBACK_STATUS,
  TRANCHES,
  GENERATE_FEEDBACK_REPORT,
  BROKER_TIES,
  ENTERPISE_PRCP_PRICES,
  PRODUCT_INFO,
  ASOH,
  SUBMIT_ORDERS,
  ORDER_STATUS,
  CUSTOMER_NUMS_IN_SITE,
  DASHBOARD_SCORE_CARD_CONVERSION,
  DASHBOARD_SCORE_CARD_SALES,
  DASHBOARD_CHARTS,
  DASHBOARD_ORDER_SUMMERY_TABLE,
  AG,
  IG,
  BC,
  CREATE_ORDER_SUMMERY_REPORT,
  ALL_BROKERS
};
