import React, { useEffect, useState } from 'react';
import { Drawer, Button, Spin } from 'antd';
import OpportunityFeedBackDrawer from './OpportunityFeedBackDrawer';
import { useSelector, useDispatch } from 'react-redux';
import { conversionDetails } from 'selectors/conversionDetails';
import { prcpPricingSelector, prcpPricingLoadingSelector } from 'selectors/orderValidations';
import parsePhoneNumberFromString from 'libphonenumber-js';
import _ from 'lodash';
import { retrieveOrderPrices } from 'actions';
import { calculatePrice } from '../managers/components/OpportunityListViewLogic';

const OpportunityDrawer = props => {
  //selectors
  const conversions = useSelector(conversionDetails);
  const prcpPrice = useSelector(prcpPricingSelector);
  const isLoadingPrcpPrice = useSelector(prcpPricingLoadingSelector);
  //States to maintain data.
  const [count, setCount] = useState(0);
  const [selectedConversion, setSelectedConversion] = useState();
  const [showFeedbackDrawer, setShowFeedbackDrawer] = useState();
  const [customerNumber, setCustomerNumber] = useState();
  const [siteId, setSiteId] = useState();
  const [casePrice, setCasePrice] = useState();
  const [fromCasePrice, setFromCasePrice] = useState();
  const dispatch = useDispatch();
  const todayDate = new Date();

  useEffect(() => {
    if (conversions && conversions.data) {
      setSelectedConversion(conversions.data[count]);
      const selectedCustomerNum = props?.conversionDrawer?.rowData?.customerNum;
      const selectedSiteId = props?.conversionDrawer?.rowData?.siteId;
      if (selectedCustomerNum) {
        setCustomerNumber(selectedCustomerNum);
      }
      if (selectedSiteId) {
        setSiteId(selectedSiteId);
      }
    }
  }, [conversions, count]);

  useEffect(() => {
    //loading case price for selected conversion
    const priceRequestDateStr = `${todayDate.getFullYear()}${String(todayDate.getMonth() + 1).padStart(2, '0')}${String(
      todayDate.getDate()
    ).padStart(2, '0')}`;

    if (
      conversions &&
      conversions.data &&
      conversions.data.length > 0 &&
      conversions.data[count] &&
      customerNumber &&
      siteId
    ) {
      const products = [];
      if (
        conversions.data[count].fromItemNum &&
        conversions.data[count].fromItemNum !== '' &&
        conversions.data[count].fromItemNum !== null
      ) {
        products.push(
          { supc: String(conversions.data[count].toItemNum).padStart(7, '0'), splitFlag: false, quantity: 1 },
          { supc: String(conversions.data[count].fromItemNum).padStart(7, '0'), splitFlag: false, quantity: 1 }
        );
      } else {
        products.push({
          supc: String(conversions.data[count].toItemNum).padStart(7, '0'),
          splitFlag: false,
          quantity: 1
        });
      }

      const requestBody = {
        businessUnitNumber: siteId,
        customerAccount: customerNumber,
        priceRequestDate: priceRequestDateStr,
        products: products
      };
      dispatch(retrieveOrderPrices(requestBody));
    }
  }, [customerNumber, count]);

  useEffect(() => {
    if (prcpPrice && prcpPrice[0] && prcpPrice[0].netPrice) {
      setCasePrice(calculatePrice(prcpPrice[0]));
    } else {
      setCasePrice('');
    }
    if (prcpPrice && prcpPrice[1] && prcpPrice[1].netPrice) {
      setFromCasePrice(calculatePrice(prcpPrice[1]));
    } else {
      setFromCasePrice('');
    }
  }, [prcpPrice]);

  const increment = () => {
    let counter = count + 1;
    if (conversions.data && conversions.data.length > 0 && conversions.data.length > counter) {
      setCount(counter);
      setSelectedConversion(conversions.data[counter]);
    } else {
      setCount(conversions.data.length - 1);
    }
  };

  const decrement = () => {
    let counter = count - 1;
    if (conversions.data && conversions.data.length > 0 && counter > 0 && conversions.data.length > counter) {
      setCount(counter);
      setSelectedConversion(conversions.data[counter]);
    } else {
      setCount(0);
    }
  };

  const getPhoneFormat = text => {
    let pNum = '';
    let phoneNumber = parsePhoneNumberFromString(`${text}`, 'US');
    if (phoneNumber) {
      pNum = phoneNumber.formatNational();
    }
    return (
      <a target="_blank" href={`tel:+1${text}`} rel="noopener noreferrer" style={{ color: '#7b8c92' }}>
        {pNum}
      </a>
    );
  };

  return (
    <Drawer
      className="opportunities-drawer"
      title={
        <>
          <div className="titles">
            {props?.conversionDrawer?.rowData?.customerName}
            <div className="subtitle"> {props?.conversionDrawer?.rowData?.customerNum}</div>
          </div>
          <div className="controls">
            <Button
              className="drawer-header-btn btn no-border no-shadow"
              onClick={decrement}
              disabled={count + 1 === 1}
            >
              {'<'}
            </Button>
            <Button
              className="drawer-header-btn btn no-border no-shadow no-margin-right"
              onClick={increment}
              disabled={count + 1 === conversions?.data?.length}
            >
              {'>'}
            </Button>
          </div>
        </>
      }
      placement="right"
      visible={props.conversionDrawer.visible && !showFeedbackDrawer}
      width="55rem"
      mask={true}
      // childrenDrawer={true}
      footer={
        <>
          <Button type="primary" className="drawer-btn green-action-btn" onClick={() => setShowFeedbackDrawer(true)}>
            PROVIDE FEEDBACK
          </Button>
          <Button
            type="primary"
            className="drawer-btn"
            onClick={increment}
            disabled={count + 1 === conversions?.data?.length}
          >
            NEXT OPPORTUNITY
          </Button>
          <Button
            className="drawer-btn"
            onClick={() => {
              props.handleDrawer(null);
              setSelectedConversion();
            }}
          >
            CANCEL
          </Button>
        </>
      }
      onClose={() => {
        props.handleDrawer(null);
        setSelectedConversion();
        setCount(0);
      }}
    >
      <div className="count">
        {count + 1} /{conversions?.data?.length}
      </div>
      <div className="meta-info">
        <div className="meta-row">
          <div className="meta-col">
            <div className="meta-label">Customer Address</div>
            <div className="meta-data">{props?.conversionDrawer?.rowData?.customerAddress}</div>
          </div>
          <div className="meta-col">
            <div className="meta-label">Customer Phone Number</div>
            <div className="meta-data">{getPhoneFormat(props?.conversionDrawer?.rowData?.customerPhoneNum)}</div>
          </div>
        </div>
        <div className="meta-row">
          <div className="meta-col">
            <div className="meta-label">DSM Name</div>
            <div className="meta-data"> {props?.conversionDrawer?.rowData?.dsmName}</div>
          </div>
          <div className="meta-col">
            <div className="meta-label">SC Name</div>
            <div className="meta-data"> {props?.conversionDrawer?.rowData?.scName}</div>
          </div>
          <div className="meta-col">
            <div className="meta-label">Category Name</div>
            <div className="meta-data"> {selectedConversion?.catName}</div>
          </div>
        </div>
        <div className="meta-row">
          <div className="meta-col">
            <div className="meta-label">Lot Number</div>
            <div className="meta-data">{props?.conversionDrawer?.rowData?.lotNum}</div>
          </div>
          <div className="meta-col">
            <div className="meta-label">Lot Description</div>
            <div className="meta-data"> {props?.conversionDrawer?.rowData?.lotDesc}</div>
          </div>
        </div>
        <div className="meta-row">
          <div className="meta-col">
            <div className="meta-label">Business Center</div>
            <div className="meta-data">{selectedConversion?.bc}</div>
          </div>
          <div className="meta-col">
            <div className="meta-label">Item Group</div>
            <div className="meta-data"> {selectedConversion?.ig}</div>
          </div>
          <div className="meta-col">
            <div className="meta-label">Attribute Group</div>
            <div className="meta-data"> {selectedConversion?.ag}</div>
          </div>
        </div>
      </div>
      <div className="proposed-changes">
        {/* Wrapped the content with loder icon */}
        <Spin spinning={!selectedConversion}>
          <div className="title">Proposed Opportunity Changes</div>
          <ul>
            <li className="header">
              <div className="col-desc"></div>
              <div className="col-from">FROM ITEM</div>
              <div className="col-to">TO ITEM</div>
            </li>
            <li>
              <div className="col-desc">Item Number</div>
              <div className="col-from">
                {selectedConversion?.fromItemNum ? _.padStart(selectedConversion?.fromItemNum, 7, '0') : '-'}
              </div>
              <div className="col-to">
                {selectedConversion?.toItemNum ? _.padStart(selectedConversion?.toItemNum, 7, '0') : '-'}
              </div>
            </li>
            <li>
              <div className="col-desc">Item Description</div>
              <div className="col-from">
                {selectedConversion?.fromItemDesc ? selectedConversion?.fromItemDesc : '-'}
              </div>
              <div className="col-to">{selectedConversion?.toItemDesc ? selectedConversion?.toItemDesc : '-'}</div>
            </li>
            <li>
              <div className="col-desc">Brand ID</div>
              <div className="col-from">{selectedConversion?.fromBrandId ? selectedConversion?.fromBrandId : '-'}</div>
              <div className="col-to">{selectedConversion?.toBrandId ? selectedConversion?.toBrandId : '-'}</div>
            </li>
            <li>
              <div className="col-desc">Pack</div>
              <div className="col-from">{selectedConversion?.fromPack ? selectedConversion?.fromPack : '-'}</div>
              <div className="col-to">{selectedConversion?.toPack ? selectedConversion?.toPack : '-'}</div>
            </li>
            <li>
              <div className="col-desc">Size</div>
              <div className="col-from">{selectedConversion?.fromSize ? selectedConversion?.fromSize : '-'}</div>
              <div className="col-to">{selectedConversion?.toSize ? selectedConversion?.toSize : '-'}</div>
            </li>
            <li>
              <div className="col-desc">Supplier</div>
              <div className="col-from">
                {selectedConversion?.fromSupplier ? selectedConversion?.fromSupplier : '-'}
              </div>
              <div className="col-to">{selectedConversion?.toSupplier ? selectedConversion?.toSupplier : '-'}</div>
            </li>
            <li>
              <div className="col-desc">Cases Sold</div>
              <div className="col-from">
                {selectedConversion?.fromCasesSold ? selectedConversion?.fromCasesSold : '-'}
              </div>
              <div className="col-to">-</div>
            </li>
            <li>
              <div className="col-desc">Pounds Sold</div>
              <div className="col-from">
                {selectedConversion?.fromPoundsSold ? selectedConversion?.fromPoundsSold : '-'}
              </div>
              <div className="col-to">-</div>
            </li>
            <li>
              <div className="col-desc">Recommended Sell Price</div>
              <div className="col-from">
                {isLoadingPrcpPrice ? (
                  <Spin size="small" />
                ) : (
                  <div className="meta-data">{fromCasePrice ? `$${fromCasePrice}` : '-'}</div>
                )}
              </div>
              <div className="col-to">
                {isLoadingPrcpPrice ? (
                  <Spin size="small" />
                ) : (
                  <div className="meta-data">{casePrice ? `$${casePrice}` : '-'}</div>
                )}
              </div>
            </li>
            <li>
              <div className="col-desc">UOM</div>
              <div className="col-from">-</div>
              <div className="col-to">{selectedConversion?.uom?.trim() ? selectedConversion?.uom : '-'}</div>
            </li>
          </ul>
        </Spin>
      </div>
      <OpportunityFeedBackDrawer
        readOnlyMode={props?.readOnlyMode}
        customerData={props?.conversionDrawer?.rowData}
        showFeedbackDrawer={showFeedbackDrawer}
        selectedConversionId={selectedConversion?.conversionId}
        onClose={() => setShowFeedbackDrawer(false)}
      />
    </Drawer>
  );
};

export default OpportunityDrawer;
