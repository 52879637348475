import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { get } from 'lodash';
import { measureTextWidth } from '@ant-design/plots';
import {
  soldStatusSummery,
  notSoldReasonSummery,
  closedOppByEngagementSummery,
  customerCommitmentSummery,
  orderPlacementSummery
} from '../../selectors/dashboardSelector';
import { loadDashboardCharts, clearDashboardCharts } from '../../actions';
import { CHART_TYPES } from '../../utils/Constants';
import SingleChart from '../../components/SingleChart';
import { allBrokersSelector } from '../../selectors/brokerListSelector';

const noKey = 'No';
const yesByBrokerKey = 'Yes by Broker';
const yesWithSCEngagedKey = 'Yes with SC Engaged';
const soldKey = 'Sold';
const unsoldKey = 'Unsold';

function DashboardCharts({ data }) {
  const dispatch = useDispatch();

  // Selectors
  const soldStatusSummeryChart = useSelector(soldStatusSummery);
  const notSoldReasonSummeryChart = useSelector(notSoldReasonSummery);
  const closedOppByEngagementSummeryChart = useSelector(closedOppByEngagementSummery);
  const customerCommitmentSummeryChart = useSelector(customerCommitmentSummery);
  const orderPlacementSummeryChart = useSelector(orderPlacementSummery);
  const allBrokers = useSelector(allBrokersSelector);
  useEffect(() => {
    const { broker, tranche, filters } = data;
    if (tranche) {
      // set chart payload
      const chartFilter = {
        market: get(filters, 'market', []),
        region: get(filters, 'region', []),
        siteId: get(filters, 'siteId', []),
        conversionCatName: get(filters, 'categoryName', []),
        supplier: get(filters, 'supplier', []),
        bc: get(filters, 'bc', []),
        ig: get(filters, 'ig', []),
        ag: get(filters, 'ag', [])
      };
      const chart1 = { broker, tranche, chartType: CHART_TYPES.SOLD_STATUS_SUMMARY, filters: chartFilter, allBrokers };
      const chart2 = {
        broker,
        tranche,
        chartType: CHART_TYPES.NOT_SOLD_REASONS_SUMMARY,
        filters: chartFilter,
        allBrokers
      };
      const chart3 = {
        broker,
        tranche,
        chartType: CHART_TYPES.CLOSED_OPP_BY_ENGAGEMENTS_SUMMARY,
        filters: chartFilter,
        allBrokers
      };
      const chart4 = {
        broker,
        tranche,
        chartType: CHART_TYPES.CUSTOMER_COMMITMENT_SUMMARY,
        filters: chartFilter,
        allBrokers
      };
      const chart5 = {
        broker,
        tranche,
        chartType: CHART_TYPES.ORDER_PLACEMENT_SUMMARY,
        filters: chartFilter,
        allBrokers
      };

      dispatch(loadDashboardCharts(chart1));
      dispatch(loadDashboardCharts(chart2));
      dispatch(loadDashboardCharts(chart3));
      dispatch(loadDashboardCharts(chart4));
      dispatch(loadDashboardCharts(chart5));
    } else {
      // rest current chart data
      dispatch(clearDashboardCharts({ chartType: CHART_TYPES.SOLD_STATUS_SUMMARY }));
      dispatch(clearDashboardCharts({ chartType: CHART_TYPES.NOT_SOLD_REASONS_SUMMARY }));
      dispatch(clearDashboardCharts({ chartType: CHART_TYPES.CLOSED_OPP_BY_ENGAGEMENTS_SUMMARY }));
      dispatch(clearDashboardCharts({ chartType: CHART_TYPES.CUSTOMER_COMMITMENT_SUMMARY }));
      dispatch(clearDashboardCharts({ chartType: CHART_TYPES.ORDER_PLACEMENT_SUMMARY }));
    }
  }, [data]);
  const renderStatistic = (containerWidth, text, style) => {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      // eslint-disable-next-line no-restricted-properties
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  };
  const opportunitiesDataFormat = dataSet => {
    if (dataSet.length == 0) {
      return [];
    }

    const yesWithSCEngaged = dataSet.find(item => item.keyName === yesWithSCEngagedKey);
    const yesByBroker = dataSet.find(item => item.keyName === yesByBrokerKey);
    const no = dataSet.find(item => item.keyName === noKey);

    const sold = (yesByBroker?.value || 0) + (yesWithSCEngaged?.value || 0);
    const unsold = no?.value || 0;
    const soldPercentage = ((sold / (sold + unsold)) * 100).toFixed(1);
    const unsoldPercentage = ((unsold / (sold + unsold)) * 100).toFixed(1);
    return [
      { type: soldKey, value: sold, percentage: soldPercentage },
      { type: unsoldKey, value: unsold, percentage: unsoldPercentage }
    ];
  };

  return (
    <div className="chart-row centered">
      <div className="chart total-opp">
        <div className="chart-title">Total Opportunities Sold/Unsold</div>
        <div className="chart-content">
          <SingleChart
            chartType="PieChart"
            chartData={soldStatusSummeryChart}
            options={{
              statistic: {
                title: {
                  style: {
                    fontSize: '1.1rem',
                    fontWeight: '600'
                  },
                  customHtml: (container, view, datum, data) => {
                    //  const { width } = container.getBoundingClientRect();
                    const soldPercentage = data?.find(item => item.type === soldKey).percentage || 0;
                    return (
                      <>
                        <div>{`${soldPercentage}%`}</div>
                        <div>Sold</div>
                      </>
                    );
                  }
                },
                content: false
              },
              label: {
                type: 'spider',
                style: {
                  // textAlign: 'center',
                  fontWeight: 'bold'
                  //  fontSize: '0.9rem'
                },
                autoRotate: false,
                content: '{value}'
              }
            }}
            formatData={opportunitiesDataFormat}
          />
        </div>
      </div>
      <div className="chart unsold-summary">
        <div className="chart-title">Unsold Reason Summary %</div>
        <div className="chart-content">
          <SingleChart chartType="BarChart" chartData={notSoldReasonSummeryChart} sort="desc" />
        </div>
      </div>
      <div className="chart sold-summary">
        <div className="chart-title">Closed Opportunities by Engagement</div>
        <div className="chart-content">
          <SingleChart chartType="PieChart" chartData={closedOppByEngagementSummeryChart} />
        </div>
      </div>
      <div className="chart consumer-commit">
        <div className="chart-title">Customer Commitment Conveyed to the SC</div>
        <div className="chart-content">
          <SingleChart
            chartType="PieChart"
            chartData={customerCommitmentSummeryChart}
            options={{
              innerRadius: 0,
              label: {
                type: 'inner',
                offset: '-50%',
                style: {
                  textAlign: 'center',
                  //           fontWeight: 'bold',
                  fill: 'black'
                },
                autoRotate: false,
                content: ({ percent }) => `${(percent * 100).toFixed(1)}%`
              }
            }}
          />
        </div>
      </div>
      <div className="chart sold-summary">
        <div className="chart-title">Order Placement</div>
        <div className="chart-content">
          <SingleChart chartType="PieChart" chartData={orderPlacementSummeryChart} />
        </div>
      </div>
    </div>
  );
}
export default DashboardCharts;
