import initialState from 'store/initialState';
import { ASOH } from 'actions/actionTypes';

const asoh = (state = initialState.asoh, action) => {
  switch (action.type) {
    case ASOH.PENDING:
      return { ...state, fetching: true };
    case ASOH.SUCCESS:
      const { availableOnHand, nextReceiveDate } = action.payload;
      return { ...state, data: action.payload, fetching: false };
    case ASOH.FAILURE:
      return { ...state, fetching: false };
    default:
      return state;
  }
};

export default asoh;
