import _ from 'lodash';
const selectQuestions = state => _.get(state.questions, 'data', []);
const selectSelectedOppt = state => _.get(state.selectedOpportunity, 'data', []);
const selectAllFeedback = state => _.get(state.feedback, 'data', []);
const selectFeedbackUpdatedby = state => _.get(state.feedback, 'updatedBy', '-');
const selectFeedbackUpdatingStatus = state => _.get(state.feedback, 'isUpdating', false);

export {
  selectQuestions,
  selectSelectedOppt,
  selectAllFeedback,
  selectFeedbackUpdatingStatus,
  selectFeedbackUpdatedby
};
