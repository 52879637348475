import initialState from 'store/initialState';
import { CONVERSION_DETAILS } from 'actions/actionTypes';
import { createReducer } from 'reduxHelpers';

const conversionDetails = (state = initialState.conversionDetails, action) => {
  switch (action.type) {
    case CONVERSION_DETAILS.REQUEST:
      return { ...state, fetching: true, data: action.payload };
    case CONVERSION_DETAILS.SUCCESS:
      const { payload } = action;
      return { ...state, data: payload, fetching: false };
    default:
      return state;
  }
};

createReducer(CONVERSION_DETAILS, initialState.conversionDetails);

export default conversionDetails;
