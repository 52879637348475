import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Table, Select, Spin, InputNumber } from 'antd';
import { opptFilterList } from '../selectors/oppFilterList';
import {
  brokerTiesSelector,
  prcpPricingSelector,
  productInfoSelector,
  asohSelector,
  prcpPricingLoadingSelector,
  asohLoadingSelector,
  productInfoLoadingSelector,
  brokerTiesLoadingSelector
} from '../selectors/orderValidations';
import { useSelector, useDispatch } from 'react-redux';
import { selectOpportunityList } from '../selectors/brokerOpportunity';
import { PLACE_ORDER_FILTER_TYPE } from '../utils/Constants';
import { action } from '../reduxHelpers';
import {
  loadBrokerTies,
  retriveConversionDetails,
  retrieveOrderPrices,
  loadAsoh,
  loadProductInfo,
  loadList,
  loadCustomerNumsForSite,
  submitOrders
} from '../actions';
import { conversionDetails, selectIsConversionLoading } from '../selectors/conversionDetails';
import { useLocation, useHistory } from 'react-router-dom';
import { getLoggedUser } from '../Authenticator';
import {
  addNewConversionItem,
  validateAsohList,
  getSiteIdSelectOptions,
  validateProductInfoList,
  hasConversionItemsChanged,
  getError,
  isOrderErrorExists,
  getTotalPrice,
  getInputValue,
  getPackSize,
  getUnitPrice,
  getPlaceOrderRequestBody,
  setDetailsToConversionItem,
  validateSupcs
} from '../managers/pages/PlaceOrderLogic';
import { ordersUpdatingStatusSelector } from '../selectors/PlaceOrderSelectors';
import { selectTranches } from '../selectors/tranches';
import { selectCustomerNumsForSiteId } from 'selectors/customerNumsForSiteId';
import OpportunityFeedBackModel from './OpportunityFeedBackModel';

const PlaceOrder = props => {
  // selectors
  const opptFilters = useSelector(opptFilterList);
  const opptList = useSelector(selectOpportunityList);
  const conversions = useSelector(conversionDetails);
  const isConversionsLoading = useSelector(selectIsConversionLoading);
  const brokerTiesList = useSelector(brokerTiesSelector);
  const productInfo = useSelector(productInfoSelector);
  const asoh = useSelector(asohSelector);
  const prcpPricing = useSelector(prcpPricingSelector);
  const isPrcpPricingLoading = useSelector(prcpPricingLoadingSelector);
  const isOrdersSubmitting = useSelector(ordersUpdatingStatusSelector);
  const isAsohLoading = useSelector(asohLoadingSelector);
  const isProdInfoLoading = useSelector(productInfoLoadingSelector);
  const isBrokerTiesLoading = useSelector(brokerTiesLoadingSelector);
  const tranches = useSelector(selectTranches);
  const customerNumsForSiteId = useSelector(selectCustomerNumsForSiteId);

  // states
  const [siteFilters, setSiteFilters] = useState([]);
  const [customerNameFilters, setCustomerNameFilters] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [selectedBrokerName, setSelectedBrokerName] = useState('');
  const [selectedBrokerContact, setSelectedBrokerContact] = useState('');
  const [selectedCustomerNum, setSelectedCustomerNum] = useState('');
  const [selectedSalesConsultant, setSelectedSalesConsultant] = useState('');
  const [note, setNote] = useState('');
  const [conversionItems, setConversionItems] = useState([]);
  const [filteredOpptList, setFilteredOpptList] = useState([]);
  const [selectedOpportunityItem, setSelectedOpportunityItem] = useState(null);
  const [itemValidationErrors, setItemValidationErrors] = useState([]);
  const [productInfoList, setProductInfoList] = useState({});
  const [supcValidatedConversions, setSupcValidatedConversions] = useState([]);
  const [availableBrokerTies, setAvailableBrokerTies] = useState([]);
  const [asohList, setAsohList] = useState([]);
  const [validPricesRetrieved, setValidPricesRetrieved] = useState(false);
  const [productInfoValidatedConversions, setProductInfoValidatedConversions] = useState([]);
  const [conversionId, setConversionId] = useState(null);
  const [openFeedBackModel, setOpenFeedBackModel] = useState(false);

  // other constants
  const todayDate = new Date();
  const { Option } = Select;
  const history = useHistory();
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const requiredSupcLengths = [6, 7];
  let supcInputTimeout = null;
  // enable/disable split input and all the validations associated with it
  const enableSplit = false;
  const tableCols = [
    {
      title: 'SUPC',
      dataIndex: 'toItemNum',
      key: 'supc',
      width: '14rem',
      render: (value, record, index) => {
        const error = getError(record.key, 'supc', itemValidationErrors);
        return (
          <>
            <Input
              className={error?.showError ? 'input-error' : ''}
              defaultValue={getInputValue(conversionItems, record.key, 'toItemNum')}
              onChange={$event => tableInputOnBlurHandler($event.target.value, 'supc', record)}
            />
            {error?.showError && <label className="input-error-message">{error?.errorMessage}</label>}
          </>
        );
      }
    },
    {
      title: 'Order Qty',
      dataIndex: 'fromPack',
      key: 'case',
      width: '14rem',
      render: (value, record, index) => {
        const orderQtyError = getError(record.key, 'case', itemValidationErrors);
        return (
          <>
            <InputNumber
              defaultValue={getInputValue(conversionItems, record.key, 'fromPack')}
              className={orderQtyError?.showError ? 'input-error' : ''}
              onChange={$event => tableInputOnBlurHandler($event, 'case', record)}
              disabled={!record.isEnableCase}
              min={0}
            />
            {orderQtyError?.showError && <label className="input-error-message">{orderQtyError?.errorMessage}</label>}
          </>
        );
      }
    },
    {
      title: 'Split',
      dataIndex: 'split',
      key: 'split',
      width: '14rem',
      render: (value, record, index) => {
        const splitError = getError(record.key, 'split', itemValidationErrors);
        return (
          <>
            <InputNumber
              defaultValue={getInputValue(conversionItems, record.key, 'split')}
              className={splitError?.showError ? 'input-error' : ''}
              onChange={$event => tableInputOnBlurHandler($event, 'split', record)}
              disabled={!record.isEnableSplit && !enableSplit}
              min={0}
            />
            {splitError?.showError && <label className="input-error-message">{splitError?.errorMessage}</label>}
          </>
        );
      }
    },
    {
      title: 'Name',
      dataIndex: 'toItemDesc',
      key: 'name',
      render: value => (value ? value : '--')
    },
    {
      title: 'Brand Name',
      dataIndex: 'toBrandId',
      key: 'brandName',
      render: value => (value ? value : '--')
    },
    {
      title: 'Pack Size',
      dataIndex: 'packSize',
      key: 'packSize',
      render: (value, record, index) => getPackSize(record.toItemNum, asohList)
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (value, record, index) => {
        const totalPrice = getUnitPrice(record, prcpPricing);

        return <div>{totalPrice ? '$' + totalPrice.toFixed(2) : '--'}</div>;
      }
    },
    {
      title: 'Total Price',
      dataIndex: 'recommendedSellPrice',
      key: 'recommendedSellPrice',
      render: (value, record, index) => {
        const totalPrice = getTotalPrice(record, prcpPricing);

        return <div>{totalPrice ? '$' + totalPrice.toFixed(2) : '--'}</div>;
      }
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
      render: value => {
        return (
          <Button type="dashed" size="small" onClick={() => removeConversionItem(value)}>
            Delete
          </Button>
        );
      }
    }
  ];

  // get query params
  const params = useLocation().state;
  const { siteId, customerNum } = params;
  const siteIdParam = siteId;
  const customerNumParam = customerNum;

  useEffect(() => {
    const siteIdFilterOptions = getSiteIdSelectOptions(opptFilters);
    setSelectedSite(siteIdParam);
    setSelectedCustomerNum(customerNumParam);
    // load conversions
    setSiteFilters(siteIdFilterOptions);
    setFilteredOpptList([...opptList]);
    applyQueryParams(siteIdFilterOptions, opptList);
    dispatch(loadCustomerNumsForSite(siteIdParam));
  }, []);

  /**
   * init site dropdown options
   */
  useEffect(() => {
    const siteIdFilterOptions = getSiteIdSelectOptions(opptFilters);
    setFilteredOpptList([...opptList]);
    setSiteFilters(siteIdFilterOptions);
    applyQueryParams(siteIdFilterOptions, opptList);
  }, [opptFilters]);

  /**
   * set opp list for selected customer number.
   */

  useEffect(() => {
    if (opptList.length > 0) {
      setSelectedOpportunityItem(opptList[0]);
      setSelectedBrokerName(opptList[0].brokerName);
      setSelectedSalesConsultant(opptList[0].scName);
    }
  }, [opptList]);

  /**
   * apeend extra fields to conversion items to use in the table
   */
  useEffect(() => {
    if (conversions && conversions.data && conversions.data.length > 0 && selectedOpportunityItem) {
      const _conversions = [...conversions.data];
      _conversions.forEach((item, index) => {
        item['key'] = index + 1;
        item['operation'] = index + 1;
        item['isEnableCase'] = false;
        item['isEnableSplit'] = false;
        item['changed'] = true;
        item['supcChanged'] = true;
        item['caseChanged'] = true;
        item['splitChanged'] = true;
        item['fromPack'] = '';
        item['split'] = '';
        item['priceUpdateRequired'] = true;
        item.toItemNum = String(item.toItemNum).padStart(7, '0');
      });

      setConversionItems(_conversions);
      setConversionId(conversions.data[0].conversionId);

      // get broker ties
      requestBrokerTies(_conversions, availableBrokerTies);

      const { supcValidationErrors, supcValidConversionItems } = validateSupcs(
        _conversions,
        availableBrokerTies,
        itemValidationErrors,
        enableSplit,
        requiredSupcLengths
      );
      setItemValidationErrors(supcValidationErrors);
      setSupcValidatedConversions(supcValidConversionItems);
    }
  }, [conversions]);

  /**
   * perform validation on asoh fetch
   */
  useEffect(() => {
    if (asoh && asoh.products && asoh.products.length > 0) {
      const asohProductList = asoh.products;
      const _asohList = [...asohList];
      const _conversionItems = [...conversionItems];

      // add non-existing asoh items to the list
      asohProductList.forEach(item => {
        const isItemAvailable = asohList.some(
          _item => item.supc === _item.supc && item.splitIndicator === _item.splitIndicator
        );
        if (!isItemAvailable) {
          _asohList.push(item);
        }
      });

      initiateAsohValidation(_asohList, _conversionItems, itemValidationErrors);
      setAsohList(_asohList);
    }
  }, [asoh]);

  /**
   * performs below tasks after validating broker ties
   * request product info and perform product info validation for existing prodInfo items
   */
  useEffect(() => {
    if (supcValidatedConversions && supcValidatedConversions.length > 0) {
      requestProductInfo(supcValidatedConversions, productInfoList);
      const { validationErrors, prodInfoValidatedConversions, conversionItemsList } = validateProductInfoList(
        productInfoList,
        itemValidationErrors,
        conversionItems,
        supcValidatedConversions,
        enableSplit
      );
      setConversionItems(conversionItemsList);
      setItemValidationErrors(validationErrors);
      setProductInfoValidatedConversions(prodInfoValidatedConversions);
    }
  }, [supcValidatedConversions]);

  /**
   * perform asoh validation for product info valid items using existing asoh items
   */
  useEffect(() => {
    if (productInfoValidatedConversions && productInfoValidatedConversions.length > 0) {
      initiateAsohValidation(asohList, conversionItems, itemValidationErrors);
    }
  }, [productInfoValidatedConversions]);

  /**
   * perform prod info validation after fetching productInfo
   */
  useEffect(() => {
    if (productInfo) {
      const _productInfoList = { ...productInfoList, ...productInfo };
      setProductInfoList(_productInfoList);

      if (Object.keys(productInfo).length != 0) {
        const {
          validProdInfoItems,
          validationErrors,
          prodInfoValidatedConversions,
          conversionItemsList
        } = validateProductInfoList(
          _productInfoList,
          itemValidationErrors,
          conversionItems,
          supcValidatedConversions,
          enableSplit
        );
        setConversionItems(conversionItemsList);
        setItemValidationErrors(validationErrors);
        setProductInfoValidatedConversions(prodInfoValidatedConversions);

        requestAsoh(validProdInfoItems, asohList);
      }
    }
  }, [productInfo]);

  /**
   * populate SUPC dropdown options
   */
  useEffect(() => {
    if (brokerTiesList && brokerTiesList.length > 0) {
      const _availableBrokerTies = [...availableBrokerTies, ...brokerTiesList];
      setAvailableBrokerTies(_availableBrokerTies);

      // perform validations
      const _conversionItems = [...conversionItems];
      _conversionItems.forEach(convItem => (convItem.supcChanged = true));
      const { supcValidationErrors, supcValidConversionItems } = validateSupcs(
        _conversionItems,
        _availableBrokerTies,
        itemValidationErrors,
        enableSplit,
        requiredSupcLengths
      );
      _conversionItems.forEach(convItem => (convItem.supcChanged = false));
      setItemValidationErrors(supcValidationErrors);
      setSupcValidatedConversions(supcValidConversionItems);

      // set additional information to the conversion items
      brokerTiesList.forEach(brokerTieItem => {
        const conversionItem = _conversionItems.find(
          convItem => parseInt(convItem.toItemNum) === parseInt(brokerTieItem.itemId)
        );
        setDetailsToConversionItem(conversionItem, brokerTieItem);
      });

      setConversionItems(_conversionItems);
    }
  }, [brokerTiesList]);

  /**
   * set pricing updated status in conversion items
   */
  useEffect(() => {
    if (prcpPricing && prcpPricing.length > 0 && conversionItems && conversionItems.length > 0) {
      const _isValidPricingRetrieved = prcpPricing.every(item => item.statuses.every(status => status.code === 200));
      if (!_isValidPricingRetrieved) {
        dispatch(
          action('SHOW_NOTIFICATION', {
            description: 'There is an error in retrieving prices. Please try again!',
            className: 'error',
            message: 'PRICES RETRIEVING ERROR'
          })
        );
        setValidPricesRetrieved(false);
        return;
      }

      const _conversionItems = [...conversionItems];
      _conversionItems.forEach(convItem => {
        const pricingItem = prcpPricing.find(item => item.supc === String(convItem.toItemNum).padStart(7, '0'));

        if (pricingItem) {
          convItem.priceUpdateRequired = false;
        }
      });

      setValidPricesRetrieved(true);
      setConversionItems(_conversionItems);
    }
  }, [prcpPricing]);

  /**
   * set loaded customer nums
   */
  useEffect(() => {
    if (customerNumsForSiteId && customerNumsForSiteId.data && customerNumsForSiteId.siteId) {
      setCustomerNameFilters(customerNumsForSiteId.data);

      const siteIdFilterOptions = getSiteIdSelectOptions(opptFilters);
      applyQueryParams(siteIdFilterOptions, opptList);
    }
  }, [customerNumsForSiteId]);

  const getOpportunityForCustomer = customerNum => {
    const filters = {};
    if (customerNum) {
      filters['customerNum'] = customerNum;
    }
    dispatch(loadList({ filters }));
  };

  /**
   * performs asoh vaidation for given list
   * @param {*} _asohList
   * @param {*} _conversionItems
   * @param {*} _itemValidationErrors
   */
  const initiateAsohValidation = (_asohList, _conversionItems, _itemValidationErrors) => {
    // set changed status to true
    _conversionItems.forEach(convItem => {
      if (convItem.isEnableCase) {
        convItem.caseChanged = true;
      }
      if (convItem.isEnableSplit && enableSplit) {
        convItem.splitChanged = true;
      }
    });

    const { asohValidationErrors } = validateAsohList(_asohList, _conversionItems, _itemValidationErrors, enableSplit);
    setItemValidationErrors(asohValidationErrors);

    // set changed status back to false
    _conversionItems.forEach(convItem => {
      convItem.caseChanged = false;
      convItem.splitChanged = false;
    });
  };

  /**
   * sends asoh request
   * @param {*}
   */
  const requestAsoh = (validProdInfos, availableAsohList) => {
    const itemsToRequest = [];

    validProdInfos.forEach(prodInfoItem => {
      const isItemExistsInAsohList = availableAsohList.some(
        asohItem => parseInt(asohItem.supc) === parseInt(prodInfoItem.id)
      );

      if (!isItemExistsInAsohList) {
        itemsToRequest.push({ supc: prodInfoItem.id, splitFlag: false });

        if (prodInfoItem.isSplit && enableSplit) {
          itemsToRequest.push({ supc: prodInfoItem.id, splitFlag: true });
        }
      }
    });

    if (itemsToRequest.length > 0) {
      dispatch(loadAsoh({ businessUnitNumber: selectedSite, products: itemsToRequest }));
    }
  };

  /**
   * sends request to load broker ties
   * @param {*} _conversionItems
   * @param {*} _availableBrokerTies
   */
  const requestBrokerTies = (_conversionItems, _availableBrokerTies) => {
    _conversionItems.forEach(conv => {
      // only ties which are not available in array, will be fetched
      const isBrokerTieAlreadyAvailable = _availableBrokerTies.some(
        _item => parseInt(_item.itemId) === parseInt(conv.toitemNum)
      );
      if (!isBrokerTieAlreadyAvailable && conv.toItemNum) {
        dispatch(loadBrokerTies({ opcoId: selectedSite, itemId: conv.toItemNum }));
      }
    });
  };

  /**
   * retrieve logged in user's email
   */
  const getLoggedInUserData = async () => {
    const username = await getLoggedUser();
    setSelectedBrokerContact(username.replace('okta_', ''));
  };

  /**
   * if query params are exists, then load the conversions using siteId and customerNum
   */
  const applyQueryParams = (siteIdFilters, opportunityList) => {
    if (
      selectedSite &&
      selectedCustomerNum &&
      siteIdFilters &&
      siteIdFilters.length > 0 &&
      opportunityList &&
      opportunityList.length > 0
    ) {
      const siteIdFilteredOpptList = opptList.filter(item => item.siteId === selectedSite);
      const _customerNum = selectedCustomerNum;

      applyFilters(PLACE_ORDER_FILTER_TYPE.SITE, { _siteId: selectedSite, _filteredOpptList: opportunityList });

      if (customerNumsForSiteId && customerNumsForSiteId.data) {
        const isCustomerNumExists = customerNumsForSiteId.data.some(item => item.customerNum === _customerNum);
        if (isCustomerNumExists) {
          applyFilters(PLACE_ORDER_FILTER_TYPE.CUSTOMER_NUM, {
            _siteId: selectedSite,
            _customerNum: _customerNum,
            _filteredOpptList: siteIdFilteredOpptList
          });
        }
      }
    }
  };

  /**
   * add new item into the table
   */
  const addConversionItem = () => {
    const _conversionItems = addNewConversionItem(conversionItems);
    setConversionItems(_conversionItems);
  };

  /**
   * remove item from table
   * @param {*} key
   */
  const removeConversionItem = key => {
    const filteredItems = conversionItems.filter(item => item.key != key);
    setConversionItems(filteredItems);
    const errors = itemValidationErrors.filter(item => item.key !== key);
    setItemValidationErrors(errors);
  };

  /**
   * filter conversions when applying the filters
   * @param {*} filterType PLACE_ORDER_FILTER_TYPE
   * @param {*} filterParams {_siteId, _customerNum, _filteredOpptList}
   */
  const applyFilters = (filterType, filterParams) => {
    const { _siteId, _customerNum, _filteredOpptList } = filterParams;

    const tempFilteredOpptList = _filteredOpptList ? _filteredOpptList : filteredOpptList;

    switch (filterType) {
      case PLACE_ORDER_FILTER_TYPE.SITE:
        // clear selected customer num
        if (_siteId !== selectedSite) {
          setSelectedCustomerNum(null);
          setSelectedBrokerName(null);
          setSelectedSalesConsultant(null);
          setConversionItems([]);
          setSupcValidatedConversions([]);
          setItemValidationErrors([]);
          setAvailableBrokerTies([]);
          setConversionId(null);
          //load customer numbers after reset
        }

        const _siteIdFilteredOpptList = tempFilteredOpptList.filter(item => item.siteId === _siteId);
        setSelectedSite(_siteId);
        setFilteredOpptList(_siteIdFilteredOpptList);

        if (customerNumsForSiteId && customerNumsForSiteId.siteId !== _siteId) {
          dispatch(loadCustomerNumsForSite(_siteId));
        }

        break;

      case PLACE_ORDER_FILTER_TYPE.CUSTOMER_NUM:
        if (!selectedSite && !_siteId) {
          dispatch(
            action('SHOW_NOTIFICATION', {
              description: 'Please select the Site!',
              className: 'error',
              message: 'SITE IS MISSING'
            })
          );
          break;
        }

        const _customerFilteredOpptList = tempFilteredOpptList.filter(item => item.customerNum === _customerNum);

        if (_customerFilteredOpptList.length >= 0 && _customerNum !== selectedCustomerNum) {
          setConversionItems([]);
          setSupcValidatedConversions([]);
          setSelectedOpportunityItem(
            _customerFilteredOpptList.length > 0 ? _customerFilteredOpptList[0] : tempFilteredOpptList[0]
          );
          setSelectedBrokerName(
            _customerFilteredOpptList.length > 0
              ? _customerFilteredOpptList[0].brokerName
              : tempFilteredOpptList[0].brokerName
          );
          setSelectedSalesConsultant(
            _customerFilteredOpptList.length > 0 ? _customerFilteredOpptList[0].scName : tempFilteredOpptList[0].scName
          );
          setItemValidationErrors([]);
          getLoggedInUserData();
          loadConversionItems(_customerNum);
          setConversionId(null);
          getOpportunityForCustomer(_customerNum);
        } else {
          getOpportunityForCustomer(_customerNum);
          loadConversionItems(_customerNum);
          getLoggedInUserData();
          if (_customerFilteredOpptList.length > 0) {
            setSelectedOpportunityItem(_customerFilteredOpptList[0]);
            setSelectedBrokerName(_customerFilteredOpptList[0].brokerName);
            setSelectedSalesConsultant(_customerFilteredOpptList[0].scName);
            getLoggedInUserData();
          }
        }
        setSelectedCustomerNum(_customerNum);
        break;
    }
  };

  /**
   * retrieve conversion item for customer number
   * @param {*} customerNum
   */
  const loadConversionItems = customerNum => {
    if (customerNum) {
      dispatch(retriveConversionDetails({ custNo: customerNum }));
    }
  };

  /**
   * handles frequent table inputs
   * @param {*} inputValue
   * @param {*} inputKey
   * @param {*} conversionItem
   */
  const tableInputOnBlurHandler = (inputValue, inputKey, conversionItem) => {
    // since antd input doesn't allow onBlur event,
    // we use a timeout to controll frequent input updates
    clearTimeout(supcInputTimeout);
    supcInputTimeout = setTimeout(() => {
      setInputValueToConversionItem(inputKey, inputValue, conversionItem.key);
    }, 500);
  };

  /**
   * set the input value to the conversion item
   * @param {*} key
   * @param {*} inputValue
   * @param {*} conversionItemKey
   */
  const setInputValueToConversionItem = (key, inputValue, conversionItemKey) => {
    const _conversionItems = [...conversionItems];
    const conversionItemIndex = _conversionItems.findIndex(
      _conversionItem => _conversionItem.key === conversionItemKey
    );

    _conversionItems.forEach(item => (item.supcChanged = false));

    const conversionItem = _conversionItems[conversionItemIndex];

    switch (key) {
      case 'supc':
        conversionItem.toItemNum = inputValue;

        // Mark conversion item as changed, It only will be validated
        conversionItem.supcChanged = true;

        if (conversionItem.toItemNum && (inputValue.length === 6 || inputValue.length === 7)) {
          const brokerTieItem = availableBrokerTies.find(
            brokerTie => brokerTie.itemId === conversionItem.toItemNum.padStart(7, '0')
          );

          if (!brokerTieItem) {
            dispatch(loadBrokerTies({ opcoId: selectedSite, itemId: inputValue }));
          }

          // set additional details to the item, if there is a match
          setDetailsToConversionItem(conversionItem, brokerTieItem);
        }

        const { supcValidationErrors, supcValidConversionItems } = validateSupcs(
          _conversionItems,
          availableBrokerTies,
          itemValidationErrors,
          enableSplit,
          requiredSupcLengths
        );
        setItemValidationErrors(supcValidationErrors);
        setSupcValidatedConversions(supcValidConversionItems);

        // Change the 'changed' attribute back to false
        conversionItem.supcChanged = false;
        break;

      case 'case':
        conversionItem.fromPack = inputValue;

        _conversionItems.forEach((convItm, index) => {
          if (index === conversionItemIndex) {
            // Mark conversion item as changed, It only will be validated
            convItm.caseChanged = true;
            convItm.splitChanged = false;
          } else {
            convItm.caseChanged = false;
            convItm.splitChanged = false;
          }
        });

        const asohCaseValidationObj = validateAsohList(asohList, _conversionItems, itemValidationErrors, enableSplit);
        setItemValidationErrors(asohCaseValidationObj.asohValidationErrors);

        // Change the 'changed' attribute back to false
        conversionItem.caseChanged = false;
        break;

      case 'split':
        conversionItem.split = inputValue;

        _conversionItems.forEach((convItm, index) => {
          if (index === conversionItemIndex) {
            // Mark conversion item as changed, It only will be validated
            convItm.caseChanged = false;
            convItm.splitChanged = true;
          } else {
            convItm.caseChanged = false;
            convItm.splitChanged = false;
          }
        });

        const asohSplitValidationObj = validateAsohList(asohList, _conversionItems, itemValidationErrors, enableSplit);
        setItemValidationErrors(asohSplitValidationObj.asohValidationErrors);

        // Change the 'changed' attribute back to false
        conversionItem.casesplitChangedChanged = false;
        break;
    }
    _conversionItems[conversionItemIndex] = conversionItem;
    conversionItem.priceUpdateRequired = true;

    setConversionItems(_conversionItems);
  };

  /**
   * reset form fields
   */
  const resetForm = () => {
    setSelectedSite('');
    setSelectedCustomerNum('');
    setSelectedBrokerName('');
    setSelectedBrokerContact('');
    setSelectedSalesConsultant('');
    setConversionItems([]);
    setFilteredOpptList([]);
    setSelectedOpportunityItem(null);
    setCustomerNameFilters([]);
    setNote('');
    setItemValidationErrors([]);
  };

  /**
   * submit form
   */
  const submitForm = () => {
    // close model
    setOpenFeedBackModel(false);

    // if everything is good, submit to API
    const requestBody = getPlaceOrderRequestBody({
      _conversionItems: conversionItems,
      _note: note
        .trim()
        .split(/ +/)
        .join(' '),
      _prcpPricingArray: prcpPricing,
      tranches,
      customerNum: selectedCustomerNum,
      brokerContact: selectedBrokerContact,
      site: selectedSite
    });
    dispatch(submitOrders(requestBody, goBackToOpportunitiesPage));
  };

  /**
   * loads pricing from pricing API
   */
  const refreshPrices = () => {
    // check if case or split is filled in all items
    for (const conversionItem of conversionItems) {
      if (
        !conversionItem.toItemNum ||
        conversionItem.toItemNum === '' ||
        !(conversionItem.fromPack || conversionItem.split)
      ) {
        dispatch(
          action('SHOW_NOTIFICATION', {
            description: 'Please fill SUPC, Order Qty of all the items!',
            className: 'error',
            message: 'SUPC, CASE/SPLIT VALUES ARE REQUIRED'
          })
        );
        return;
      }
    }

    const priceRequestDateStr = `${todayDate.getFullYear()}${String(todayDate.getMonth() + 1).padStart(2, '0')}${String(
      todayDate.getDate()
    ).padStart(2, '0')}`;

    const products = [];
    conversionItems.forEach(convItem => {
      products.push({ supc: String(convItem.toItemNum), splitFlag: false, quantity: parseInt(convItem.fromPack) });

      if (convItem.split && convItem.split > 0) {
        products.push({ supc: String(convItem.toItemNum), splitFlag: true, quantity: parseInt(convItem.split) });
      }
    });

    const requestBody = {
      businessUnitNumber: selectedSite,
      customerAccount: selectedCustomerNum,
      priceRequestDate: priceRequestDateStr,
      products: products
    };

    dispatch(retrieveOrderPrices(requestBody));
  };

  /**
   * load the product info for valid supc items
   * @param {*} supcValidatedConvItems
   */
  const requestProductInfo = (supcValidatedConvItems, prodInfoList) => {
    if (supcValidatedConvItems && supcValidatedConvItems.length > 0 && selectedSite && selectedCustomerNum) {
      const availableProdInfoIds = Object.keys(prodInfoList).map(item => parseInt(item));
      const nonAvailableItems = supcValidatedConvItems.filter(
        convItem => !availableProdInfoIds.includes(parseInt(convItem.toItemNum))
      );

      const supcs = nonAvailableItems.map(item => {
        return parseInt(item.toItemNum);
      });

      if (supcs.length > 0) {
        dispatch(loadProductInfo({ siteNo: selectedSite, supcs, customerNum: selectedCustomerNum }));
      }
    }
  };

  /**
   * trigger browser back
   */
  const goBackToOpportunitiesPage = () => {
    history.goBack();
  };

  const closeFeedBackModel = () => {
    dispatch(
      action('SHOW_NOTIFICATION', {
        description: 'Please provide feedback!',
        className: 'error',
        message: 'Feedback Required'
      })
    );
    setOpenFeedBackModel(false);
  };

  const openFeedbackForm = () => {
    // check if any errors exists
    const isErrorExists = isOrderErrorExists(itemValidationErrors);
    if (isErrorExists) {
      dispatch(
        action('SHOW_NOTIFICATION', {
          description: 'Please fix the errors and try again!',
          className: 'error',
          message: 'PLEASE CORRECT THE ERRORS'
        })
      );
      return;
    }

    // check if price update is required
    const isConversionsChanged = hasConversionItemsChanged(conversionItems);
    if (isConversionsChanged || !validPricesRetrieved) {
      dispatch(
        action('SHOW_NOTIFICATION', {
          description: 'Please refresh the prices and try again!',
          className: 'error',
          message: 'PLEASE REFRESH THE PRICES'
        })
      );
      return;
    }
    setOpenFeedBackModel(true);
  };

  return (
    <section>
      <div className="place-order-page">
        <div className="grid-filter-panel">
          <div
            className="back-to-dashboard"
            title="Go Back to Conversion Opportunities"
            onClick={goBackToOpportunitiesPage}
          ></div>
          <div className="title">Place Order</div>
        </div>

        <div className="filterbar">
          <div className="place-order-form-wrapper">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <Row>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Form.Item
                    className="required"
                    label="Site"
                    rules={[{ required: true, message: 'Please input the Site!' }]}
                  >
                    <Select
                      onChange={$event =>
                        applyFilters(PLACE_ORDER_FILTER_TYPE.SITE, { _siteId: $event, _filteredOpptList: opptList })
                      }
                      style={{ float: 'left' }}
                      value={selectedSite}
                      filterOption={(input, option) => {
                        return option.children.toLowerCase().includes(input.toLowerCase());
                      }}
                      showSearch
                    >
                      {siteFilters &&
                        siteFilters.map(item => (
                          <Option value={item.siteId} key={item.siteId}>{`${item.siteId} - ${item.siteName}`}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Form.Item label="Today’s Date">
                    <b>{`${todayDate.getFullYear()}-${String(todayDate.getMonth() + 1).padStart(2, '0')}-${String(
                      todayDate.getDate()
                    ).padStart(2, '0')}`}</b>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Form.Item
                    label="Customer Name & Num"
                    className="required"
                    rules={[{ required: true, message: 'Please input the Customer Name!' }]}
                  >
                    <Select
                      onChange={$event =>
                        applyFilters(PLACE_ORDER_FILTER_TYPE.CUSTOMER_NUM, {
                          _customerNum: $event,
                          _filteredOpptList: opptList
                        })
                      }
                      value={selectedCustomerNum}
                      filterOption={(input, option) => {
                        return option.children.toLowerCase().includes(input.toLowerCase());
                      }}
                      showSearch
                    >
                      {customerNameFilters &&
                        customerNameFilters.map(item => (
                          <Option
                            value={item.customerNum}
                            key={item.customerNum}
                          >{`${item.customerNum} - ${item.customerName}`}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Form.Item label="Broker Name">
                    <Input value={selectedBrokerName} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Form.Item label="Sales Consultant">
                    <Input value={selectedSalesConsultant} disabled />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Form.Item label="Broker Contact">
                    <Input value={selectedBrokerContact} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Form.Item
                    label="Delivery Method"
                    rules={[{ required: true, message: 'Please input Delivery Method!' }]}
                  >
                    <div>
                      <b>Ship with next</b>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Form.Item label="Note">
                    <TextArea onChange={$event => setNote($event.target.value)} value={note} rows={4} maxLength={300} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>

        <div className="filterbar add-item-wrapper">
          <Spin
            spinning={
              isConversionsLoading ||
              isPrcpPricingLoading ||
              isOrdersSubmitting ||
              isAsohLoading ||
              isProdInfoLoading ||
              isBrokerTiesLoading
            }
          >
            <div className={`table-control-btn-row`}>
              <Button type="primary" onClick={addConversionItem} disabled={selectedOpportunityItem === null}>
                Add Item
              </Button>
              <div className={hasConversionItemsChanged(conversionItems) || !validPricesRetrieved ? '' : 'disabled'}>
                <Button type="primary" onClick={refreshPrices} disabled={selectedOpportunityItem === null}>
                  Refresh Prices
                </Button>
              </div>
            </div>
            <div className="add-item-table">
              <Table
                dataSource={conversionItems}
                columns={tableCols}
                pagination={{
                  showTotal: total => (
                    <div className="submit-btn-wrapper">
                      <Button
                        className="btn green-action-btn"
                        type="primary"
                        disabled={isOrdersSubmitting}
                        onClick={openFeedbackForm}
                      >
                        Submit
                      </Button>
                      <Button className="btn" onClick={resetForm}>
                        Reset
                      </Button>
                    </div>
                  )
                }}
              />
            </div>
          </Spin>
        </div>
      </div>
      <OpportunityFeedBackModel
        openFeedBackMode={openFeedBackModel}
        onClose={closeFeedBackModel}
        customerData={params}
        done={submitForm}
        conversionItems={conversionItems}
      />
    </section>
  );
};
export default PlaceOrder;
