const selectFeedbackReportStatus = state => state.feedbackReport.status;
const selectFeedbackReportUrl = state => state.feedbackReport.url;
const selectFeedbackReportFileName = state => state.feedbackReport.fileName;
const selectFeedbackReportExpiryTime = state => state.feedbackReport.expiredTime;

export {
  selectFeedbackReportStatus,
  selectFeedbackReportUrl,
  selectFeedbackReportFileName,
  selectFeedbackReportExpiryTime
};
