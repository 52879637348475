import { FEEDBACK_COMPLETION_STATUS } from 'utils/Constants';

/**
 * check whether question is required or not
 * @param {questionId} questionId
 * @returns boolean
 */
const isRequiredQuestion = (questionId, requiredQuestions) => {
  const qIndex = requiredQuestions.findIndex(item => item.questionId === questionId);
  if (qIndex >= 0) {
    return requiredQuestions[qIndex].required;
  }
  return false;
};

/**
 * return a question object by using question's Id
 * @param {questionId} questionId
 * @returns question object
 */
const getQuestionById = (questionId, questions) => {
  if (questions && questions.length > 0) {
    for (let qSet of questions) {
      for (let question of qSet.questions) {
        if (question.questionId === questionId) {
          return question;
        }
      }
    }
  }

  return null;
};

/**
 * Returns answer object
 * @param {ID of the question} questionId
 * @returns given answer with questionId
 */
const getAnswerById = (questionId, allFeedbacks) => {
  if (allFeedbacks && allFeedbacks.length > 0) {
    for (let feedback of allFeedbacks) {
      if (feedback && feedback.questionId === questionId) {
        return feedback;
      }
    }
  }
  return null;
};

/**
 * returns form completion status by considering question dependencies
 */
const getFormCompletionStatus = (questions, allFeedbacks) => {
  const allParentQuestions = getAllParentQuestions(questions);

  //Sold status is completed when Sold Status is 'Yes by Broker','Yes with SC Engaged' or 'No'.
  //Sold status is partially completed(in progress) when 'Testing in Progress'

  const soldStatusQuestion = allParentQuestions.filter(a => a.questionId === 20);
  const completedAnswers = ['Yes by Broker', 'Yes with SC Engaged', 'No'];

  const feedbackCompleted = soldStatusQuestion.every(question => {
    let answer = getAnswerById(question.questionId, allFeedbacks);
    return answer.answer && answer.answer.length > 0 && completedAnswers.includes(answer.answer);
  });

  if (feedbackCompleted) {
    return FEEDBACK_COMPLETION_STATUS.COMPLETED;
  } else {
    const feedbackPartiallyCompleted = soldStatusQuestion.every(question => {
      let answer = getAnswerById(question.questionId, allFeedbacks);
      return answer.answer && answer.answer.length > 0 && answer.answer == 'Testing in Progress';
    });

    if (feedbackPartiallyCompleted) {
      return FEEDBACK_COMPLETION_STATUS.PARTIALLY_COMPLETED;
    } else {
      return FEEDBACK_COMPLETION_STATUS.NOT_STARTED;
    }
  }
};

/**
 * returns all parent questions
 * if a question does not appear as a dependency, those questions are parent questions
 */
const getAllParentQuestions = questions => {
  let _allQuestions = getAllQuestionsArray(questions);
  let parentQuestions = [..._allQuestions];
  _allQuestions.forEach(question => {
    if (question && question.dependencies && question.dependencies.length > 0) {
      question.dependencies.forEach(dependencyArray => {
        if (dependencyArray && dependencyArray.length > 0) {
          dependencyArray.forEach(qId => {
            parentQuestions = parentQuestions.filter(_question => _question?.questionId != qId);
          });
        }
      });
    }
  });
  return parentQuestions;
};

/**
 * returns all questions as one array
 * @returns questions array
 */
const getAllQuestionsArray = questions => {
  const allQuestions = [];
  if (questions && questions.length > 0) {
    questions.forEach(qSet => {
      if (qSet && qSet.questions && qSet.questions.length > 0) {
        qSet.questions.forEach(question => {
          allQuestions.push(question);
        });
      }
    });
  }
  return allQuestions;
};

/**
 * returns the parent question of a child question by using child question's questionId
 * @param { child questionId} questionId
 * @returns
 */
const getQuestionsParentQuestionById = (questionId, questions) => {
  const allQuestions = getAllQuestionsArray(questions);

  for (const question of allQuestions) {
    const parentQuestionInfo = getParentQuestionInfo(question, questionId);

    if (parentQuestionInfo) {
      return parentQuestionInfo;
    }
  }

  return null;
};

/**
 * search for the given question ID to find its parent question
 * @param {*} question
 * @param {*} questionId
 * @returns
 */
const getParentQuestionInfo = (question, childQuestionId) => {
  if (!question || !question.dependencies || question.dependencies.length === 0) return null;

  for (let i = 0; i < question.dependencies.length; i++) {
    for (const dependenctId of question.dependencies[i]) {
      if (dependenctId == childQuestionId) {
        return {
          matchingParentAnswer: question.answers[i],
          matchingParentAnswerIndex: i,
          matchingParentQuestion: question
        };
      }
    }
  }
  return null;
};

/**
 * Removes validation error objects from validationErrors state array
 * @param {ID of the question} questionId
 */
const removeValidationError = (question, answer, validationErrorsArray) => {
  validationErrorsArray = validationErrorsArray.filter(item => item.questionId !== question.questionId);

  // remove validation errors of child questions
  if (question.answers && question.answers.length > 0 && question.dependencies && question.dependencies.length > 0) {
    const answerIndex = question.answers.findIndex(_answer => _answer === answer);
    if (answerIndex > -1 && question.dependencies[answerIndex] && question.dependencies[answerIndex].length > 0) {
      const childQuestionsIds = question.dependencies[answerIndex];
      childQuestionsIds.forEach(questionId => {
        validationErrorsArray = validationErrorsArray.filter(item => item.questionId != questionId);
      });
    }
  }
  return validationErrorsArray;
};

/**
 * get errors for required questions
 * @param {*} requiredQuestions
 * @returns
 */
const getRequiredQuestionErrors = (requiredQuestions, allFeedbacks) => {
  const _validationErrors = [];

  if (requiredQuestions && requiredQuestions.length > 0) {
    requiredQuestions.forEach(reqQs => {
      const { questionId } = reqQs;
      const answer = getAnswerById(questionId, allFeedbacks);
      if (!answer || !answer.answer || answer.answer.length === 0) {
        _validationErrors.push({ questionId, showError: true, errorMsg: 'Valid feedback is required.' });
      }
    });
  }
  return _validationErrors;
};

/**
 * validation is performed by considering parent, child relation using dependecies.
 * @returns array of error objects
 */
const getParentChildCrossValidationErrors = (questions, allFeedbacks) => {
  /**
   * check if given answers are related with the question dependecies
   *
   * SCENARIO:
   *  question 2 should be answered only when question 1 answer is 'Yes'.
   *  but user has selected 'No' to question 1
   *  in this case question 2 should not be answered
   **/

  let _crossValidationErrors = [];

  if (!questions || questions.length === 1 || !allFeedbacks || allFeedbacks.length === 1) {
    return _crossValidationErrors;
  }

  allFeedbacks.forEach(feedback => {
    const { questionId, answer } = feedback;
    let parentQuestion = null;
    if (questionId && answer && answer.length > 0) {
      // check if questionId as a dependecy of questions
      parentQuestion = getQuestionsParentQuestionById(questionId, questions);
    }

    if (parentQuestion) {
      const { matchingParentAnswer, matchingParentAnswerIndex, matchingParentQuestion } = parentQuestion;

      // match whether the dependency is valid or not
      const answerGivenForTheParentQuestion = getAnswerById(matchingParentQuestion.questionId, allFeedbacks);
      if (!answerGivenForTheParentQuestion) {
        _crossValidationErrors.push({
          questionId,
          showError: true,
          errorMsg: `Invalid feedback. You have not selected "${matchingParentAnswer}" for question ${matchingParentQuestion.questionNum}.`
        });
      } else {
        const indexOfGivenAnswerForParentQuestion = matchingParentQuestion.answers.findIndex(
          item => item === answerGivenForTheParentQuestion.answer
        );
        if (indexOfGivenAnswerForParentQuestion !== matchingParentAnswerIndex) {
          _crossValidationErrors.push({
            questionId,
            showError: true,
            errorMsg: `Invalid feedback. You have not selected "${matchingParentAnswer}" for question ${matchingParentQuestion.questionNum}.`
          });
        }
      }
    }
  });

  return _crossValidationErrors;
};

/**
 * get validation error message from validation errors
 * @param {questionId} questionId
 * @returns
 */
const getValidationErrorMessageById = (questionId, validationErrors) => {
  const validationError = getValidationErrorById(questionId, validationErrors);
  if (validationError && validationError.showError && validationError.errorMsg && validationError.errorMsg.length > 0) {
    return validationError.errorMsg;
  }
  return null;
};

/**
 * returns validation error object, if an error exists in validationErrors state array
 * @param {questionId} questionId
 * @returns validation error object
 */
const getValidationErrorById = (questionId, validationErrors) => {
  for (let validationError of validationErrors) {
    if (validationError && validationError.questionId === questionId) {
      return validationError;
    }
  }
  return null;
};

/**
 * performs form validation
 * @returns whether form in valid or not
 */
const performValidation = (questions, requiredQuestions, allFeedbacks) => {
  const _validationErrors = getRequiredQuestionErrors(requiredQuestions);

  const _crossValidationErrors = getParentChildCrossValidationErrors(questions, allFeedbacks);
  _validationErrors.push(..._crossValidationErrors);
  return _validationErrors;
};

/**
 * return a modified questions array by using questions and modifications
 * @returns question array
 */
const getModifiedQuestions = (questions, modifications) => {
  return questions.map(qSet => {
    const modifiedQSet = qSet.questions.map(question => {
      const modification = modifications.find(item => item.questionId === question.questionId);
      if (modification) {
        return { ...question, ...modification };
      }
      return question;
    });
    return { ...qSet, questions: modifiedQSet };
  });
};

export {
  isRequiredQuestion,
  getQuestionById,
  getAnswerById,
  getFormCompletionStatus,
  getAllParentQuestions,
  getAllQuestionsArray,
  getQuestionsParentQuestionById,
  getParentQuestionInfo,
  removeValidationError,
  getRequiredQuestionErrors,
  getParentChildCrossValidationErrors,
  getValidationErrorMessageById,
  getValidationErrorById,
  performValidation,
  getModifiedQuestions
};
