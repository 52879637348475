import { put } from 'redux-saga/effects';
import { getRequest, postRequest } from '_http';
import { CREATE_ORDER_SUMMERY_REPORT, DASHBOARD_ORDER_SUMMERY_TABLE } from 'actions/actionTypes';

import { action } from 'reduxHelpers';

function* loadOrderSummeryAsync(payload) {
  try {
    const { broker, tranche, pageSize, currentPage, body, sortParams, allBrokers } = payload;
    const response = yield postRequest(
      `/oppt/opp-dashboard/orderSummary?broker-code=${broker}&tranche=${tranche}&page-size=${pageSize}&current-page=${currentPage}&sort-params=${sortParams}&all-brokers=${allBrokers}`,
      body
    );
    yield put({ type: DASHBOARD_ORDER_SUMMERY_TABLE.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DASHBOARD_ORDER_SUMMERY_TABLE.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ORDER SUMMERY TABLE LOADING ERROR'
      })
    );
  }
}

export function* loadOrderSummeryReportAsync(payload) {
  try {
    const { broker, tranche, sortParams, filterParams, allBrokers } = payload;
    const response = yield getRequest(
      `/oppt/reports/order-summary?broker-code=${broker}&tranche=${tranche}&sort-params=${encodeURIComponent(
        JSON.stringify(sortParams)
      )}&filter-params=${encodeURIComponent(JSON.stringify(filterParams))}&all-brokers=${allBrokers}`
    );
    yield put({ type: CREATE_ORDER_SUMMERY_REPORT.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CREATE_ORDER_SUMMERY_REPORT.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ORDER SUMMERY TABLE REPORT DOWNLOAD ERROR'
      })
    );
  }
}

export default loadOrderSummeryAsync;
