import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Radio } from 'antd';
import { selectTotalOpportunities } from '../selectors/brokerOpportunity';
import { selectTranches } from '../selectors/tranches';
import { USER_TYPE_ASSOCIATE_PERMISSION, USER_TYPE_ASSOCIATE_ROLE, USER_TYPE_BROKER_ROLE } from '../utils/Constants';
import userDetails from '../selectors/userDetailsSelector';

const TABLEAU_REPORT_URL = process.env.REACT_APP_TABLEAU_REPORT_URL;

function DefaultLayout(props) {
  const totalOpportunities = useSelector(selectTotalOpportunities);
  const tranches = useSelector(selectTranches);
  const loadUserDetails = useSelector(userDetails);

  const history = useHistory();
  const location = useLocation();
  const tabItems = [
    {
      label: 'View Opportunities',
      key: '/suite/brokeroppt',
      subtitle: 'Welcome Back. Please select your market and/or region or site below to see your opportunities.'
    },
    {
      label: 'Dashboard',
      key: '/suite/brokeroppt/dashboard',
      subtitle: 'View a summary of broker activity.'
    }
  ];

  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const getActiveTab = () => {
    return tabItems.find(item => item.key === location.pathname);
  };
  const onChange = key => {
    history.push(key);
  };

  useEffect(() => {
    if (
      loadUserDetails?.roles?.includes(USER_TYPE_ASSOCIATE_ROLE) &&
      loadUserDetails?.permissions?.includes(USER_TYPE_ASSOCIATE_PERMISSION)
    ) {
      setReadOnlyMode(true);
    } else if (loadUserDetails?.roles?.includes(USER_TYPE_BROKER_ROLE)) {
      setReadOnlyMode(false);
    }
  }, [loadUserDetails]);

  const navigateToTableauReport = () => {
    window.open(TABLEAU_REPORT_URL);
  };

  return (
    <>
      <div className="grid-filter-panel">
        <div
          className="back-to-dashboard"
          onClick={() => {
            window.location.href = '/suite/dashboard';
          }}
        ></div>
        <div className="bread-crum-seperator"></div>
        <div className="title">Conversion Opportunities</div>
        <Radio.Group
          onChange={e => onChange(e.target.value)}
          value={getActiveTab()?.key || ''}
          optionType="button"
          buttonStyle="solid"
          defaultValue="dashboard"
          className="home-nav"
        >
          {tabItems.map(tab => (
            <Radio.Button key={tab.key} value={tab.key}>
              {tab.label}
            </Radio.Button>
          ))}
        </Radio.Group>
        {getActiveTab()?.key === '/suite/brokeroppt' && (
          <div className="trs-id">
            <div className="subtitle">
              <span>{totalOpportunities}</span>
              {` Conversion Oppt${totalOpportunities > 1 ? 's' : ''}`}
            </div>
            <div className="subtitle">
              {tranches && tranches.length > 0 && `Tranche ID `}
              <span>{tranches[0]?.tranche}</span>
            </div>
          </div>
        )}
        {getActiveTab()?.key === '/suite/brokeroppt' && readOnlyMode && (
          <div className="goto-link-wrapper">
            <Button type="link" className="goto-link" onClick={navigateToTableauReport}>
              Goto Performance Tracker <i className="icon" />
            </Button>
          </div>
        )}
      </div>
      <div className="text-bar">{getActiveTab()?.subtitle} </div>
      {props.children}
    </>
  );
}
export default DefaultLayout;
