import { put } from 'redux-saga/effects';
import { postRequest, getRequest } from '_http';
import {
  UPDATE_FEEDBACK,
  FEEDBACK,
  REFRESHSTATUS,
  FEEDBACK_STATUS,
  GENERATE_FEEDBACK_REPORT
} from 'actions/actionTypes';

import { action } from 'reduxHelpers';
import { checkBrokerCode, flattenFeedbacks } from 'utils/Util';

function* updateFeedbackAsync(payload) {
  let conversionId = payload.data.payload.conversionId;
  let customerId = payload.data.customerId;
  const onSubmissionSuccess = payload.data.onSubmissionSuccess;

  const feedbackPayload = payload.data.payload;
  if (feedbackPayload.feedbacks) {
    feedbackPayload.feedbacks = flattenFeedbacks(feedbackPayload.feedbacks);
  }

  try {
    const response = yield postRequest(`/oppt/feedback/${conversionId}`, feedbackPayload);
    yield put({ type: UPDATE_FEEDBACK.SUCCESS, payload: response.data });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Feedback updated successfully',
        className: 'success',
        message: 'FEEDBACK UPDATED'
      })
    );

    if (onSubmissionSuccess) {
      onSubmissionSuccess();
    }

    // yield put({ type: OPPORTUNITY_SELECTED_CLEAR.REQUEST });
    try {
      const response2 = yield getRequest(`/oppt/feedback/${conversionId}`);
      let updatedBy = response2.data.updatedBy;
      yield put({ type: REFRESHSTATUS.SUCCESS, payload: { updatedBy, opptId: conversionId } });

      if (customerId) {
        yield put({ type: FEEDBACK_STATUS.REQUEST, customerIdList: [customerId] });
      }
    } catch (error) {
      yield put({ type: REFRESHSTATUS.SUCCESS, payload: { updatedBy: null, opptId: conversionId } });
    }
  } catch (error) {
    yield put({ type: UPDATE_FEEDBACK.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'FEEDBACK UPDATING ERROR'
      })
    );
  }
}

function* fetchFeedbackAsync(payload) {
  const { conversionId, modifications } = payload;
  try {
    const response = yield getRequest(`/oppt/feedback/${conversionId}`);
    yield put({ type: FEEDBACK.SUCCESS, payload: { ...response.data, modifications } });
  } catch (error) {
    yield put({ type: FEEDBACK.FAILURE, payload: { ...error.message, modifications } });
  }
}

function* fetchRefeshStatusAsync({ refreshIds }) {
  if (refreshIds) {
    for (let conversionIndex = 0; conversionIndex < refreshIds.length; conversionIndex++) {
      let updatedBy = null;
      let opptId = refreshIds[conversionIndex];
      try {
        yield put({ type: REFRESHSTATUS.PENDING, payload: { updatedBy: '', opptId } });
        const response = yield getRequest(`/oppt/feedback/${opptId}`);
        updatedBy = response.data.updatedBy;
        yield put({ type: REFRESHSTATUS.SUCCESS, payload: { updatedBy, opptId } });
      } catch (error) {
        yield put({ type: REFRESHSTATUS.SUCCESS, payload: { updatedBy, opptId } });
      }
    }
  }
}

function* generateFeedbackReport(payload) {
  try {
    yield put({ type: GENERATE_FEEDBACK_REPORT.PENDING, payload: {} });

    const { pageSize, currentPage, filters, sortParams, brokerCode } = payload;

    const response = yield getRequest(
      `/reports/feedbacks?currentPage=${currentPage}&pageSize=${pageSize}&sortParams=${encodeURIComponent(
        JSON.stringify(sortParams)
      )}&filterParams=${encodeURIComponent(JSON.stringify(filters))}${checkBrokerCode(brokerCode)}`
    );
    yield put({ type: GENERATE_FEEDBACK_REPORT.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: GENERATE_FEEDBACK_REPORT.FAILURE, payload: error.message });
  }
}

export { fetchRefeshStatusAsync, updateFeedbackAsync, fetchFeedbackAsync, generateFeedbackReport };
