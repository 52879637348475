import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { get } from 'lodash';
import ScoreCard from '../../components/ScoreCard';
import { scoreCardConversion, scoreCardSales } from '../../selectors/dashboardSelector';
import {
  loadDashboardCardConversion,
  loadDashboardCardSales,
  clearDashboardCardConversion,
  clearDashboardCardSales
} from '../../actions';
import { CURRENCY_SYMBOL } from '../../utils/Constants';
import { simpleNumberFormatter } from '../../utils/Util';
import { allBrokersSelector } from '../../selectors/brokerListSelector';

const initCardData = [
  {
    loading: false,
    header: {
      title: 'Total Opportunities',
      tootleTip: `Total opportunities for <br> the selected period`
    },
    content: [
      {
        title: '',
        value: ''
      }
    ]
  },
  {
    loading: false,
    header: {
      title: 'Total Closed Opportunities',
      tootleTip: `Opportunities closed with Sold Status:</br>- Yes by Broker</br>- Yes with SC Engaged</br>- No`
    },
    content: [
      {
        title: '',
        value: ''
      },
      {
        title: '',
        value: ''
      }
    ]
  },
  {
    loading: false,
    header: {
      title: 'Closed Opportunities by Sold Status',
      tootleTip: ''
    },
    content: [
      {
        title: 'Yes with SC Engaged',
        value: ''
      },
      {
        title: 'Yes by Broker',
        value: ''
      },
      {
        title: 'No',
        value: ''
      }
    ]
  },
  {
    loading: false,
    header: {
      title: 'Items Sold',
      tootleTip: `- Items Sold: Total items sold by broker</br>- Targeted Items Sold: Targeted items sold by broker`
    },
    content: [
      {
        title: 'Targeted Items Sold',
        value: ''
      },
      {
        title: 'Non-targeted Items Sold',
        value: ''
      }
    ]
  },
  {
    loading: false,
    header: {
      title: 'Total Opportunities Sold',
      tootleTip: ''
    },
    content: [
      {
        title: '',
        value: ''
      },
      {
        title: 'Cases',
        value: ''
      }
    ]
  }
];
function DashboardScoreCard({ data }) {
  const dispatch = useDispatch();
  const allBrokers = useSelector(allBrokersSelector);
  // Selectors
  const conversionData = useSelector(scoreCardConversion);
  const salesData = useSelector(scoreCardSales);

  // localState
  const [cardData, setCardData] = useState(initCardData);

  useEffect(() => {
    const { tranche, filters } = data;
    if (tranche) {
      const cardFilter = {
        market: get(filters, 'market', []),
        region: get(filters, 'region', []),
        siteId: get(filters, 'siteId', []),
        conversionCatName: get(filters, 'categoryName', []),
        supplier: get(filters, 'supplier', []),
        bc: get(filters, 'bc', []),
        ig: get(filters, 'ig', []),
        ag: get(filters, 'ag', [])
      };

      // set chart payload
      dispatch(loadDashboardCardConversion({ ...data, filters: JSON.stringify(cardFilter), allBrokers }));
      dispatch(loadDashboardCardSales({ ...data, filters: JSON.stringify(cardFilter), allBrokers }));
    } else {
      // rest current card data
      dispatch(clearDashboardCardConversion());
      dispatch(clearDashboardCardSales());
    }
  }, [data]);

  useEffect(() => {
    const tempCardData = [...cardData];

    if (conversionData.fetching) {
      tempCardData[0].loading = true;
      tempCardData[1].loading = true;
      tempCardData[2].loading = true;
    } else {
      const yesWithSCEngaged = get(conversionData, 'data.yesWithSCEngaged', 0);
      const yesByBroker = get(conversionData, 'data.yesByBroker', 0);
      const no = get(conversionData, 'data.no', 0);
      const total = get(conversionData, 'data.totalConversions', 0);

      // card 1
      tempCardData[0].content[0].value = total;
      tempCardData[0].loading = false;
      // card 2
      const totalClosedOpportunities = yesWithSCEngaged + yesByBroker + no;
      tempCardData[1].content[0].value = totalClosedOpportunities;
      tempCardData[1].content[1].value = `${
        totalClosedOpportunities == 0 ? 0 : ((totalClosedOpportunities / total) * 100).toFixed(1)
      }%`;
      tempCardData[1].loading = false;

      // card 3
      tempCardData[2].content[0].value = yesWithSCEngaged || 0;
      tempCardData[2].content[1].value = yesByBroker || 0;
      tempCardData[2].content[2].value = no || 0;
      tempCardData[2].loading = false;
    }

    setCardData(tempCardData);
  }, [conversionData]);

  useEffect(() => {
    const tempCardData = [...cardData];
    if (salesData.fetching) {
      tempCardData[3].loading = true;
      tempCardData[4].loading = true;
    } else {
      // card 4
      const targetItemsSold = get(salesData, 'data.targetItemsSold', 0);
      const allItemsSold = get(salesData, 'data.allItemsSold', 0);

      tempCardData[3].content[0].value = targetItemsSold || 0;
      tempCardData[3].content[1].value = allItemsSold - targetItemsSold;
      tempCardData[3].loading = false;

      // card 5
      const total = get(salesData, 'data.totalSales', 0);
      tempCardData[4].content[0].value = `${CURRENCY_SYMBOL}${simpleNumberFormatter(total)}`;
      tempCardData[4].content[1].value = get(salesData, 'data.totalCases', 0);
      tempCardData[4].loading = false;
    }
    setCardData(tempCardData);
  }, [salesData]);
  return (
    <div className="chart-row centered">
      {cardData.map((item, index) => (
        <ScoreCard data={item} key={index} />
      ))}
    </div>
  );
}
export default DashboardScoreCard;
